import { Button, CircularProgress } from '@mui/material';
import { FC, useEffect } from 'react';
import { format } from 'date-fns';
import { useNavigate } from 'react-router-dom';
import {
  useGetMassTypesQuery,
  useGetProjectsQuery,
  usePostOrderMutation,
} from '../../../../core/redux/transport';
import { useDispatch, useSelector } from '../../../../shared/hooks/redux';
import { Header } from '../../../../components/header';
import { InnsendingLinje } from '../../../../components/Header4';
import { SplitOrder } from '../../../../components/SplitOrder';
import { calculateTonnage } from '../../../../core/helpers/functions';
import {
  translateWhenType,
  translateVehicleNumberType,
  translateNBVehicleType,
} from '../../../../core/helpers/translate';
import { resetStates } from '../../../../core/redux/massOrderState';
import { NewLoad } from '../../../../core/types/transport/api/newLoad';
import { NewOrder } from '../../../../core/types/transport/api/newOrder';
import { When } from '../../../../core/types/transport/when';
import { CancelButton } from '../../../../shared/components/CancelButton';
import { Content } from '../../../../shared/components/Content';
import { PageUnauthenticated } from '../../../../shared/components/page';
import { dateWithoutTimezone } from '../../../../shared/logic/dates';

export const InnInnsendingPage: FC = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { data: projects } = useGetProjectsQuery();
  const { data: massTypes } = useGetMassTypesQuery();
  const {
    projectFrom,
    projectTo,
    cubic,
    typeOfMass,
    trips,
    numberOfVehicles,
    deliveryTimeType,
    startTime,
    endTime,
    vehicleType,
    deliveryDate,
    isEarlyDelivery,
    isImportantDeadLine,
    timerCounter,
    orderComment,
    contactPerson,
    splitLoad,
    splitCubic,
    splitTypeOfMass,
  } = useSelector((s) => s.massOrderState);

  if (projectTo === null || cubic === null || typeOfMass === 0 || vehicleType.type === 0) {
    navigate('/bestilling');
    return null;
  }

  function getWhen(): When {
    const timeWhen = translateWhenType(deliveryTimeType.time);
    if (timeWhen === 'Custom') {
      return {
        when: timeWhen,
        from: startTime ? startTime.toISOString() : undefined,
        to: endTime ? endTime.toISOString() : undefined,
      };
    }
    return {
      when: timeWhen,
    };
  }

  const contactId: number | undefined = contactPerson?.employeeNumber || undefined;
  const contactName: string | undefined = contactPerson?.name || undefined;
  const contactNumber: string | undefined = contactPerson?.number || undefined;

  const createLoad = (): [NewLoad, NewLoad?] => {
    if (splitLoad) {
      return ([
        {
          amount: cubic,
          massTypeId: typeOfMass,
        },
        {
          amount: splitCubic || 0,
          massTypeId: splitTypeOfMass,
        },
      ]);
    }
    return ([
      {
        amount: cubic,
        massTypeId: typeOfMass,
      },
    ]);
  };

  const [sendOrder, sendOrderResponse] = usePostOrderMutation();

  const postOrder = () => {
    if (!deliveryDate.day) {
      throw new Error('Leveringstid er ikke spesifisert');
    }
    const orderToSend: NewOrder = {
      type: 'MassIn',
      fromProject: projectFrom || undefined,
      toProject: projectTo,
      loads: createLoad(),
      date: deliveryDate.day && dateWithoutTimezone(deliveryDate.day),
      when: getWhen(),
      earlyDelivery: isEarlyDelivery,
      importantDeadline: isImportantDeadLine,
      vehicle: translateVehicleNumberType(vehicleType.type),
      extraTimeInFacility: timerCounter || 0,
      comment: orderComment || '',
      contactPersonEmployeeNumber: contactId,
      contactPersonName: contactName,
      contactPersonPhoneNumber: contactNumber,
    };
    sendOrder(orderToSend);
  };

  useEffect(() => {
    if (sendOrderResponse.isSuccess) {
      navigate(`/bestilling/bekreftelse/${sendOrderResponse.data.id}`);
    }
  }, [sendOrderResponse.isSuccess]);

  useEffect(() => {
    if (sendOrderResponse.isError) {
      navigate('/masseinn/innsending');
    }
  }, [sendOrderResponse.isError]);

  function checkIfImportantDeadline() {
    return (
      <h5>
        Viktig Tidsfrist!
      </h5>
    );
  }

  function displayTime() {
    if (deliveryTimeType.type < 5) {
      return (
        <div>
          {deliveryTimeType.time}
        </div>
      );
    }
    return (
      <div>
        <div>
          {`Tidligst: ${startTime && format(startTime, 'HH:mm')} `}
        </div>
        <div>
          {`Senest: ${endTime && format(endTime, 'HH:mm')} `}
        </div>
      </div>
    );
  }

  function displayProjectFrom() {
    const project = projects?.find((e) => e.id === projectFrom);
    return (
      <div>
        <InnsendingLinje text="Fra prosjekt" />
        <div>
          {`${project?.id} ${project?.projectName}`}
        </div>
      </div>
    );
  }

  const mass = massTypes?.find((e) => e.id === `${typeOfMass}`)?.name;
  const splitMass = massTypes?.find((e) => e.id === `${splitTypeOfMass}`)?.name;
  const project = projects?.find((e) => e.id === projectTo);

  return (
    <PageUnauthenticated className="innsending-page parent-pos">
      <Header>Massebestilling</Header>
      <Content>
        <InnsendingLinje text="Steg 4 - Innsending" styling="page-item" />
        <div className="extra-inline-padding">
          <InnsendingLinje text="Din bestilling er klar til innsending" />
          <p>
            Les gjennom oppsummeringen under og sjekk at informasjonen stemmer før du sender
          </p>
          <InnsendingLinje text="Masse inn" />
          <InnsendingLinje text="Til prosjekt" />
          <div>
            {`${project?.id} ${project?.projectName}`}
          </div>
          {projectFrom && displayProjectFrom()}
          <InnsendingLinje text="Masse type" />
          <div>
            {mass}
          </div>
          <InnsendingLinje text="Mengde" />
          <div>
            {`${cubic} m3`}
          </div>
          {splitLoad && <SplitOrder splitMass={splitMass} splitCubic={splitCubic || 0} />}
          <div>
            {`Cirka ${calculateTonnage(cubic)} tonn`}
          </div>
          <InnsendingLinje text="Anbefalt kjøretøy" />
          <div>
            {translateNBVehicleType(vehicleType.type)}
          </div>
          <div>
            {`Antall turer/biler: ${trips}/${numberOfVehicles}`}
          </div>
          <InnsendingLinje text="Leveringsdato" />
          <div>
            {`${deliveryDate.day && format(deliveryDate.day, 'dd.MM.yyyy')}`}
          </div>
          <InnsendingLinje text="Kan leveres dagen før" />
          <div>
            {isEarlyDelivery ? 'Ja' : 'Nei'}
          </div>
          <InnsendingLinje text="Leveringstidspunkt" />
          {displayTime()}
          {isImportantDeadLine && checkIfImportantDeadline()}
          <InnsendingLinje text="Ekstra tid i anlegg" />
          <div>
            {`${timerCounter || 0} time(r)`}
          </div>
          <InnsendingLinje text="Kontaktperson" />
          <div>
            {`${contactPerson?.name} - ${contactPerson?.number}`}
          </div>
          <div>
            <InnsendingLinje text="Kommentar" />
          </div>
          <div className="display-linebreak">
            {orderComment}
          </div>
        </div>
        <div className="bottom-inner">
          <div className="bottom-element">
            <Button
              className="left-element"
              onClick={() => navigate(-1)}
              variant="contained"
              size="large"
            >
              Tilbake
            </Button>
            <CancelButton confirm={() => {
              dispatch(resetStates());
              return navigate('/bestilling');
            }}
            />
            <Button
              className="right-element"
              disabled={sendOrderResponse.isLoading}
              onClick={postOrder}
              variant="contained"
              size="large"
            >
              Send inn
            </Button>
            {sendOrderResponse.isLoading && (
            <CircularProgress
              size={24}
              sx={{
                position: 'absolute',
                top: '50%',
                left: '50%',
                marginTop: '-12px',
                marginLeft: '-12px',
              }}
            />
            )}
          </div>
        </div>
      </Content>
    </PageUnauthenticated>
  );
};
