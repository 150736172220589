import LocalShippingIcon from '@mui/icons-material/LocalShipping';
import SwapHorizIcon from '@mui/icons-material/SwapHoriz';
import AltRouteIcon from '@mui/icons-material/AltRoute';
import AlarmIcon from '@mui/icons-material/Alarm';
import CalendarViewWeekIcon from '@mui/icons-material/CalendarViewWeek';
import { Box } from '@mui/material';
import OilBarrelIcon from '@mui/icons-material/OilBarrel';
import { Order } from '../../../core/types/transport/order';
import {
  extractData,
  getContainerLoadTitle,
  getOrderProject,
  extractLoadData,
} from '../../../core/helpers/functions';
import { OrderItemType } from './orderItem';
import { useIsOrderLate } from '../../../core/hooks/useIsLate';
import { useIsType } from '../../../core/hooks/useIsType';
import { getMachineLabel } from '../../../core/helpers/labelling';
import { dieselTypeItems } from '../../dieselOrderPages/logic/dieselTypeItems';

export const useConvertMass = (order: Order): OrderItemType => {
  const project = getOrderProject(order);
  const totalAmount = order.loads
    ? order.loads.reduce((acc, curr) => acc + curr.amount, 0)
    : 0;
  const isType = useIsType(order.type);
  const { isLate } = useIsOrderLate(order);
  const orderData = extractData(order);
  const loadData = extractLoadData(order);
  const obj: OrderItemType = {
    id: order.id,
    path: `/bestilling/transport/${order.id}`,
    assignedUser: {
      name: order.contactPersonName,
      phone: order.contactPersonPhoneNumber,
    },
    info: order.loads.map((l) => {
      if (isType('Diesel')) {
        const type = dieselTypeItems.find((t) => t.id === l.dieselType)?.label;
        return (
          <span key={l.id}>
            {`${l.amount} liter ${type ?? ' '} diesel`}
          </span>
        );
      }

      if (isType('Container')) {
        return (
          <span key={l.id}>{getContainerLoadTitle(order.type, l)}</span>
        );
      }

      if (isType('Haul')) {
        return (
          <span key={l.id}>
            {
              (l.machine && getMachineLabel(l.machine))
                ?? l.machineInternalNumber
                ?? l.machineExternal
            }
          </span>
        );
      }

      return (<span key={l.id}>{`${l.amount}m³ ${l.type?.name}`}</span>);
    }),
    status: order.status,
    title: `${project?.id} - ${project?.projectName}`,
    type: order.type,
    importantDeadline: order.importantDeadline,
    origin: 'transport',
    icons: null,
  };
  obj.icons = (
    <Box>
      {isType('Container') && (
      <span className="fraction">
        {`${order.details?.totalAmount || 0}/${totalAmount} `}
        <CalendarViewWeekIcon fontSize="small" color="primary" />
      </span>
      )}

      {isType('Haul') && (
      <span className="fraction">
          {`${order.details?.totalAmount || 0}/${totalAmount} `}
        <LocalShippingIcon fontSize="small" color="primary" />
      </span>
      )}

      {isType('MassIn', 'MassOut') && (
      <>
        <span className="fraction">
          {`${loadData?.trips || '0'}/${orderData.loads} `}
          <SwapHorizIcon color="primary" />
        </span>
        <span className="fraction pl5">
          {`${loadData?.vehicles || '0'}/${orderData.vehicles} `}
          <LocalShippingIcon color="primary" />
        </span>
      </>
      )}

      {isType('MassInternal') && (
        <span className="fraction">
          {`${loadData?.vehicles || '0'}/${order.numberOfVehicles} `}
          <LocalShippingIcon color="primary" />
        </span>
      )}

      {isType('Diesel') && (
        <span className="fraction">
          {`${orderData.dieselContainerAmount || 0} `}
          <OilBarrelIcon color="primary" />
        </span>
      )}

      {!isType('MassInternal') && order.earlyDelivery && (
        <span className="fraction" title="Kan leveres dagen før">
          <AltRouteIcon fontSize="small" color="primary" />
        </span>
      )}

      { isLate && (
      <span className="fraction transform-type" title="Sen innsending">
        <AlarmIcon fontSize="small" color="error" />
      </span>
      )}
    </Box>
  );

  return obj;
};
