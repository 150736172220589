import { EnvironmentName } from './shared/types/enums/environmentName';

export const getEnvironmentName = (env?: string): EnvironmentName => {
  const url = new URL(env || document.baseURI);
  if (url.hostname === 'localhost') return 'local';
  if (url.hostname === 'delightful-island-036696f03.2.azurestaticapps.net' && url.protocol === 'https:') return 'dev';
  if (url.hostname === 'bestilling-test.tsmaskin.no' && url.protocol === 'https:') return 'test';
  if (url.hostname === 'bestilling.tsmaskin.no' && url.protocol === 'https:') return 'prod';
  return '';
};
