import { configureStore } from '@reduxjs/toolkit';
import {
  FLUSH,
  REHYDRATE,
  PAUSE,
  PERSIST,
  PURGE,
  REGISTER,
} from 'redux-persist';
import { loggerMiddleware } from './loggerMiddleware';
import rental, { rentalApi } from '../../shared/redux/rental';
import viewSetting from './viewSetting';
import transport, { transportApi } from './transport';
import calendar, { calendarApi } from './calendar';
import rentalOrderState from '../../shared/redux/local/rentalOrderState';
import containerOrderState from './containerOrderState';
import haulOrderState from './haulOrderState';
import massOrderState from './massOrderState';
import dieselOrderState from './dieselOrderState';

// Put additional reducers here
export const store = configureStore({
  reducer: {
    massOrderState,
    containerOrderState,
    haulOrderState,
    dieselOrderState,
    rentalOrderState,
    viewSetting,
    rental,
    transport,
    calendar,
  },
  middleware: (getDefaultMiddleware) => getDefaultMiddleware({
    serializableCheck: {
      ignoredActions: [FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER],
    },
  })
    .concat(rentalApi.middleware)
    .concat(calendarApi.middleware)
    .concat(transportApi.middleware)
    .concat(loggerMiddleware),
});

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;
