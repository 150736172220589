import {
  FC,
  ReactNode,
} from 'react';
import {
  Box,
  IconButton,
  Typography,
} from '@mui/material';
import HomeIcon from '@mui/icons-material/Home';
import BackIcon from '@mui/icons-material/ChevronLeft';
import { Link, useNavigate } from 'react-router-dom';
import { Header as SharedHeader } from '../../shared/components/header';
import { PageName } from '../../shared/types/util/pageName';

export const Header: FC<{
  page?: PageName,
  children?: ReactNode,
  disableHome?: boolean,
  showBack?: boolean,
}> = ({
  page,
  children,
  disableHome = false,
  showBack = false,
}) => {
  const navigate = useNavigate();
  return (
    <SharedHeader
      noServerVersion
      page={page}
      disableMap
    >
      <Box display="flex" alignItems="center" gap={1}>
        {!disableHome && (
        <IconButton component={Link} to="/" edge="start" color="inherit" aria-label="menu">
          <HomeIcon />
        </IconButton>
        )}
        {showBack && (
        <IconButton
          color="secondary"
          onClick={() => navigate(-1)}
        >
          <BackIcon sx={{ fontSize: 32 }} />
        </IconButton>
        )}
        <Typography sx={{ fontSize: 20 }}>
          {children}
        </Typography>
      </Box>
    </SharedHeader>
  );
};
