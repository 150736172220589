import { useMemo } from 'react';
import { useGetProjectMRUsQuery } from '../redux/transport';

export const useMRUs = () => {
  const { data: rawMrus } = useGetProjectMRUsQuery();

  const mrus = useMemo(() => {
    if (!rawMrus) return new Map<number, Date>();
    return new Map<number, Date>(
      rawMrus?.map((m) => ([m.projectId, new Date(m.lastUsed)])),
    );
  }, [rawMrus]);

  return mrus;
};
