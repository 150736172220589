import React, { FC } from 'react';
import { InnsendingLinje } from '../Header4';

export const SplitOrder: FC<{
    splitMass: string | undefined, splitCubic: number}> = ({ splitMass, splitCubic }) => (
      <div>
        <InnsendingLinje text="Splittet ordre" />
        <InnsendingLinje text="Masse type" />
        <div>
          {splitMass}
        </div>
        <InnsendingLinje text="Mengde" />
        <div>
          {`${splitCubic} m3`}
        </div>
      </div>
    );
