/**
 * Checks if any of the provided values is null or undefined
 *
 * An advanced pattern is when a value is only required if a boolean value is
 * true. In this case the following pattern can be used:
 *
 * ```ts
 * const requirement = Math.random() > 0.5;
 *
 * hasMissing(
 *   someVar,
 *   // Only checks `anotherVar` if requirement is true:
 *   requirement && anotherVar,
 * );
 * ```
 *
 * This works because `false` is considered a non-missing value.
 */
export const hasMissing = (...values: unknown[]) => (
  values.some((v) => v === null || v === undefined)
);
