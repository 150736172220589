import { Box, TextField, Button } from '@mui/material';
import { skipToken } from '@reduxjs/toolkit/dist/query';
import { useMemo } from 'react';
import { SearchSelect } from '../../shared/components/searchSelect';
import { InnsendingLinje } from '../Header4';
import { SelectItem } from '../../shared/types/util/selectItem';
import { SelectContainer } from '../../core/types/transport/selectContainer';
import {
  useGetContainerWasteTypesQuery,
  useGetProjectContainersQuery,
} from '../../core/redux/transport';

export const OrderContainerSelector = ({
  value = [],
  onChange = () => null,
  onRemove = () => null,
  onAdd = () => null,
  projectId,
}: {
  value?: SelectContainer[],
  onChange?: (id: number, val: Partial<SelectContainer>) => void,
  onRemove?: (id: number) => void,
  onAdd?: (ext?: boolean) => void,
  projectId?: number,
}) => {
  const { data: rawMass } = useGetContainerWasteTypesQuery();
  const { data: rawContainers } = useGetProjectContainersQuery(projectId ?? skipToken);

  const massList = useMemo(() => {
    const arr: SelectItem[] = [{ id: 0, label: '(tom)' }];
    if (rawMass) {
      rawMass.forEach((m, i) => (arr.unshift({ id: i + 1, label: m })));
    }
    return arr;
  }, [rawMass]);

  const containerList = useMemo((): SelectItem[] => (rawContainers
    ? rawContainers
      .filter((rc) => (!value.some((c) => `${c.containerInternalNumber?.id}` === rc.internalNumber)))
      .map((c) => ({
        id: c.internalNumber,
        label: `${c.internalNumber} - ${c.subCategoryName}`,
        disabled: c.assignmentId !== undefined || c.orderId !== undefined,
      }))
    : []), [value, rawContainers]);

  const disableAdd = useMemo(() => (
    value.filter((v) => !v.isExternal && !v.containerInternalNumber).length
    >= containerList.filter((c) => !c.disabled).length
  ), [value, containerList]);

  return (
    <Box>
      <Box className="orders-wrapper">
        {value.map((c) => (
          <Box key={c.id} className="order-wrapper">
            <div className="item">
              <InnsendingLinje styling="field-title" text="Container" />
              {c.isExternal ? (
                <TextField
                  label="Ekstern container"
                  fullWidth
                  helperText={!c.containerExternal ? 'Skriv inn ekstern container' : ''}
                  error={!c.containerExternal}
                  required
                  value={c.containerExternal}
                  onChange={(e) => onChange(c.id, { containerExternal: e.currentTarget.value })}
                />
              ) : (
                <SearchSelect
                  label="Container"
                  errorLabel="Velg en container"
                  required
                  value={c.containerInternalNumber}
                  onChange={(p) => onChange(c.id, { containerInternalNumber: p })}
                >
                  {containerList}
                </SearchSelect>
              )}
            </div>
            <div className="item">
              <InnsendingLinje styling="field-title" text="Avfallstype" />
              <SearchSelect
                label=""
                errorLabel="Velg avfallstype"
                required
                value={c.mass}
                onChange={(p) => onChange(c.id, { mass: p })}
              >
                {massList}
              </SearchSelect>
            </div>
            {value.length > 1 && (
            <div>
              <Button className="delete-btn" size="large" color="error" variant="contained" onClick={() => onRemove(c.id)}>Slett</Button>
            </div>
            )}
          </Box>
        ))}
      </Box>

      <Box display="flex" gap={1} className="add-btn">
        <Button
          sx={{ flex: '1' }}
          disabled={disableAdd}
          variant="contained"
          onClick={() => onAdd()}
        >
          Legg til flere
        </Button>
        <Button
          sx={{ flex: '1' }}
          variant="contained"
          onClick={() => onAdd(true)}
        >
          Legg til ekstern
        </Button>
      </Box>
    </Box>
  );
};
