/* eslint-disable no-param-reassign */
/* eslint-disable no-plusplus */
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { ContactPerson } from '../../components/ContactPerson';
import { DeliveryDate } from '../types/transport/utilityTypes';
import { OrderType } from '../types/transport/enums/orderType';
import { MachinePickerValue } from '../../components/MachinePicker/index';

interface DeliveryTime {
  type: number;
  time: string;
}

interface HaulOrderState {
  type: Extract<OrderType, 'HaulSemi' | 'HaulHook'> | null,

  projectTo: number | null;
  projectFrom: number | null;

  /** Internal or external machine */
  machine: MachinePickerValue;

  /** Code for gate to enter site */
  gateCode: string | null;
  /** Required. If true, gateCode must also be provided */
  gateCodeRequired: boolean | null;

  /** Free text for extra equipment */
  extraEquipment: string;
  /** If true, extraEquipment must also be provided */
  extraEquipmentRequired: boolean;

  /** If true, the order can be delivered between 07:00 and 15:00 */
  daytimeDelivery: boolean;

  /** True if vehicle has dispensation */
  hasDispensation: boolean | null;

  deliveryTimeType: DeliveryTime;
  startTime: Date | null;
  endTime: Date | null;

  userDefinedDeliveryDate: Date | null;

  deliveryDate: DeliveryDate;

  isEarlyDelivery: boolean;
  isImportantDeadLine: boolean;
  timerCounter: number | null;

  lastCreatedOrderId: number;

  orderComment: string;

  contactPerson: ContactPerson | null;
}

const initialState: HaulOrderState = {
  type: null,

  projectFrom: null,
  projectTo: null,

  machine: { internal: null },

  gateCode: null,
  gateCodeRequired: null,

  extraEquipment: '',
  extraEquipmentRequired: true,

  daytimeDelivery: false,
  hasDispensation: null,

  deliveryTimeType: { type: 1, time: 'I løpet av dagen' },
  startTime: null,
  endTime: null,

  userDefinedDeliveryDate: null,
  deliveryDate: { type: 0, day: null },

  isEarlyDelivery: false,
  isImportantDeadLine: false,
  lastCreatedOrderId: 0,
  timerCounter: 0,

  orderComment: '',

  contactPerson: null,
};

const haulOrderSlice = createSlice({
  name: 'haulOrderState',
  initialState,
  reducers: {
    setType(state, action: PayloadAction<typeof state.type>) {
      state.type = action.payload;
    },
    setProjectFrom(state, action: PayloadAction<number | null>) {
      state.projectFrom = action.payload;
    },
    setProjectTo(state, action: PayloadAction<number | null>) {
      state.projectTo = action.payload;
    },
    setMachine(state, action: PayloadAction<typeof state.machine>) {
      state.machine = action.payload;
    },
    setGateCode(state, action: PayloadAction<typeof state.gateCode>) {
      state.gateCode = action.payload;
    },
    setGateCodeRequired(state, action: PayloadAction<typeof state.gateCodeRequired>) {
      state.gateCodeRequired = action.payload;
    },
    setExtraEquipment(state, action: PayloadAction<typeof state.extraEquipment>) {
      state.extraEquipment = action.payload;
    },
    setExtraEquipmentRequired(state, action: PayloadAction<typeof state.extraEquipmentRequired>) {
      state.extraEquipmentRequired = action.payload;
    },
    setDaytimeDelivery(state, action: PayloadAction<typeof state.daytimeDelivery>) {
      state.daytimeDelivery = action.payload;
    },
    setHasDispensation(state, action: PayloadAction<typeof state.hasDispensation>) {
      state.hasDispensation = action.payload;
    },
    setDeliveryTimeType(state, action: PayloadAction<DeliveryTime>) {
      state.deliveryTimeType = action.payload;
    },
    setStartTime(state, action: PayloadAction<Date | null>) {
      state.startTime = action.payload;
    },
    setEndTime(state, action: PayloadAction<Date | null>) {
      state.endTime = action.payload;
    },
    setUserDefinedDeliveryDate(state, action: PayloadAction<Date | null>) {
      state.userDefinedDeliveryDate = action.payload;
    },
    setDeliveryDate(state, action: PayloadAction<DeliveryDate>) {
      state.deliveryDate = action.payload;
    },
    setIsEarlyDelivery(state, action: PayloadAction<boolean>) {
      state.isEarlyDelivery = action.payload;
    },
    setIsImportantDeadline(state, action: PayloadAction<boolean>) {
      state.isImportantDeadLine = action.payload;
    },
    setLastCreatedOrderId(state, action: PayloadAction<number>) {
      state.lastCreatedOrderId = action.payload;
    },
    setTimerCounter(state, action: PayloadAction<number | null>) {
      state.timerCounter = action.payload;
    },
    setOrderComment(state, action: PayloadAction<string>) {
      state.orderComment = action.payload;
    },
    setContactPerson(state, action: PayloadAction<ContactPerson | null>) {
      state.contactPerson = action.payload;
    },
    resetStates(state) {
      Object.assign(state, { ...initialState, lastCreatedOrderId: state.lastCreatedOrderId });
    },
  },
});

export const {
  setType,
  setProjectFrom,
  setProjectTo,
  setMachine,
  setGateCode,
  setGateCodeRequired,
  setExtraEquipment,
  setExtraEquipmentRequired,
  setDaytimeDelivery,
  setHasDispensation,
  setDeliveryTimeType,
  setStartTime,
  setEndTime,
  setDeliveryDate,
  setIsEarlyDelivery,
  setIsImportantDeadline,
  setUserDefinedDeliveryDate,
  setOrderComment,
  setContactPerson,
  resetStates,
} = haulOrderSlice.actions;
export default haulOrderSlice.reducer;
