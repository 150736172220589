import { Box } from '@mui/material';
import { ReactNode } from 'react';
import {
  compareAsc,
  compareDesc,
  format,
  // eslint-disable-next-line import/no-duplicates
} from 'date-fns';
// eslint-disable-next-line import/no-duplicates
import { nb } from 'date-fns/locale';
import { AssignmentType } from '../types/transport/enums/assignmentType';
import { Load } from '../types/transport/load';
import { Order } from '../types/transport/order';
import { useIsType } from '../hooks/useIsType';
import { OrderStatus } from '../types/transport/enums/orderStatus';
import { EquipmentOrderStatus } from '../../shared/types/enums/equipmentOrderStatus';
import { Project } from '../types/transport/project';
import { DeliveryDate } from '../types/transport/utilityTypes';
import { EquipmentAssignment } from '../../shared/types/equipmentAssignment';
import { Organization } from '../../shared/types/organization';
import {
  EquipmentEditRules,
  equipmentEditRulesDefaults,
} from '../../shared/types/equipmentEditRules';
import { AssignmentStatus } from '../types/transport/enums/assignmentStatus';
import { dieselTypeItems } from '../../pages/dieselOrderPages/logic/dieselTypeItems';

export const pluralize = (
  value: number,
  word: string,
  ending: string = 'er',
) => {
  if (value === 1) {
    return `${value} ${word}`;
  }
  return `${value} ${word}${ending}`;
};

/**
 *
 * @param type Assignmenttype
 * @param l Load
 * @param multiline flag indicating if function should return jsx for planning element for ContainerCollect and ContainerEmptying
 * @returns string or jsx element
 */
export const getContainerLoadTitle = (
  type: AssignmentType | undefined,
  l: Load,
  multiline: boolean = false,
): string | ReactNode => {
  if (type === 'ContainerCollect' || type === 'ContainerEmptying') {
    if (multiline) {
      return (
        <Box display="flex" marginLeft={1} flexDirection="column">
          <span>
            {`${
              l.actualContainerExternal || l.containerExternal
                ? `(Ekstern) ${
                  l.actualContainerExternal || l.containerExternal
                }`
                : l.actualContainerInternalNumber || l.containerInternalNumber
            } ${
              l.container?.subCategoryName.replace('KROKCONTAINER', '') || ''
            }  ${l.wasteType ? `med ${l.wasteType}` : '(tom)'}`}
          </span>
        </Box>
      );
    }
    return `${
      l.containerExternal
        ? `(Ekstern) ${l.containerExternal}`
        : l.containerInternalNumber
    } ${l.wasteType ? `med ${l.wasteType}` : '(tom)'}`;
  }

  return `${l.amount}stk ${l.containerType} ${
    l.type?.name ? `med ${l.type.name}` : ''
  }`;
};

export const getDieselLoadTitle = (l: Load) => {
  const containerAmount = pluralize(l.dieselContainerAmount ?? 0, 'tank');
  const type = dieselTypeItems.find((t) => t.id === l.dieselType)?.label;
  return `${containerAmount} (${l.amount} liter) ${type ?? ''} diesel`;
};

/**
 *
 * @param type Assignmenttype
 * @param l Load
 * @param multiline flag indicating if function should return jsx for planning element for ContainerCollect and ContainerEmptying
 * @returns string or jsx element
 */
export const getHaulLoadTitle = (l: Load): string | ReactNode => {
  if (l.machine) {
    return `${l.machine.internalNumber} - ${l.machine.subCategoryName} - ${l.machine.modelName}`;
  }
  if (l.machineInternalNumber) return l.machineInternalNumber;
  return l.machineExternal;
};

export const getOrderProject = (order: Order) => {
  const isType = useIsType(order.type);
  if (isType('ContainerCollect', 'ContainerEmptying', 'MassOut')) return order.fromProject;
  return order.toProject;
};

export const extractData = (order: Order) => {
  const project = getOrderProject(order);

  const title = `${project?.id} - ${project?.projectName}`;
  // Add together all masses of all loads
  const totalAmount = order.loads
    ? order.loads.reduce((acc, curr) => acc + curr.amount, 0)
    : 0;
  // Get the load with the most mass
  const mainType = order.loads.reduce(
    (acc, curr) => (curr.amount > acc.max
      ? { max: curr.amount, name: curr.type ? curr.type.name : '' }
      : acc),
    { max: 0, name: '' },
  ).name;

  const loads = Math.ceil(totalAmount / (order.vehicle === 'Single' ? 10 : 20)); // Each vehicle can take 20 m³ per load
  const vehicles = Math.ceil(loads / 6); // Each vehicle can make 6 loads a day
  const dieselContainerAmount = order.loads[0]?.dieselContainerAmount ?? 0;

  return {
    title,
    totalAmount,
    mainType,
    loads,
    vehicles,
    dieselContainerAmount,
  };
};

export const extractLoadData = (order: Order) => ({
  trips: order.details?.assignmentCount,
  vehicles: order.details?.vehicleCount,
});

export const getOrderStatusColor = (
  currStatus: OrderStatus | EquipmentOrderStatus | undefined,
) => {
  switch (currStatus) {
    case 'Created':
      return 'CreatedColor';
    case 'UnderPlanning':
      return 'UnderPlanningColor';
    case 'Planned':
    case 'Approved':
      return 'ApprovedColor';
    case 'Completed':
      return 'FinishedColor';
    case 'Cancelled':
    case 'NotApproved':
      return 'CancelledColor';
    case 'Undelivered':
      return 'UndeliveredColor';
    case 'Declined':
      return 'CancelledColor';
    default:
      return 'CreatedColor';
  }
};

export const getDeliveryTimes = (order: Order) => {
  const fromTime = order.when.from
    ? format(new Date(order.when.from), "dd.MM.yyyy 'kl.' HH:mm", {
      locale: nb,
    })
    : null;
  const toTime = order.when.to
    ? format(new Date(order.when.to), "dd.MM.yyyy 'kl.' HH:mm", { locale: nb })
    : null;
  const customTime = (
    <div>
      <span>{`Fra: ${fromTime}`}</span>
      <br />
      <span>{`Til: \u00A0${toTime}`}</span>
    </div>
  );

  switch (order.when.when) {
    case 'DuringTheDay':
      return 'I løpet av dagen';
    case 'Morning':
      return 'Morgen';
    case 'BeforeFood':
      return 'Før mat';
    case 'AfterFood':
      return 'Etter mat';
    case 'Custom':
      return customTime;
    default:
      return 'I løpet av dagen';
  }
};

export const calculateTonnage = (m3: number) => (m3 * 1.6).toFixed(1);

/**
 * returns true when userDefineDeliveryDate < today or null
 * false when valid or future date is selected
 */
export const futureDateIsNotSelected = (
  deliveryDate: DeliveryDate,
  userDefinedDeliveryDate: Date | null,
) => {
  if (deliveryDate.type === 2 && userDefinedDeliveryDate === null) return true;
  if (userDefinedDeliveryDate === null) return false;
  if (deliveryDate.type !== 2) return false;
  return (
    compareAsc(
      userDefinedDeliveryDate.setHours(0, 0, 0, 0),
      new Date().setHours(0, 0, 0, 0),
    ) < 0
  );
};

export const sumAssignedLoads = (id: string | undefined, order: Order) => {
  const vehicles = order.vehicles || [];
  if (!id) return 0;
  return vehicles
    .flatMap((v) => v?.assignments.filter(
      (a) => a.status !== 'Cancelled' && a.status !== 'Deleted',
    ))
    .flatMap((a) => a?.loads.filter((l) => l.type?.id === id))
    .reduce((prev, l) => prev + l.amount, 0);
};

export const sortByMRU = (mrus: Map<number, Date>) => (a: Project, b: Project) => {
  const aMru = mrus.get(a.id);
  const bMru = mrus.get(b.id);
  if (!aMru && !bMru) return 0;
  if (!aMru) return 1;
  if (!bMru) return -1;
  return compareDesc(aMru, bMru);
};

export const emptyGuard = (val: number | string | undefined | null) => {
  if (val === undefined || val === null) return undefined;
  if (val === '' || val === 0) return undefined;
  return `${val}` === '(tom)' ? undefined : `${val}`;
};

export const onlyQuarters = (
  timeValue: number,
  clockType: 'hours' | 'minutes' | 'seconds',
) => clockType === 'minutes' && timeValue / 15 !== Math.round(timeValue / 15);

/**
 * Filter the array so only one object with the key exists
 * @param arr Array of objects
 * @param key Key of object that should be unique
 * @returns Unique array
 */
export const unique = <T extends object | string | number>(
  arr: T[] | undefined | null,
  key: keyof T | void,
) => {
  if (!arr) return [];
  const out: T[] = [];
  for (let i = 0; i < arr.length; i += 1) {
    const item = arr[i];
    if (
      !out.some((o) => (typeof o === 'object' ? key && o[key] === item[key] : o === item))
    ) {
      out.push(item);
    }
  }
  return out;
};

export const editEquipmentRules = (
  event: EquipmentAssignment | undefined,
  isAdmin: boolean,
  userOrg: Organization | undefined,
): EquipmentEditRules => {
  const canEdit = (() => {
    if (userOrg === undefined) return false;
    return (
      event?.project?.internalProduction.some((x) => x.id === userOrg.id)
      || event?.project?.responsible?.parentOrganizationId === userOrg.id
      || event?.project?.coResponsible?.parentOrganizationId === userOrg.id
    );
  })();
  if (event?.type === 'Order') {
    return {
      ...equipmentEditRulesDefaults,
      editTime: isAdmin || canEdit,
      editEquipment: isAdmin || canEdit,
      editSubCat: isAdmin || canEdit,
      editWorksite: isAdmin || canEdit,
      editComment: canEdit,
      editProject: canEdit,
      editOrderer: canEdit,

      seeEquipment: isAdmin,
      seeMainCat: true,
      seeSubCat: true,
      seeComment: true,
      seeOrderComment: true,
      seeLastEditBy: true,

      seeAssignEquipmentButton: isAdmin,
      seeCancelButton: isAdmin || canEdit,
      seeSaveButton: isAdmin || canEdit,

      closeButtonText: isAdmin || canEdit ? 'Avbryt' : 'Lukk',
      cancelButtonText: isAdmin ? 'Avslå' : 'Kanseller',
    };
  }

  if (event?.type === 'Assignment' && event?.status === 'Created') {
    return {
      ...equipmentEditRulesDefaults,
      editTime: isAdmin || canEdit,
      editEquipment: isAdmin,
      editInternalComment: isAdmin,
      editWorksite: isAdmin || canEdit,
      editProject: isAdmin || canEdit,
      editOrderer: isAdmin || canEdit,

      seeEquipment: true,
      seeInternalComment: isAdmin,
      seeLastEditBy: true,
      seeOrderCard: isAdmin,

      seeApproveEquipmentButton: isAdmin,
      seeCancelButton: isAdmin,
      seeSaveButton: isAdmin || canEdit,

      closeButtonText: isAdmin ? 'Avbryt' : 'Lukk',
      cancelButtonText: isAdmin ? 'Avslå' : 'Kanseller',
    };
  }
  return {
    ...equipmentEditRulesDefaults,
  };
};

export const getAssignmentStatusColor = (
  currStatus: AssignmentStatus | undefined,
) => {
  switch (currStatus) {
    case 'UnderPlanning':
      return 'UnderPlanningColor';
    case 'Approved':
      return 'ApprovedColor';
    case 'Started':
      return 'StartedColor';
    case 'Completed':
      return 'CompletedColor';
    case 'Cancelled':
      return 'CancelledColor';
    case 'NotDelivered':
    case 'VerifiedNotDelivered':
      return 'NotDeliveredColor';
    case 'Deleted':
      return 'DeletedColor';
    default:
      return 'UnderPlanningColor';
  }
};

/**
 *  Stringify an optional boolean value. If the value is true, you get "Ja",
 * "Nei" for false, and by default "-" for null or undefined.
 *
 * ```ts
 * optionalBool(true); // "Ja"
 * optionalBool(false); // "Nei"
 * optionalBool(undefined); // "-"
 * optionalBool(null); // "-"
 * optionalBool(null, 'Ukjent'); // "Ukjent"
 * ```
 */
export const optionalBool = (
  /** Boolean value to stringify */
  v: boolean | undefined | null,
  /** String to return if value is not true or false */
  defaultText: string = '-',
) => {
  if (v === true) return 'Ja';
  if (v === false) return 'Nei';
  return defaultText;
};
