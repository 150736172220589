import { FC, useEffect, useMemo } from 'react';
import { useNavigate } from 'react-router-dom';
import { Box, Button } from '@mui/material';
import { useSelector, useDispatch } from '../../../shared/hooks/redux';
import { PageUnauthenticated } from '../../../shared/components/page';
import { Header } from '../../../components/header';
import { InnsendingLinje } from '../../../components/Header4';
import {
  resetStates,
  setMachine,
  setProjectFrom,
  setProjectTo,
} from '../../../core/redux/haulOrderState';
import { CancelButton } from '../../../shared/components/CancelButton';
import { Content } from '../../../shared/components/Content';
import { translateOrderType } from '../../../core/helpers/translate';
import { ProjectPicker } from '../../../components/ProjectPicker';

import { MachinePicker } from '../../../components/MachinePicker';

export const HaulPage: FC = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const {
    type,
    projectFrom,
    projectTo,
    machine,
  } = useSelector((s) => s.haulOrderState);

  useEffect(() => {
    if (!type) navigate('/maskinslep');
  }, []);

  const disableNextPage = useMemo(() => {
    if (!machine.internal && !machine.external) return true;
    if (projectFrom === null) return true;
    if (projectTo === null) return true;
    return false;
  }, [machine, projectFrom, projectTo]);

  return (
    <PageUnauthenticated className="maskinslep-order-page">
      <Header>{type ? translateOrderType(type) : 'Maskinslep'}</Header>
      <Content>
        <InnsendingLinje text={`Steg 1 - ${type && translateOrderType(type)}`} styling="page-item" />
        <Box sx={{ display: 'flex', flexDirection: 'column', gap: 4 }}>
          <MachinePicker value={machine} onChange={(v) => dispatch(setMachine(v))} />
          <ProjectPicker
            label="Fra prosjekt"
            value={projectFrom}
            onChange={(v) => dispatch(setProjectFrom(v?.id ?? null))}
          />
          <ProjectPicker
            label="Til prosjekt"
            value={projectTo}
            onChange={(v) => dispatch(setProjectTo(v?.id ?? null))}
          />
        </Box>
        <div className="bottom-inner">
          <div className="bottom-element">
            <div style={{ width: '64px' }} />
            <CancelButton confirm={() => {
              dispatch(resetStates());
              return navigate('/maskinslep');
            }}
            />
            <Button
              className="right-element"
              onClick={() => navigate('./leveringsdetaljer')}
              variant="contained"
              size="large"
              disabled={disableNextPage}
            >
              {'>'}
            </Button>
          </div>
        </div>
      </Content>
    </PageUnauthenticated>
  );
};
