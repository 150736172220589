import { FC, useEffect } from 'react';
import Button from '@mui/material/Button';
import {
  InputAdornment,
  TextareaAutosize,
  TextField,
} from '@mui/material';
import { useNavigate } from 'react-router-dom';
import AddIcon from '@mui/icons-material/Add';
import RemoveIcon from '@mui/icons-material/Remove';
import { PageUnauthenticated } from '../../../../shared/components/page';
import { ChooseContactPerson } from '../../../../components/ContactPerson';
import { Header } from '../../../../components/header';
import { InnsendingLinje } from '../../../../components/Header4';
import {
  setTimerCounter,
  setContactPerson,
  setOrderComment,
  resetStates,
} from '../../../../core/redux/containerOrderState';
import { CancelButton } from '../../../../shared/components/CancelButton';
import { Content } from '../../../../shared/components/Content';
import { useDispatch, useSelector } from '../../../../shared/hooks/redux';

export const ContainerDeliverAdditionalDetailsPage: FC = () => {
  const navigate = useNavigate();
  const {
    orderComment,
    projectTo,
    contactPerson,
    timerCounter,
  } = useSelector((s) => s.containerOrderState);
  const dispatch = useDispatch();

  useEffect(() => {
    if (projectTo === null) {
      navigate('/container');
    }
  }, [projectTo]);

  if (projectTo === null) {
    return null;
  }

  const updateTimerCounter = (value: string) => {
    if (value) {
      dispatch(setTimerCounter(Number.parseFloat(value)));
    } else {
      dispatch(setTimerCounter(null));
    }
  };

  const disableNextPage = contactPerson === null || (contactPerson?.name === '' || contactPerson?.number === '');

  return (
    <PageUnauthenticated className="tilleggsdetaljer-page parent-pos">
      <Header>Containerbestilling</Header>
      <Content>
        <InnsendingLinje text="Steg 3 - Tilleggsdetaljer" styling="page-item" />
        <InnsendingLinje text="Ekstra tid i anlegg (valgfritt)" styling="page-item" />

        <div className="page-item timer-clicker">
          <Button className="left-element" onClick={() => dispatch(setTimerCounter(timerCounter && Math.max(timerCounter - 0.5, 0)))} variant="outlined" size="large">
            <RemoveIcon
              fontSize="inherit"
            />
          </Button>
          <TextField
            className="center-element"
            size="small"
            type="number"
            value={
              timerCounter !== null
                ? Math.min(24, timerCounter || 0)
                : ''
            }
            InputProps={{
              inputProps: { min: 0, max: 24 },
              endAdornment: <InputAdornment position="end">time(r)</InputAdornment>,
            }}
            onChange={(e) => updateTimerCounter(e.target.value)}
            onKeyDown={(e) => ['e', 'E', '+', '-'].includes(e.key) && e.preventDefault()}
            onPaste={(e) => {
              e.preventDefault();
              return false;
            }}
          />
          <Button
            className="right-element"
            onClick={() => dispatch(setTimerCounter(Math.min(24, (timerCounter || 0) + 0.5)))}
            variant="outlined"
            size="small"
          >
            <AddIcon
              fontSize="inherit"
            />
          </Button>
        </div>
        <InnsendingLinje text="Kontaktperson" styling="page-item remove-bottom-padding" />
        <div className="page-item">
          <ChooseContactPerson
            onChange={(value) => dispatch(setContactPerson(value))}
            value={contactPerson}
          />
        </div>
        <InnsendingLinje text="Er det noe mer vi bør vite? (valgfritt)" styling="page-item remove-bottom-padding" />
        <p className="page-item">
          Spesielle forhold eller hensyn på anlegget, hvem skal motta leveransen
          eller annen nyttig informasjon som vil hjelp oss å planlegge bedre.
        </p>
        <div className="text-item flex-parent">
          <TextareaAutosize
            minRows={20}
            placeholder="Legg til kommentar"
            value={orderComment}
            className="fill-screen"
            onChange={(e) => dispatch(setOrderComment(e.target.value))}
          />
        </div>

        <div className="bottom-inner">
          <div className="bottom-element">
            <Button
              className="left-element"
              onClick={() => navigate(-1)}
              variant="contained"
              size="large"
            >
              {'<'}
            </Button>
            <CancelButton confirm={() => {
              dispatch(resetStates());
              return navigate('/container');
            }}
            />
            <Button
              className="right-element"
              disabled={disableNextPage}
              onClick={() => navigate('/container/ut/innsending')}
              variant="contained"
              size="large"
            >
              {'>'}
            </Button>
          </div>
        </div>
      </Content>
    </PageUnauthenticated>
  );
};
