import { Box } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import { Header } from '../../components/header';
import { Content } from '../../shared/components/Content';
import { NewEquipmentOrder } from '../../shared/components/newEquipmentOrder';
import { PageUnauthenticated } from '../../shared/components/page';

export const RentalPage = () => {
  const navigate = useNavigate();

  const onClose = (sucess: boolean) => {
    navigate(-1);
    if (sucess) toast.success('Utstyrbestilling sendt');
  };

  return (
    <PageUnauthenticated className="bestilling-page">
      <Header page="order">
        Internutleie
      </Header>
      <Content>
        <Box marginTop={5} padding={1}>
          <NewEquipmentOrder onClose={onClose} />
        </Box>
      </Content>

    </PageUnauthenticated>
  );
};
