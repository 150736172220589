/* eslint-disable no-param-reassign */
import { createSlice } from '@reduxjs/toolkit';
import { persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage';

const stateName = 'viewSetting';

interface InitialState {}

const initialState: InitialState = {};

const viewSettingSlice = createSlice({
  name: stateName,
  initialState,
  reducers: {
    reset(state) {
      Object.assign(state, initialState);
    },
  },
});

export const {
  reset,
} = viewSettingSlice.actions;

export default persistReducer({ key: stateName, storage }, viewSettingSlice.reducer);
