import {
  FC, useCallback, useEffect, useMemo,
} from 'react';
import Button from '@mui/material/Button';
import { useNavigate } from 'react-router-dom';
import { Checkbox, FormControlLabel } from '@mui/material';
import { isSameDay } from 'date-fns';
import { skipToken } from '@reduxjs/toolkit/dist/query';
import { useSelector, useDispatch } from '../../shared/hooks/redux';
import { ChooseDate } from '../../components/ChooseDate';
import { Header } from '../../components/header';
import { InnsendingLinje } from '../../components/Header4';
import { futureDateIsNotSelected } from '../../core/helpers/functions';
import { CancelButton } from '../../shared/components/CancelButton';
import { Content } from '../../shared/components/Content';
import { PageUnauthenticated } from '../../shared/components/page';
import { resetStates, setValue } from '../../core/redux/dieselOrderState';
import { hasMissing } from '../../core/helpers/hasMissing';
import { useNextWorkingDayWithRequirement } from '../../core/hooks/calendar';
import { useGetProjectQuery } from '../../core/redux/transport';
import { RYFYLKE_REGION } from '../../shared/constants';
import { weekdays } from '../../shared/logic/weekdays';
import { useHasAppRoles } from '../../shared/hooks/hasAppRole';
import { config } from '../../shared/config/transport';

export const DieselDeliveryDetailsPage: FC = () => {
  const navigate = useNavigate();
  const {
    projectTo,
    dieselType,
    dieselAmount,
    dieselContainerAmount,
    deliveryDate,
    userDefinedDeliveryDate,
    isEarlyDelivery,
  } = useSelector((s) => s.dieselOrderState);
  const dispatch = useDispatch();

  const { data: project } = useGetProjectQuery(projectTo ?? skipToken);

  /** List of allowed days */
  const reqDays = useMemo(
    (): number[] => (project?.region === RYFYLKE_REGION
      ? [weekdays.wednesday]
      : [weekdays.tuesday, weekdays.thursday]),
    [project],
  );
  const isDieselCoordinator = useHasAppRoles(config, 'diesel-koordinator');

  const reqFun = useCallback(
    (d: Date) => isDieselCoordinator || reqDays.includes(d.getDay()),
    [reqDays, isDieselCoordinator],
  );

  const nextDeliveryDayReqFun = useCallback(
    (d: Date) => reqDays.includes(d.getDay()),
    [reqDays],
  );
  const nextDeliveryDay = useNextWorkingDayWithRequirement(
    nextDeliveryDayReqFun,
  );

  // Update the date when the project changes
  useEffect(() => {
    if (deliveryDate.type !== 1) return;
    if (deliveryDate.day === nextDeliveryDay) return;
    dispatch(
      setValue({
        deliveryDate: {
          type: 1,
          day: nextDeliveryDay,
        },
      }),
    );
  }, [deliveryDate, nextDeliveryDay]);

  const setDeliveryDateToNextDeliveryDay = () => {
    dispatch(
      setValue({
        deliveryDate: {
          type: 1,
          day: nextDeliveryDay,
        },
      }),
    );
  };

  const hasMissingValue = hasMissing(
    projectTo,
    dieselType,
    dieselAmount,
    dieselContainerAmount,
  );

  useEffect(() => {
    if (hasMissingValue) navigate('/diesel');
  }, [hasMissingValue]);

  if (projectTo === null) {
    return null;
  }

  // all conditions should be false to move on to next page
  const disableNextPage = () => {
    if (futureDateIsNotSelected(deliveryDate, userDefinedDeliveryDate)) return true;
    if (
      deliveryDate.type === 2
      && userDefinedDeliveryDate !== null
      && !reqFun(userDefinedDeliveryDate)
    ) return true;

    if (
      deliveryDate.type !== 2
      && userDefinedDeliveryDate !== null
      && !reqFun(userDefinedDeliveryDate)
    ) return true;

    if (
      deliveryDate.type !== 2
      && deliveryDate.day !== null
      && !reqFun(deliveryDate.day)
    ) return true;

    return false;
  };

  return (
    <PageUnauthenticated className="leveringsdetaljer-page parent-pos">
      <Header>Dieselbestilling</Header>
      <Content>
        <InnsendingLinje
          text="Steg 2 - Leveringsdetaljer"
          styling="page-item"
        />

        <InnsendingLinje text="Leveringsdato" styling="page-item" />

        <div className="page-item">
          <Button
            onClick={setDeliveryDateToNextDeliveryDay}
            variant={deliveryDate.type === 1 ? 'contained' : 'outlined'}
            size="medium"
          >
            Neste leveringsdag
          </Button>
          <Button
            onClick={() => dispatch(
              setValue({
                deliveryDate: { type: 2, day: userDefinedDeliveryDate },
              }),
            )}
            variant={deliveryDate.type === 2 ? 'contained' : 'outlined'}
            size="medium"
          >
            Velg dato
          </Button>
          <Button
            disabled={!reqFun(new Date())}
            title={
              !reqFun(new Date())
                ? 'I dag er ikke en gyldig leveringsdato'
                : undefined
            }
            onClick={() => dispatch(
              setValue({
                deliveryDate: { type: 3, day: new Date() },
              }),
            )}
            variant={deliveryDate.type === 3 ? 'contained' : 'outlined'}
            size="medium"
          >
            I dag
          </Button>
        </div>
        <ChooseDate
          state="diesel"
          dateRequirementFunction={reqFun}
          nextDayLabel="Neste leveringsdag"
        />

        <FormControlLabel
          disabled={
            (deliveryDate.day && isSameDay(deliveryDate.day, new Date()))
            || false
          }
          className="page-item pt10"
          control={(
            <Checkbox
              checked={isEarlyDelivery}
              onClick={() => dispatch(setValue({ isEarlyDelivery: !isEarlyDelivery }))}
            />
          )}
          label="Kan leveres dagen før"
        />

        <div className="bottom-inner">
          <div className="bottom-element">
            <Button
              className="left-element"
              onClick={() => navigate('./../detaljer')}
              variant="contained"
              size="large"
            >
              {'<'}
            </Button>
            <CancelButton
              confirm={() => {
                dispatch(resetStates());
                return navigate('/diesel');
              }}
            />
            <Button
              className="right-element"
              onClick={() => navigate('./../tilleggsdetaljer')}
              variant="contained"
              size="large"
              disabled={disableNextPage()}
            >
              {'>'}
            </Button>
          </div>
        </div>
      </Content>
    </PageUnauthenticated>
  );
};
