import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { ContactPerson } from '../../components/ContactPerson';
import { DeliveryDate } from '../types/transport/utilityTypes';
import { DieselType } from '../types/transport/enums/dieselType';

interface DieselOrderState {
  projectTo: number | null;

  /** Type of diesel */
  dieselType: DieselType | null;
  dieselAmount: number | null;
  dieselContainerAmount: number | null;

  /** Code for gate to enter site */
  gateCode: string | null;
  /** Required. If true, gateCode must also be provided */
  gateCodeRequired: boolean | null;

  userDefinedDeliveryDate: Date | null;
  deliveryDate: DeliveryDate;
  isEarlyDelivery: boolean;

  orderComment: string;

  contactPerson: ContactPerson | null;
}

const initialState: DieselOrderState = {
  projectTo: null,
  dieselType: 'Colored',
  dieselAmount: null,
  dieselContainerAmount: null,
  gateCode: null,
  gateCodeRequired: null,
  userDefinedDeliveryDate: null,
  deliveryDate: { type: 0, day: null },
  isEarlyDelivery: false,
  orderComment: '',
  contactPerson: null,
};

const dieselOrderSlice = createSlice({
  name: 'dieselOrderState',
  initialState,
  reducers: {
    setValue(state, action: PayloadAction<Partial<DieselOrderState>>) {
      Object.assign(state, action.payload);
    },
    resetStates(state) {
      Object.assign(state, { ...initialState });
    },
  },
});

export const { setValue, resetStates } = dieselOrderSlice.actions;
export default dieselOrderSlice.reducer;
