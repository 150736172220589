import {
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  TextField,
  InputAdornment,
  FormGroup,
  FormControlLabel,
  Checkbox,
  Button,
  Box,
  CircularProgress,
} from '@mui/material';
import { DatePicker } from '@mui/x-date-pickers';
import {
  addDays,
  compareAsc,
  isValid,
  startOfDay,
  subDays,
} from 'date-fns';
import { useState, useEffect, useMemo } from 'react';
import { toast } from 'react-toastify';
import RemoveIcon from '@mui/icons-material/Remove';
import AddIcon from '@mui/icons-material/Add';
import LocalShippingIcon from '@mui/icons-material/LocalShipping';
import { ChooseDeliveryTime } from '../../../components/ChooseDeliveryTime';
import { ChooseContactPerson, ContactPerson } from '../../../components/ContactPerson';
import { InnsendingLinje } from '../../../components/Header4';
import { MachinePicker } from '../../../components/MachinePicker';
import { ProjectPicker } from '../../../components/ProjectPicker';
import { RadioYesNo } from '../../../components/RadioYesNo';
import { calculateTonnage, emptyGuard } from '../../../core/helpers/functions';
import { SearchSelect, SearchSelectDirect } from '../../../shared/components/searchSelect';
import { order2UpdateOrder } from '../../../core/helpers/converters';
import { useIsType } from '../../../core/hooks/useIsType';
import { usePutDieselOrderMutation, usePutOrderMutation } from '../../../core/redux/transport';
import { UpdateLoad } from '../../../core/types/transport/api/updateLoad';
import { VehicleType } from '../../../core/types/transport/enums/vehicleType';
import { WhenType } from '../../../core/types/transport/enums/whenType';
import { Project } from '../../../core/types/transport/project';
import { SelectContainer } from '../../../core/types/transport/selectContainer';
import { dateWithoutTimezone } from '../../../shared/logic/dates';
import { SelectItem } from '../../../shared/types/util/selectItem';
import { Order } from '../../../core/types/transport/order';
import { MassType } from '../../../core/types/transport/massType';
import { Container } from '../../../core/types/transport/container';
import { dieselTypeItems } from '../../dieselOrderPages/logic/dieselTypeItems';
import { config } from '../../../shared/config/transport';
import { RYFYLKE_REGION } from '../../../shared/constants';
import { weekdays } from '../../../shared/logic/weekdays';
import { useHasAppRoles } from '../../../shared/hooks/hasAppRole';

export const EditOrder = ({
  order,
  massTypes,
  containerTypes,
  wasteTypes,
  exContainers,
  onEndEdit: onEditEnd,
}: {
  order: Order | undefined,
  massTypes: MassType[] | undefined,
  containerTypes: string[] | undefined,
  wasteTypes: string[] | undefined,
  exContainers: Container[] | undefined,
  onEndEdit?: () => void,
}) => {
  const [putOrder, { isLoading: putLoading }] = usePutOrderMutation();
  const [putDieselOrder, { isLoading: putDieselLoading }] = usePutDieselOrderMutation();

  const isType = useIsType(order?.type);

  const loadCount = order?.loads && (order?.loads.length > 1 || false);

  const massList: SelectItem[] = [{ id: 0, label: '(tom)' }];
  const containerList: SelectItem[] = [];

  const [fromProject, setFromProject] = useState<Project|null>(order?.fromProject || null);
  const [toProject, setToProject] = useState<Project|null>(order?.toProject || null);
  const [internProject, setInternProject] = useState<Project|null>(order?.toProject || null);
  const [vehicleType, setVehicleType] = useState<VehicleType>(order?.vehicle || 'Double');
  const [timerCounter, setTimerCounter] = useState(order?.extraTimeInFacility || null);
  const [orderComment, setOrderComment] = useState(order?.comment || null);
  const [numberOfVehicles, setNumberOfVehicles] = useState(order?.numberOfVehicles || 0);
  const [splitLoad, setSplitLoad] = useState<boolean>(loadCount || false);
  const [deliveryTimeType, setDeliveryTimeType] = useState<WhenType>(order?.when.when || 'DuringTheDay');
  const [deliveryDate, setDeliveryDate] = useState<Date|null>(order?.date ? new Date(order?.date) : null);
  const [startTime, setStartTime] = useState<Date|null>(order?.when.from ? new Date(order?.when.from) : null);
  const [endTime, setEndTime] = useState<Date|null>(order?.when.to ? new Date(order?.when.to) : null);
  const [primaryLoad, setPrimaryLoad] = useState({
    id: order?.loads[0]?.id ?? null,
    massTypeId: order?.loads[0]?.type?.id ?? null,
    name: order?.loads[0]?.type?.name ?? '',
    amount: order?.loads[0]?.amount ?? null,
    machineExternal: order?.loads[0]?.machineExternal ?? null,
    machineInternalNumber: order?.loads[0]?.machineInternalNumber || order?.loads[0]?.machine?.internalNumber || null,
    dieselContainerCount: order?.loads[0]?.dieselContainerAmount ?? null,
    dieselType: order?.loads[0]?.dieselType ?? null,
  });
  const [secondaryLoad, setSecondaryLoad] = useState({
    id: order?.loads[1]?.id || null,
    massTypeId: order?.loads[1]?.type?.id || null,
    name: order?.loads[1]?.type?.name || '',
    amount: order?.loads[1]?.amount || null,
  });
  const [contactPerson, setContactPerson] = useState<ContactPerson | null>({
    name: order?.contactPersonName || '',
    number: order?.contactPersonPhoneNumber || '',
    employeeNumber: order?.contactPersonEmployeeNumber || undefined,
  });
  const [containers, setContainers] = useState<SelectContainer[]|undefined>(undefined);

  const [gateCode, setGateCode] = useState<string|undefined>(order?.gateCode);
  const [extraEquipment, setExtraEquipment] = useState<string|undefined>(order?.extraEquipment);
  const [hasDispensation, setHasDispensation] = useState<boolean|undefined>(order?.hasDispensation ?? undefined);
  const [daytimeDelivery, setDaytimeDelivery] = useState<boolean>(order?.daytimeDelivery ?? false);
  const [gateCodeRequired, setGateCodeRequired] = useState<boolean>(!!order?.gateCode);
  const [extraEquipmentRequired, setExtraEquipmentRequired] = useState<boolean>(!!order?.extraEquipment);

  const [isEarlyDelivery, setIsEarlyDelivery] = useState<boolean>(order?.earlyDelivery || false);

  const [isImportantDeadLine, setIsImportantDeadLine] = useState<boolean>(order?.importantDeadline || false);

  const singleDouble = ['Single', 'Double'];
  const [customVehicle, setCustomVehicle] = useState(!singleDouble.includes(order?.vehicle || ''));

  const isDisabled = !primaryLoad.amount || primaryLoad.amount > 10;

  useEffect(() => {
    if (isDisabled) {
      setSplitLoad(false);
    }
  }, [isDisabled]);

  const setContainersWithLoads = () => {
    if (!order || !order.type.includes('Container')) setContainers(undefined);
    setContainers(order?.loads.map((l, i): SelectContainer => ({
      id: i,
      isExternal: !!l.containerExternal,
      containerExternal: order?.type === 'ContainerDeliver'
        ? undefined
        : l!.containerExternal,
      containerInternalNumber: order?.type === 'ContainerDeliver'
        ? undefined
        : { id: l!.containerInternalNumber, label: l!.containerInternalNumber } as SelectItem,
      type: order?.type === 'ContainerDeliver'
        ? { id: l.containerType, label: l.containerType } as SelectItem
        : undefined,
      amount: l.amount,
      mass: order?.type === 'ContainerDeliver'
        ? { id: l.type?.id || 0, label: l.type?.name || '(tom)' } as SelectItem
        : { id: l.wasteType || 0, label: l.wasteType || '(tom)' } as SelectItem,
    })));
  };

  const [prevVehicle, setPrevVehicle] = useState<VehicleType>(vehicleType);
  const [prevCustom, setPrevCustom] = useState<boolean>(false);
  useEffect(() => {
    if (splitLoad) {
      if (vehicleType && singleDouble.includes(vehicleType)) {
        setPrevCustom(false);
      } else {
        setPrevCustom(true);
      }
      setPrevVehicle(vehicleType);
      setCustomVehicle(true);
      setVehicleType('CarWithTrailer');
    } else {
      setVehicleType(prevVehicle);
      setCustomVehicle(prevCustom);
    }
  }, [splitLoad]);

  useEffect(() => {
    setFromProject(order?.fromProject || null);
    setToProject(order?.toProject || null);
    setInternProject(order?.toProject || null);
    setVehicleType(order?.vehicle || 'Double');
    setIsEarlyDelivery(order?.earlyDelivery || false);
    setIsImportantDeadLine(order?.importantDeadline || false);
    setCustomVehicle(!singleDouble.includes(order?.vehicle || ''));
    setTimerCounter(order?.extraTimeInFacility || null);
    setOrderComment(order?.comment || null);
    setNumberOfVehicles(order?.numberOfVehicles || 0);

    setDeliveryTimeType(order?.when.when || 'DuringTheDay');
    setDeliveryDate(order?.date ? new Date(order?.date) : null);
    setStartTime(order?.when.from ? new Date(order?.when.from) : null);
    setEndTime(order?.when.to ? new Date(order?.when.to) : null);

    setSplitLoad(loadCount || false);
    setPrimaryLoad({
      id: order?.loads[0]?.id || null,
      massTypeId: order?.loads[0]?.type?.id || null,
      name: order?.loads[0]?.type?.name || '',
      amount: order?.loads[0]?.amount || null,
      machineExternal: order?.loads[0]?.machineExternal || null,
      machineInternalNumber: order?.loads[0]?.machineInternalNumber
        ?? order?.loads[0]?.machine?.internalNumber
        ?? null,
      dieselContainerCount: order?.loads[0]?.dieselContainerAmount ?? null,
      dieselType: order?.loads[0]?.dieselType ?? null,
    });
    setSecondaryLoad({
      id: order?.loads[1]?.id || null,
      massTypeId: order?.loads[1]?.type?.id || null,
      name: order?.loads[1]?.type?.name || '',
      amount: order?.loads[1]?.amount || null,
    });
    setContactPerson({
      name: order?.contactPersonName || '',
      number: order?.contactPersonPhoneNumber || '',
      employeeNumber: order?.contactPersonEmployeeNumber || undefined,
    });
    setContainersWithLoads();

    setGateCode(order?.gateCode);
    setExtraEquipment(order?.extraEquipment);
    setHasDispensation(order?.hasDispensation ?? undefined);
    setDaytimeDelivery(order?.daytimeDelivery ?? false);
    setGateCodeRequired(!!order?.gateCode);
    setExtraEquipmentRequired(!!order?.extraEquipment);
  }, [order, loadCount]);

  if (!order) {
    return null;
  }

  const getMassType = order.type === 'MassIn' ? 'Inn' : 'Ut';

  const updateTimerCounter = (value: string) => {
    if (value) {
      setTimerCounter(Number.parseFloat(value));
    } else {
      setTimerCounter(null);
    }
  };

  const updateNumberOfVehicles = (value: string) => {
    if (value) {
      setNumberOfVehicles(parseInt(value, 10));
    } else {
      setNumberOfVehicles(0);
    }
  };

  const updateCubic = (value: string, setSplit: boolean = false) => {
    if (value) {
      if (setSplit) {
        setSecondaryLoad((l) => ({ ...l, amount: Number.parseFloat(value) }));
      } else {
        setPrimaryLoad((l) => ({ ...l, amount: Number.parseFloat(value) }));
      }
    } else {
      // eslint-disable-next-line no-lonely-if
      if (setSplit) {
        setSecondaryLoad((l) => ({ ...l, amount: null }));
      } else {
        setPrimaryLoad((l) => ({ ...l, amount: null }));
      }
    }
  };

  const displaySplitLoad = () => (
    <>
      <span className="info-label-edit">Masse henger</span>
      <div className="info-content-edit">
        <FormControl size="small" fullWidth>
          <InputLabel>Velg massetype</InputLabel>
          <Select
            value={`${secondaryLoad.massTypeId}`}
            label="Velg massetype"
            onChange={(e) => setSecondaryLoad((l) => ({ ...l, massTypeId: e.target.value }))}
            defaultValue="Laster..."
            error={secondaryLoad.massTypeId === null}
          >
            {
                !massTypes
                  ? null
                  : massTypes.filter((e) => e.type === getMassType).map((p) => <MenuItem value={p.id}>{p.name}</MenuItem>)
              }
          </Select>
        </FormControl>
      </div>
      <div className="info-content-edit-small pt10">
        <TextField
          value={secondaryLoad.amount || ''}
          label="Mengde henger"
          onChange={(e) => updateCubic(e.target.value.replace(/[+-]/g, ''), true)}
          id="outlined-basic"
          placeholder="Mengde masse"
          type="number"
          autoComplete="off"
          error={
            (secondaryLoad.amount ? secondaryLoad.amount > 10 : false)
            || (secondaryLoad.amount === 0 || secondaryLoad.amount === null)
          }
          helperText={secondaryLoad.amount && secondaryLoad.amount > 10 ? 'Maks 10m³' : ''}
          InputProps={{
            inputProps: { min: 0, max: 10 },
            endAdornment: <InputAdornment position="end">m³</InputAdornment>,
          }}
          variant="outlined"
          size="small"
          fullWidth
        />
      </div>
    </>
  );

  const isMass = isType('Mass');
  const isMassInternal = isType('MassInternal');
  const isMassInOut = isType('MassIn', 'MassOut');
  const isContainer = isType('Container');
  const isHaul = isType('Haul');
  const isDiesel = isType('Diesel');
  const project = isType('MassOut', 'ContainerCollect', 'ContainerEmptying')
    ? fromProject
    : toProject;
  const today = startOfDay(new Date());

  if (order?.type === 'ContainerDeliver' && massTypes && containerTypes) {
    massTypes.filter((m) => m.type === 'Inn').forEach((m) => (massList.push({ id: m.id, label: m.name })));
    containerTypes.forEach((c) => (containerList.push({ id: c, label: c })));
  }
  if ((order?.type === 'ContainerCollect' || order?.type === 'ContainerEmptying') && wasteTypes && exContainers) {
    wasteTypes.forEach((m, i) => (massList.push({ id: i + 1, label: m })));
    exContainers.forEach((c) => (containerList.push({ id: c.internalNumber, label: c.internalNumber, disabled: c.assignmentId !== undefined || c.orderId !== undefined })));
  }

  const setVal = (itemId: number, val: Partial<SelectContainer>) => {
    if (!containers) return;
    setContainers(containers.map((c) => (c.id === itemId ? { ...c, ...val } : c)));
  };

  const setAmount = (itemId: number, value: string) => {
    const amount = value === '' ? 0 : parseInt(value, 10);
    if (Number.isNaN(amount)) return;
    setVal(itemId, { amount });
  };

  const updatePrimaryLoad = (v: Partial<typeof primaryLoad>) => {
    setPrimaryLoad((old) => ({ ...old, ...v }));
  };

  const addContainer = (external: boolean = false) => {
    if (!containers) return;
    const itemId = containers[containers.length - 1].id + 1;
    if (!itemId) return;
    setContainers(
      [
        ...containers,
        {
          id: itemId,
          isExternal: external,
          containerExternal: undefined,
          containerInternalNumber: undefined,
          type: undefined,
          amount: 1,
          mass: { id: 0, label: '(tom)' },
        },
      ],
    );
  };

  const removeContainer = (itemId: number) => {
    if (!containers) return;
    setContainers(containers.filter((f) => f.id !== itemId));
  };

  const duplicates = (order.type !== 'ContainerDeliver'
    ? 0
    : containers && containers.filter((c) => (
      containers.some((o) => o.id !== c.id && o.type?.label === c.type?.label && o.mass?.label === c.mass?.label)))
      .map((c) => c.id)) || [];

  const containersHasDuplicat = (duplicates !== null && duplicates.length > 0);

  const disableAddContainer = (order.type !== 'ContainerDeliver'
  && containers
  && containers.filter((v) => !v.isExternal && !v.containerInternalNumber).length
  >= containerList.filter((c) => !c.disabled).length)
  || containersHasDuplicat;

  /** List of allowed days for diesel */
  const reqDays = useMemo((): number[] => (
    toProject?.region === RYFYLKE_REGION ? [
      weekdays.wednesday,
    ] : [
      weekdays.tuesday,
      weekdays.thursday,
    ]
  ), [toProject]);
  const isDieselCoordinator = useHasAppRoles(config, 'diesel-koordinator');

  /** True if date should be disabled in the date picker */
  const shouldDisableDate = (d: Date) => {
    if (!isDiesel) return false;
    if (isDieselCoordinator) return false;
    return !reqDays.includes(d.getDay());
  };

  const limitDate = () => {
    const limit = new Date();
    limit.setHours(12, 0, 0, 0);
    if (deliveryDate && compareAsc(new Date(), limit) === 1) {
      return false;
    }
    return true;
  };

  const dateValid = () => !(deliveryDate && compareAsc(new Date(), subDays(deliveryDate.setHours(12, 0, 0, 0), 1)) === 1);

  const isFormOk = () => {
    if (isContainer) {
      if ((project === null)) return false;
      if (!containers) return false;
      if (order.type === 'ContainerDeliver'
        ? containers?.some((c) => !c.type || !c.amount || !c.mass) || containersHasDuplicat
        : containers?.some((c) => (!c.isExternal && !c.containerInternalNumber)
        || (c.isExternal && !c.containerExternal) || !c.mass)) return false;
      if ((contactPerson === null || contactPerson.name === '' || contactPerson.number === '')) return false;
      if ((deliveryDate && compareAsc(deliveryDate.setHours(0, 0, 0, 0), today.setHours(0, 0, 0, 0)) < 0)) return false;
      if (((deliveryTimeType === 'Custom') && (startTime === null || endTime === null))) return false;
      if ((dateValid() === false)) return false;
    }
    if (isMassInOut) {
      if ((project === null)) return false;
      if ((contactPerson === null || contactPerson.name === '' || contactPerson.number === '')) return false;
      if ((deliveryDate && compareAsc(deliveryDate.setHours(0, 0, 0, 0), today.setHours(0, 0, 0, 0)) < 0)) return false;
      if ((primaryLoad.amount === null || primaryLoad.amount === 0)) return false;
      if ((splitLoad && (secondaryLoad.massTypeId === null))) return false;
      if ((splitLoad && (secondaryLoad.amount === null || secondaryLoad.amount === 0))) return false;
      if (((deliveryTimeType === 'Custom') && (startTime === null || endTime === null))) return false;
      if ((dateValid() === false)) return false;
    }
    if (isMassInternal) {
      if ((internProject === null)) return false;
      if ((contactPerson === null || contactPerson.name === '' || contactPerson.number === '')) return false;
      if ((deliveryDate && compareAsc(deliveryDate.setHours(0, 0, 0, 0), today.setHours(0, 0, 0, 0)) < 0)) return false;
      if ((numberOfVehicles < 1)) return false;
      if (((deliveryTimeType === 'Custom') && (startTime === null || endTime === null))) return false;
      if ((dateValid() === false)) return false;
    }
    if (isHaul) {
      if (fromProject === null) return false;
      if (toProject === null) return false;
      if (gateCodeRequired && !gateCode) return false;
      if (extraEquipmentRequired && !extraEquipment) return false;
      if (!primaryLoad.machineExternal
        && !primaryLoad.machineInternalNumber) return false;
    }
    if (isDiesel) {
      if ((project === null)) return false;
      if ((contactPerson === null || contactPerson.name === '' || contactPerson.number === '')) return false;
      if (!deliveryDate) return false;
      if (shouldDisableDate(deliveryDate)) return false;
      if (primaryLoad === null) return false;
      if (primaryLoad.dieselType === null) return false;
      if (!primaryLoad.dieselContainerCount || primaryLoad.dieselContainerCount < 1) return false;
      if (!primaryLoad.amount || primaryLoad.dieselContainerCount < 1) return false;
      if ((dateValid() === false)) return false;
    }
    return true;
  };

  const updateOrderHandler = () => {
    if (order === undefined) return;
    let loads: UpdateLoad[] = [];
    if (isMassInOut) {
      if (primaryLoad.amount === null) return;
      if (primaryLoad.massTypeId === null) return;
      loads = [{
        id: primaryLoad.id ?? 0, // primaryLoad === null ? 0 : primaryLoad.id
        amount: primaryLoad.amount,
        massTypeId: parseInt(primaryLoad.massTypeId, 10),
      }];
      if (splitLoad) {
        if (secondaryLoad.amount === null) return;
        if (secondaryLoad.massTypeId === null) return;
        loads.push({
          id: secondaryLoad.id ?? 0,
          amount: secondaryLoad.amount,
          massTypeId: parseInt(secondaryLoad.massTypeId, 10),
        });
      }
    }
    if (isContainer) {
      containers?.forEach((c) => (loads.push({
        amount: c.amount,
        massTypeId: order.type === 'ContainerDeliver' ? parseInt(`${c.mass?.id}`, 10) || undefined : undefined,
        wasteType: order.type !== 'ContainerDeliver' ? emptyGuard(c.mass?.label) : undefined,
        containerInternalNumber: order.type !== 'ContainerDeliver' ? `${c.containerInternalNumber?.id}` || undefined : undefined,
        containerExternal: order.type !== 'ContainerDeliver' ? c.containerExternal : undefined,
        containerType: order.type === 'ContainerDeliver' ? c.type?.label : undefined,
      })));
    }
    if (isHaul) {
      loads = [{
        id: primaryLoad.id ?? 0,
        amount: 1,
        machineExternal: primaryLoad.machineExternal ?? undefined,
        machineInternalNumber: primaryLoad.machineInternalNumber ?? undefined,
      }];
    }
    const putData = order2UpdateOrder(order);
    if (isType('ContainerEmptying')) {
      putData.fromProject = fromProject?.id;
      putData.toProject = fromProject?.id;
    } else if (!isMassInternal) {
      putData.fromProject = fromProject?.id;
      putData.toProject = toProject?.id;
    } else {
      putData.toProject = internProject?.id;
    }
    if (putData.type === 'Diesel') {
      putData.load = {
        id: primaryLoad.id ?? undefined,
        amount: primaryLoad.amount ?? 0,
        dieselContainerAmount: primaryLoad.dieselContainerCount ?? undefined,
        dieselType: primaryLoad.dieselType ?? undefined,
      };
    }
    putData.loads = loads;
    putData.date = deliveryDate ? dateWithoutTimezone(deliveryDate) : '';
    putData.when = {
      when: deliveryTimeType,
      from: startTime?.toISOString() || undefined,
      to: endTime?.toISOString() || undefined,
    };
    putData.earlyDelivery = isEarlyDelivery;
    putData.importantDeadline = isImportantDeadLine;
    putData.vehicle = isContainer ? undefined : vehicleType;
    putData.extraTimeInFacility = timerCounter || 0;
    putData.comment = orderComment || '';
    putData.numberOfVehicles = numberOfVehicles;
    putData.contactPersonEmployeeNumber = contactPerson?.employeeNumber;
    putData.contactPersonName = contactPerson?.name ?? '';
    putData.contactPersonPhoneNumber = contactPerson?.number ?? '';
    if (putData.type === 'HaulHook' || putData.type === 'HaulSemi' || putData.type === 'Diesel') {
      putData.gateCode = gateCode;
    }
    if (putData.type === 'HaulHook' || putData.type === 'HaulSemi') {
      putData.extraEquipment = extraEquipment;
      putData.hasDispensation = hasDispensation;
      putData.daytimeDelivery = daytimeDelivery;
    }

    const updateOrder = putData.type === 'Diesel' ? putDieselOrder : putOrder;

    updateOrder({
      id: `${order.id}`,
      data: putData,
    })
      .then(() => onEditEnd?.())
      .catch(() => toast.error('Kunne ikke oppdatere bestilling, prøv igjen'));
  };

  const getDieselHelperText = (value: number | null | undefined) => {
    if (value === null || value === undefined) return 'Dette feltet er påkrevd';
    if (value < 1) return 'Denne verdien må være større enn 0';
    return ' ';
  };

  return (
    <div className="order-task">
      <div className="order-info">

        {isMassInOut && (
          <>
            <div className="info-item-edit">
              <span className="info-label-edit">Prosjekt</span>
              <div className="info-content-edit">
                <ProjectPicker
                  value={fromProject}
                  onChange={(e) => {
                    setFromProject(e);
                  }}
                  label="Fra prosjekt"
                  size="small"
                  required={fromProject === project}
                />
              </div>
              <div className="info-content-edit pt20">
                <ProjectPicker
                  value={toProject}
                  onChange={(e) => {
                    setToProject(e);
                  }}
                  label="Til prosjekt"
                  size="small"
                  required={toProject === project}
                />
              </div>
            </div>
            <div className="info-item-edit">
              <span className="info-label-edit">Masse</span>
              <div className="info-content-edit">
                <FormControl size="small" fullWidth>
                  <InputLabel>Velg massetype</InputLabel>
                  <Select
                    value={`${primaryLoad.massTypeId}`}
                    label="Velg massetype"
                    onChange={(e) => setPrimaryLoad((l) => ({
                      ...l,
                      massTypeId: e.target.value,
                    }))}
                    defaultValue="Laster..."
                  >
                    {!massTypes ? null : massTypes.filter((e) => e.type === getMassType).map((p) => <MenuItem value={p.id}>{p.name}</MenuItem>)}
                  </Select>
                </FormControl>
              </div>
              <div className="info-content-edit-small pt10">
                <TextField
                  value={primaryLoad.amount || ''}
                  label="Velg mengde"
                  onChange={(e) => updateCubic(e.target.value.replace(/[+-]/g, ''), false)}
                  id="outlined-basic"
                  placeholder="Mengde masse"
                  type="number"
                  error={primaryLoad.amount === 0 || primaryLoad.amount === null}
                  autoComplete="off"
                  InputProps={{
                    inputProps: { min: 0 },
                    endAdornment: <InputAdornment position="end">m³</InputAdornment>,
                  }}
                  variant="outlined"
                  size="small"
                  fullWidth
                />
              </div>
              <div>
                {`Cirka ${calculateTonnage(primaryLoad.amount || 0)} tonn`}
              </div>
              <div>
                <FormGroup>
                  <FormControlLabel disabled={isDisabled} control={<Checkbox checked={splitLoad} onClick={() => setSplitLoad(!splitLoad)} />} label="Splittet last (maks 10m³)" />
                </FormGroup>
              </div>
              {!isDisabled && splitLoad && displaySplitLoad()}
            </div>
            <div className="info-item-edit">
              <span className="info-label-edit">Ekstra tid i anlegget</span>
              <div className="timer-clicker">
                <Button className="left-element" onClick={() => setTimerCounter(timerCounter && Math.max(timerCounter - 0.5, 0))} variant="outlined" size="large">
                  <RemoveIcon fontSize="inherit" />
                </Button>
                <TextField
                  className="center-element"
                  size="small"
                  type="number"
                  value={timerCounter !== 0
                    ? Math.min(24, timerCounter || 0)
                    : ''}
                  InputProps={{
                    inputProps: { min: 0, max: 24 },
                    endAdornment: <InputAdornment position="end">time(r)</InputAdornment>,
                  }}
                  onChange={(e) => updateTimerCounter(e.target.value)}
                  onKeyDown={(e) => ['e', 'E', '+', '-'].includes(e.key) && e.preventDefault()}
                  onPaste={(e) => {
                    e.preventDefault();
                    return false;
                  }}
                />
                <Button className="right-element" onClick={() => setTimerCounter(Math.min(24, (timerCounter || 0) + 0.5))} variant="outlined" size="small">
                  <AddIcon
                    fontSize="inherit"
                  />
                </Button>
              </div>
            </div>
          </>
        )}
        {isMassInternal && (
          <>
            <div className="info-item-edit">
              <span className="info-label-edit">Prosjekt</span>
              <div className="info-content-edit">
                <ProjectPicker
                  value={internProject}
                  onChange={(e) => {
                    setInternProject(e);
                  }}
                  label="Til prosjekt"
                  size="small"
                  required
                />
              </div>
            </div>
            <div className="info-item-edit timer-clicker">
              <span className="info-label-edit">Antall kjøretøy</span>
              <div className="info-content-edit-small">
                <Button className="left-element" onClick={() => setNumberOfVehicles(Math.max(numberOfVehicles - 1, 1))} variant="outlined" size="large">
                  <RemoveIcon
                    fontSize="inherit"
                  />
                </Button>
                <TextField
                  className="center-element"
                  size="small"
                  type="number"
                  value={numberOfVehicles || ''}
                  error={numberOfVehicles === 0}
                  InputProps={{
                    inputProps: { min: 0 },
                    endAdornment: <InputAdornment position="end"><LocalShippingIcon fontSize="medium" color="primary" /></InputAdornment>,
                  }}
                  onChange={(e) => updateNumberOfVehicles(e.target.value)}
                  onKeyDown={(e) => ['e', 'E', '+', '-'].includes(e.key) && e.preventDefault()}
                  onPaste={(e) => {
                    e.preventDefault();
                    return false;
                  }}
                />
                <Button className="right-element" onClick={() => setNumberOfVehicles(numberOfVehicles + 1)} variant="outlined" size="large">
                  <AddIcon
                    fontSize="inherit"
                  />
                </Button>
              </div>
            </div>
          </>
        )}
        {isMass && (
          <div className="info-item-edit">
            <span className="info-label-edit">Ønsket kjøretøy</span>
            <div className="flexcenter">
              <Button
                disabled={splitLoad}
                onClick={() => {
                  setVehicleType('Single');
                  setCustomVehicle(false);
                }}
                variant={vehicleType === 'Single' ? 'contained' : 'outlined'}
              >Enkel
              </Button>
              <Button
                disabled={splitLoad}
                onClick={() => {
                  setVehicleType('Double');
                  setCustomVehicle(false);
                }}
                variant={vehicleType === 'Double' ? 'contained' : 'outlined'}
              >Dobbel
              </Button>
              <Button
                disabled={splitLoad}
                onClick={() => {
                  setVehicleType('Semi');
                  setCustomVehicle(true);
                }}
                variant={customVehicle ? 'contained' : 'outlined'}
              >Egendefinert
              </Button>
            </div>
            {customVehicle && (
              <div className="info-content-edit-small pt10">
                <Select
                  sx={{
                    width: '320px',
                  }}
                  value={vehicleType}
                  disabled={splitLoad}
                  onChange={(e) => {
                    if (e.target.value === 'Semi') {
                      setVehicleType('Semi');
                    } else if (e.target.value === 'CarWithTrailer') {
                      setVehicleType('CarWithTrailer');
                    } else if (e.target.value === 'SixxSix') {
                      setVehicleType('SixxSix');
                    } else {
                      setVehicleType('HookLift');
                    }
                  }}
                >
                  <MenuItem value="Semi">Semi</MenuItem>
                  <MenuItem value="CarWithTrailer">Bil med henger</MenuItem>
                  <MenuItem value="SixxSix">6x6</MenuItem>
                  {isMassInternal && <MenuItem value="HookLift">Kroklift</MenuItem>}
                </Select>
              </div>
            )}
          </div>
        )}
        {isContainer && containers && (
          <>
            <div className="info-item-edit">
              <span className="info-label-edit">Prosjekt</span>
              <div className="info-content-edit">
                <ProjectPicker
                  value={fromProject}
                  onChange={(e) => {
                    setFromProject(e);
                  }}
                  label={isType('ContainerEmptying') ? '' : 'Fra prosjekt'}
                  size="small"
                  required={fromProject === project}
                />
              </div>
              {!isType('ContainerEmptying') && (
                <div className="info-content-edit pt20">
                  <ProjectPicker
                    value={toProject}
                    onChange={(e) => {
                      setToProject(e);
                    }}
                    label="Til prosjekt"
                    size="small"
                    required={toProject === project}
                  />
                </div>
              )}
            </div>
            <Box className="container-orders-wrapper">
              {containers.map((c) => (
                <Box key={c.id} className="container-order-wrapper">
                  <div className="item">
                    <InnsendingLinje styling="field-title" text={order.type === 'ContainerDeliver' ? 'Container type' : 'Container'} />
                    {c.isExternal ? (
                      <TextField
                        label="Ekstern container"
                        fullWidth
                        helperText={!c.containerExternal ? 'Skriv inn ekstern container' : ''}
                        error={!c.containerExternal}
                        required
                        value={c.containerExternal}
                        onChange={(e) => setVal(c.id, {
                          containerExternal: e.currentTarget.value,
                        })}
                      />
                    ) : (
                      <SearchSelect
                        label=""
                        errorLabel={duplicates?.includes(c.id) ? 'Duplikat' : 'Velg en container'}
                        forceError={duplicates?.includes(c.id)}
                        required
                        value={order.type === 'ContainerDeliver' ? c.type : c.containerInternalNumber}
                        onChange={(p) => setVal(c.id, order.type === 'ContainerDeliver' ? {
                          type: p,
                        } : {
                          containerInternalNumber: p,
                        })}
                      >
                        {containerList.filter((cl) => !containers.some((v) => `${v.containerInternalNumber?.id}` === cl.id))}
                      </SearchSelect>
                    )}
                  </div>
                  {order.type === 'ContainerDeliver' && (
                    <div className="item">
                      <InnsendingLinje styling="field-title" text="Antall" />
                      <TextField type="number" fullWidth value={c.amount === 0 ? '' : c.amount} onChange={(e) => setAmount(c.id, e.currentTarget.value)} onBlur={() => c.amount < 1 && setAmount(c.id, '1')} />
                    </div>
                  )}
                  <div className="item">
                    <InnsendingLinje styling="field-title" text={order.type === 'ContainerDeliver' ? 'Masse' : 'Avfallstype'} />
                    <SearchSelect
                      label=""
                      errorLabel={order.type === 'ContainerDeliver' ? `${duplicates?.includes(c.id) ? 'Duplikat' : 'Velg masse'}` : 'Velg avfallstype'}
                      forceError={duplicates?.includes(c.id)}
                      required
                      value={c.mass}
                      onChange={(p) => setVal(c.id, {
                        mass: p,
                      })}
                    >
                      {massList}
                    </SearchSelect>
                  </div>
                  {containers.length > 1 && (
                    <div>
                      <Button className="delete-btn" size="large" color="error" variant="contained" onClick={() => removeContainer(c.id)}>Slett</Button>
                    </div>
                  )}
                </Box>
              ))}
            </Box>
            <Box display="flex" gap={1} className="add-btn">
              <Button
                sx={{
                  flex: '1',
                }}
                disabled={disableAddContainer}
                variant="contained"
                onClick={() => addContainer()}
              >
                Legg til flere
              </Button>
              {order.type !== 'ContainerDeliver' && (
                <Button
                  sx={{
                    flex: '1',
                  }}
                  variant="contained"
                  onClick={() => addContainer(true)}
                >
                  Legg til ekstern
                </Button>
              )}
            </Box>
          </>
        )}
        {isHaul && (
          <>
            <div className="info-item-edit">
              <span className="info-label-edit">Prosjekt</span>
              <div className="info-content-edit">
                <ProjectPicker
                  value={fromProject}
                  onChange={(e) => {
                    setFromProject(e);
                  }}
                  label="Fra prosjekt"
                  size="small"
                  required
                />
              </div>
              <div className="info-content-edit pt20">
                <ProjectPicker
                  value={toProject}
                  onChange={(e) => {
                    setToProject(e);
                  }}
                  label="Til prosjekt"
                  size="small"
                  required
                />
              </div>
            </div>
            <Box className="container-orders-wrapper">
              <MachinePicker
                size="small"
                value={{
                  internal: primaryLoad.machineInternalNumber,
                  external: primaryLoad.machineExternal,
                }}
                onChange={({
                  internal, external,
                }) => {
                  setPrimaryLoad((v) => ({
                    ...v,
                    machineInternalNumber: internal ?? null,
                    machineExternal: external ?? null,
                  }));
                }}
              />
            </Box>
            <InnsendingLinje text="Kode for port" styling="page-item" />
            <Box
              sx={{
                display: 'flex',
                gap: 1,
                flexDirection: 'column',
              }}
              className="page-item"
            >
              <RadioYesNo required value={gateCodeRequired} onChange={(v) => setGateCodeRequired(v)} />
              {gateCodeRequired && <TextField required fullWidth value={gateCode || ''} onChange={(e) => setGateCode(e.target.value)} label="Adgangskode" error={!gateCode} />}
            </Box>
            <InnsendingLinje text="Ekstra utstyr" styling="page-item" />
            <Box
              sx={{
                display: 'flex',
                gap: 1,
                flexDirection: 'column',
              }}
              className="page-item"
            >
              <RadioYesNo value={extraEquipmentRequired} onChange={(v) => setExtraEquipmentRequired(v)} />
              {extraEquipmentRequired && (
              <TextField
                required
                multiline
                fullWidth
                value={extraEquipment || ''}
                onChange={(e) => setExtraEquipment(e.target.value)}
                InputProps={{ minRows: 2 }}
                label="Ekstra utstyr"
                error={!extraEquipment}
              />
              )}
            </Box>
            <InnsendingLinje text="Dispensasjon" styling="page-item" />
            <div className="page-item">
              <RadioYesNo value={hasDispensation} onChange={(v) => setHasDispensation(v)} />
            </div>
            <InnsendingLinje text="Kan tas på dagtid" styling="page-item" />
            <div className="page-item">
              <RadioYesNo required value={daytimeDelivery} onChange={(v) => setDaytimeDelivery(v)} />
            </div>
          </>
        )}
        {isDiesel && (
          <>
            <div className="info-item-edit">
              <span className="info-label-edit">Prosjekt</span>
              <div className="info-content-edit">
                <ProjectPicker
                  value={toProject}
                  onChange={(e) => {
                    setToProject(e);
                  }}
                  label="Til prosjekt"
                  size="small"
                  required
                />
              </div>
            </div>
            <div className="info-item-edit timer-clicker g10">
              <span className="info-label-edit">Bestillingsdetaljer</span>
              <SearchSelectDirect
                value={primaryLoad.dieselType}
                onChange={(v) => updatePrimaryLoad({ dieselType: v ?? null })}
                label="Diesel type"
                required
              >{dieselTypeItems}
              </SearchSelectDirect>
              <TextField
                label="Antall tanker"
                type="number"
                fullWidth
                value={primaryLoad.dieselContainerCount ?? ''}
                onChange={(e) => updatePrimaryLoad({ dieselContainerCount: Math.round(+e.target.value) })}
                required
                error={!primaryLoad.dieselContainerCount || primaryLoad.dieselContainerCount < 1}
                helperText={getDieselHelperText(primaryLoad.dieselContainerCount)}
              />
              <TextField
                label="Estimert antall liter"
                type="number"
                fullWidth
                value={primaryLoad.amount ?? ''}
                onChange={(e) => updatePrimaryLoad({ amount: Math.round(+e.target.value) })}
                required
                error={!primaryLoad.amount || primaryLoad.amount < 1}
                helperText={getDieselHelperText(primaryLoad.amount)}
              />
              <InnsendingLinje text="Kode for port" />
              <Box
                sx={{
                  display: 'flex',
                  gap: 1,
                  flexDirection: 'column',
                }}
              >
                <RadioYesNo required value={gateCodeRequired} onChange={(v) => setGateCodeRequired(v)} />
                {gateCodeRequired && <TextField required fullWidth value={gateCode || ''} onChange={(e) => setGateCode(e.target.value)} label="Adgangskode" error={!gateCode} />}
              </Box>
            </div>
          </>
        )}
        <div className="info-item-edit">
          <span className="info-label-edit">Leveringsdato</span>
          <div className="info-content-edit-small">
            <DatePicker
              value={deliveryDate}
              views={['day']}
              minDate={limitDate() === false ? addDays(today, 2) : addDays(today, 1)}
              inputFormat="dd.MM.yyyy"
              mask="__.__.____"
              onChange={(e) => (e && isValid(e) ? setDeliveryDate(e) : null)}
              onAccept={(e) => setDeliveryDate(e)}
              shouldDisableDate={shouldDisableDate}
              renderInput={(p) => (
                // eslint-disable-next-line react/jsx-props-no-spreading
                <TextField {...p} />
              )}
            />
          </div>
          <FormControlLabel className="pt5" control={<Checkbox checked={isEarlyDelivery} onClick={() => setIsEarlyDelivery(!isEarlyDelivery)} />} label="Kan leveres dagen før" />
        </div>
        {!isDiesel && (
        <div className="info-item-edit">
          <span className="info-label-edit">Leveringstidspunkt</span>
          <div>
            <Button onClick={() => setDeliveryTimeType('DuringTheDay')} variant={deliveryTimeType === 'DuringTheDay' ? 'contained' : 'outlined'} size="medium">
              I løpet av dagen
            </Button>
            {!isMassInternal && (
              <>
                <Button onClick={() => setDeliveryTimeType('Morning')} variant={deliveryTimeType === 'Morning' ? 'contained' : 'outlined'} size="medium">
                  Morgen
                </Button>
                <Button onClick={() => setDeliveryTimeType('BeforeFood')} variant={deliveryTimeType === 'BeforeFood' ? 'contained' : 'outlined'} size="medium">
                  Før mat
                </Button>
                <Button onClick={() => setDeliveryTimeType('AfterFood')} variant={deliveryTimeType === 'AfterFood' ? 'contained' : 'outlined'} size="medium">
                  Etter mat
                </Button>
              </>
            )}
            <Button onClick={() => setDeliveryTimeType('Custom')} variant={deliveryTimeType === 'Custom' ? 'contained' : 'outlined'} size="medium">
              Egendefinert
            </Button>
          </div>
          {deliveryTimeType === 'Custom' && <ChooseDeliveryTime startTime={startTime} onStartTimeChange={(v) => setStartTime(v)} endTime={endTime} onEndTimeChange={(v) => setEndTime(v)} isImportantDeadLine={isImportantDeadLine} onIsImportantDeadlineChange={(v) => setIsImportantDeadLine(v)} deliveryDate={deliveryDate} />}
        </div>
        )}
        <div className="info-item-edit">
          <span className="info-label-edit">Kontaktperson</span>
          <div className="info-content-edit">
            <ChooseContactPerson onChange={(value) => setContactPerson(value)} value={contactPerson} />
          </div>
        </div>
        <div className="info-item-edit">
          <span className="info-label-edit">Bestillingskommentar</span>
          <div className="info-content-edit">
            <TextField id="outlined-multiline-flexible" variant="outlined" fullWidth multiline maxRows={4} onChange={(e) => setOrderComment(e.target.value)} value={orderComment || ''} />
          </div>
        </div>
        <div className="flex g10">
          <Button onClick={() => onEditEnd?.()} fullWidth disabled={putLoading || putDieselLoading} variant="outlined">Avbryt</Button>
          <Button onClick={updateOrderHandler} fullWidth disabled={!isFormOk() || putLoading || putDieselLoading} variant="contained">
            {putLoading ? <CircularProgress size={25} /> : 'Bekreft'}
          </Button>
        </div>
      </div>
    </div>
  );
};
