import {
  FormControlLabel,
  Radio,
  RadioGroup,
  useTheme,

} from '@mui/material';
import { useMemo } from 'react';

/** Show "Ja" and "Nei" radio buttons */
export const RadioYesNo = ({
  value = null,
  onChange = () => null,
  required = false,
}: {
  /** True for yes, false for no, null or undefined for neither */
  value?: boolean | null,
  /** New value will always be either yes or no */
  onChange?: (v: boolean) => void,
  /** If true, show as error until a selection is made */
  required?: boolean,
}) => {
  const theme = useTheme();

  const errorSx = useMemo(() => {
    if (!required) return {};
    return (
      value === null
        ? { color: theme.palette.error.main }
        : {}
    );
  }, [value, required]);

  return (
    <RadioGroup
      row
      value={value?.toString() || 'null'}
      onChange={(_, v) => onChange(v === true.toString())}
    >
      <FormControlLabel
        sx={errorSx}
        value={true.toString()}
        control={<Radio sx={errorSx} />}
        label="Ja"
      />
      <FormControlLabel
        sx={errorSx}
        value={false.toString()}
        control={<Radio sx={errorSx} />}
        label="Nei"
      />
    </RadioGroup>
  );
};
