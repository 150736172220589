import React, { FC } from 'react';
import {
  Card,
  CardContent,
  CircularProgress,
  Divider,
  Skeleton,
} from '@mui/material';
import Box from '@mui/material/Box';

export const LoadingProject: FC = () => (
  <Card
    sx={{ minWidth: 275, mt: 2 }}
  >
    <CardContent>
      <div className="project-item-wrapper">
        <Box
          sx={{
            display: 'flex',
            alignItems: 'center',
          }}
        >
          <span className="project-number"><Skeleton width={50} height={25} /></span>
          <Divider orientation="vertical" flexItem />
          <span className="flexme padleft">
            <span><Skeleton width="75%" height={25} /></span>
          </span>
          <span className="flexparentme">
            <span className="star loading"><CircularProgress size="small" /></span>
          </span>
        </Box>
      </div>
    </CardContent>
  </Card>
);
