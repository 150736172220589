import { FC } from 'react';
import {
  FormControl, InputLabel, MenuItem, Select,
} from '@mui/material';
import { setVehicleType } from '../../core/redux/massOrderState';
import { useDispatch, useSelector } from '../../shared/hooks/redux';

export const ChooseVehicle: FC<{ disable: boolean; internal?: boolean }> = ({
  disable,
  internal = false,
}) => {
  const { vehicleType } = useSelector((s) => s.massOrderState);
  const dispatch = useDispatch();
  return (
    <div className="page-item small-vertical-padding">
      <FormControl size="small" fullWidth>
        <InputLabel id="demo-simple-select-label">Velg kjøretøytype</InputLabel>
        <Select
          label="Velg kjøretøytype"
          value={vehicleType.type}
          disabled={disable}
          onChange={(e) => {
            let vehicleName = '';
            if (e.target.value === 4) {
              vehicleName = 'Semi';
            } else if (e.target.value === 5) {
              vehicleName = 'Bil med henger';
            } else if (e.target.value === 6) {
              vehicleName = '6x6';
            } else if (internal) {
              vehicleName = 'Kroklift';
            }
            dispatch(
              setVehicleType({
                type: parseInt(`${e.target.value}`, 10),
                name: vehicleName,
              }),
            );
          }}
        >
          <MenuItem key="4" value={4}>
            Semi
          </MenuItem>
          <MenuItem key="5" value={5}>
            Bil med henger
          </MenuItem>
          <MenuItem key="6" value={6}>
            6x6
          </MenuItem>
          {internal && (
            <MenuItem key={7} value={7}>
              Kroklift
            </MenuItem>
          )}
        </Select>
      </FormControl>
    </div>
  );
};
