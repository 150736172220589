import { config as workerConfig } from '../config/worker';
import { useRoles } from './useRoles';

const allowedRoles = ['project-owner', 'admin', 'super-admin'];

export function useCanAccessOperationStatus() {
  const { roles, isAuthenticated } = useRoles(workerConfig);
  let hasRole = false;
  hasRole = (roles && roles.some((r) => allowedRoles.includes(r))) ?? false;

  return { canAccessOperationStatus: hasRole, isAuthenticated };
}
