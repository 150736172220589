import { ReactNode, useMemo } from 'react';
import {
  Box,
  Button,
  ButtonGroup,
  CircularProgress,
  Typography,
} from '@mui/material';
import {
  startOfDay,
  subDays,
} from 'date-fns';
import './style.scss';
import { useParams } from 'react-router-dom';
import { skipToken } from '@reduxjs/toolkit/dist/query';
import { Order } from '../../core/types/transport/order';
import { Header } from '../../components/header';
import {
  useGetFullProjectQuery,
  useGetOrdersQuery,
} from '../../core/redux/transport';
import { useStateUrl } from '../../shared/hooks/params';
import { format, formatISODate } from '../../shared/logic/dates';
import { useGetAssignmentsQuery } from '../../shared/redux/rental';
import { EquipmentAssignment } from '../../shared/types/equipmentAssignment';
import { DateTime } from '../../shared/types/util/dateTime';
import { MassOrderItem } from './partials/massOrderItem';
import { RentalOrderItem } from './partials/rentalOrderItem';
import { Checkbox } from '../../shared/components/checkbox';

const emptyTransport: Order[] = [];
const emptyRental: EquipmentAssignment[] = [];

export const OrdersPage = () => {
  const { id } = useParams<'id'>();
  const [selection, setSelection] = useStateUrl<'Future'|'Last7Days'|'Last14Days' | 'Alle'>('tidsrom', 'Future', true);
  const [showTransportText, setShowTransport] = useStateUrl<'true'|'false'>('transport', 'true', true);
  const [showRentalText, setShowRental] = useStateUrl<'true'|'false'>('innleie', 'true', true);
  const showTransport = showTransportText === 'true';
  const showRental = showRentalText === 'true';

  const fromDate = useMemo(() => {
    const d = startOfDay(new Date());
    switch (selection) {
      case 'Last7Days': return formatISODate(subDays(d, 7));
      case 'Last14Days': return formatISODate(subDays(d, 14));
      case 'Alle': return undefined;
      default: return formatISODate(d);
    }
  }, [selection]);

  const {
    data: transportOrders = emptyTransport,
    isLoading: transportLoading,
  } = useGetOrdersQuery({
    from: fromDate,
    projectId: id,
  }, { skip: !showTransport });

  const {
    data: rentalOrders = emptyRental,
    isLoading: rentalLoading,
  } = useGetAssignmentsQuery({
    from: fromDate,
    projectId: id,
  }, { skip: !showRental });

  const { data: project, isLoading: projectLoading } = useGetFullProjectQuery(id ? { id, date: formatISODate(new Date()) } : skipToken);

  const isLoading = transportLoading || rentalLoading || projectLoading;

  const orders = useMemo(() => {
    const days = new Map<string, ReactNode[]>();
    const add = (date: Date|DateTime, data: ReactNode) => {
      const day = format(date, 'EEEE do MMM yyyy');
      if (days.has(day)) {
        days.set(day, [...days.get(day) as ReactNode[], data]);
      } else {
        days.set(day, [data]);
      }
    };
    if (showTransport) {
      transportOrders.forEach((o) => {
        add(o.date, <MassOrderItem order={o} />);
      });
    }
    if (showRental) {
      rentalOrders.forEach((o) => {
        add(o.from, <RentalOrderItem order={o} />);
      });
    }
    const items: ReactNode[] = [];
    days.forEach((v, k) => {
      items.push(
        // eslint-disable-next-line react/no-array-index-key
        <Box key={k}>
          <div className="day-header mt20 mb20">{k}</div>
          <Box sx={{ display: 'flex', flexDirection: 'column', gap: 2 }}>
            {v}
          </Box>
        </Box>,
      );
    });
    if (items.length <= 0) {
      return (
        <Box sx={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          flex: 1,
          paddingTop: '4vh',
        }}
        >
          {isLoading && (
            <CircularProgress />
          )}
          {!isLoading && (
            <Typography sx={{ color: '#666', fontSize: 20 }}>
              Ingen bestillinger i denne perioden
            </Typography>
          )}
        </Box>
      );
    }
    return items;
  }, [showTransport, showRental, transportOrders, rentalOrders, isLoading]);

  return (
    <Box sx={{ flex: 1 }} className="orders-page">
      <Header page="order" showBack={!!id}>
        {id ? 'Prosjektdetaljer' : 'Mine Bestillinger'}
      </Header>
      <Box
        sx={{
          flex: 1,
          paddingX: 1,
          paddingY: '3vh',
          display: 'flex',
          justifyContent: 'center',
        }}
      >
        <Box sx={{
          flex: 1,
          display: 'flex',
          flexDirection: 'column',
          maxWidth: '800px',
        }}
        >
          {isLoading && id && (
            <div className="header">Laster...</div>
          )}
          {!isLoading && !!project && (
            <>
              <div className="header">{`${project?.id} ${project?.projectName}`}</div>
              <div className="font-size-16 pt10 pb15">
                {`Ansvarlig: ${project?.responsible?.employeeNumber} ${project?.responsible?.fullName}`}
              </div>
            </>
          )}
          <div className="header">
            <span className="header-group">
              <ButtonGroup fullWidth variant="contained" aria-label="outlined primary button group">
                <Button variant={selection === 'Future' ? 'contained' : 'outlined'} onClick={() => setSelection('Future')}>Fremtidige</Button>
                <Button variant={selection === 'Last7Days' ? 'contained' : 'outlined'} onClick={() => setSelection('Last7Days')}>siste 7 dager</Button>
                <Button variant={selection === 'Last14Days' ? 'contained' : 'outlined'} onClick={() => setSelection('Last14Days')}>siste 14 dager</Button>
                <Button variant={selection === 'Alle' ? 'contained' : 'outlined'} onClick={() => setSelection('Alle')}>Alle</Button>
              </ButtonGroup>
            </span>
            <Box sx={{
              display: 'flex',
              gap: 2,
              pt: 2,
              color: 'black',
            }}
            >
              <Checkbox disabled={!showRental} checked={showTransport} onChange={(v) => showRental && setShowTransport(v ? 'true' : 'false')} label="Vis transportbestillinger" />
              <Checkbox disabled={!showTransport} checked={showRental} onChange={(v) => showTransport && setShowRental(v ? 'true' : 'false')} label="Vis internutleie" />
            </Box>
          </div>
          {orders}
        </Box>
      </Box>
    </Box>
  );
};
