import { useEffect, useMemo } from 'react';
import {
  FormControl,
  FormHelperText,
  TextField,
} from '@mui/material';
import {
  addHours,
  compareAsc,
  isAfter,
  isBefore,
  isToday,
  isValid,
  startOfToday,
} from 'date-fns';
import { DatePicker } from '@mui/x-date-pickers';
import {
  setUserDefinedDeliveryDate,
  setDeliveryDate,
} from '../../core/redux/massOrderState';
import {
  setUserDefinedDeliveryDate as setUserDefinedDeliveryDateContainer,
  setDeliveryDate as setDeliveryDateContainer,
} from '../../core/redux/containerOrderState';
import {
  setUserDefinedDeliveryDate as setUserDefinedDeliveryDateHaul,
  setDeliveryDate as setDeliveryDateHaul,
} from '../../core/redux/haulOrderState';
import { useNextWorkingDayWithRequirement } from '../../core/hooks/calendar';
import { futureDateIsNotSelected } from '../../core/helpers/functions';
import { useSelector, useDispatch } from '../../shared/hooks/redux';
import { useSetDeliveryDateToNextWorkingDay } from '../../pages/DeliveryDate';
import { setValue as setDieselValue } from '../../core/redux/dieselOrderState';

export const ChooseDate = ({
  state = 'transport',
  dateRequirementFunction = () => true,
  nextDayLabel = 'Neste arbeidsdag',
} : {
  state?: 'transport'|'container'|'haul'|'diesel',
  dateRequirementFunction?: (d: Date) => boolean,
  /** Label to use instead of 'Neste arbeidsdag' */
  nextDayLabel?: string,
}) => {
  const {
    userDefinedDeliveryDate,
    deliveryDate,
  } = useSelector((s) => {
    switch (state) {
      case 'haul': return s.haulOrderState;
      case 'container': return s.containerOrderState;
      case 'diesel': return s.dieselOrderState;
      default: return s.massOrderState;
    }
  });

  const dispatch = useDispatch();

  const setDeliveryDateToNextWorkingDay = useSetDeliveryDateToNextWorkingDay(state, dateRequirementFunction);

  const isDisabled = deliveryDate.type !== 2;

  useEffect(() => {
    if (deliveryDate.type === 0) {
      setDeliveryDateToNextWorkingDay();
    }
  }, [deliveryDate, setDeliveryDateToNextWorkingDay]);

  const nextWorkingDay = useNextWorkingDayWithRequirement(dateRequirementFunction);
  const limit = addHours(startOfToday(), 12);

  const dateOfDelivery = useMemo(() => {
    if (deliveryDate.type === 1 || deliveryDate.type === 3) {
      return deliveryDate.day;
    }
    return userDefinedDeliveryDate;
  }, [deliveryDate, userDefinedDeliveryDate]);

  const showLateOrderWarning = useMemo(() => {
    if (futureDateIsNotSelected(deliveryDate, userDefinedDeliveryDate)) {
      return false;
    }
    if (dateOfDelivery && (isToday(dateOfDelivery)
    || (isAfter(new Date(), limit) && isBefore(dateOfDelivery, nextWorkingDay)))) {
      return true;
    }
    return false;
  }, [
    futureDateIsNotSelected,
    deliveryDate,
    userDefinedDeliveryDate,
    dateOfDelivery,
    nextWorkingDay,
    limit,
  ]);

  const label = useMemo(() => {
    if (deliveryDate.type === 1) {
      return nextDayLabel;
    }
    if (deliveryDate.type === 3) {
      return 'I dag';
    }
    return 'Velg dato';
  }, [deliveryDate]);

  const setDate = (d: Date | null) => {
    if (state === 'haul') {
      dispatch(setUserDefinedDeliveryDateHaul(d));
      dispatch(setDeliveryDateHaul({ type: 2, day: d }));
    } else if (state === 'container') {
      dispatch(setUserDefinedDeliveryDateContainer(d));
      dispatch(setDeliveryDateContainer({ type: 2, day: d }));
    } else if (state === 'diesel') {
      dispatch(setDieselValue({
        userDefinedDeliveryDate: d,
        deliveryDate: { type: 2, day: d },
      }));
    } else {
      dispatch(setUserDefinedDeliveryDate(d));
      dispatch(setDeliveryDate({ type: 2, day: d }));
    }
  };

  return (
    <div className="page-item vertical-padding">
      <FormControl fullWidth>
        <DatePicker
          views={['day']}
          label={label}
          minDate={new Date()}
          inputFormat="dd.MM.yyyy"
          mask="__.__.____"
          value={dateOfDelivery}
          onChange={(e) => (e && isValid(e) ? setDate(e) : setDate(null))}
          disabled={isDisabled}
          onAccept={setDate}
          shouldDisableDate={(d) => (!dateRequirementFunction(d))}
          renderInput={(params) => (
            <TextField
            // eslint-disable-next-line react/jsx-props-no-spreading
              {...params}
              error={(
                deliveryDate.type === 2
                && userDefinedDeliveryDate === null
              ) || (
                deliveryDate.type === 2
                && userDefinedDeliveryDate !== null
                && compareAsc(
                  userDefinedDeliveryDate.setHours(12, 0, 0, 0),
                  new Date().setHours(12, 0, 0, 0),
                ) < 0
              ) || (
                dateOfDelivery
                  ? !dateRequirementFunction(dateOfDelivery)
                  : false
              )}
            />
          )}
        />
        {showLateOrderWarning && (
        <FormHelperText error sx={{ typography: 'body1' }}>
          NB: Frist for innsending er kl 12:00 siste arbeidsdag før bestillingsdato.<br />
          Bestillinger mottatt etter dette vil ikke bli prioritert og er ikke garantert behandlet.
        </FormHelperText>
        )}
        {futureDateIsNotSelected(deliveryDate, userDefinedDeliveryDate) && (
        <FormHelperText error sx={{ typography: 'body1' }}>
          Dato må være fremtidig og gyldig (dd.mm.yyyy)
        </FormHelperText>
        )}
      </FormControl>
    </div>
  );
};
