import { skipToken } from '@reduxjs/toolkit/dist/query';
import { useNavigate, useParams } from 'react-router-dom';
import { useEffect } from 'react';
import { SelectItem } from '../../shared/types/util/selectItem';
import {
  useGetAllProjectsQuery,
  useGetEquipmentAssignmentQuery,
} from '../../shared/redux/rental';
import { EquipmentProject } from '../../shared/types/equipmentProject';
import { PageUnauthenticated } from '../../shared/components/page';
import { Header } from '../../components/header';
import { Content } from '../../shared/components/Content';
import { EditEquipmentOrder } from '../../shared/components/editEquipmentOrder';

const emptyProjects: EquipmentProject[] = [];

export const OrderDetailsRentalPage = () => {
  const { id } = useParams<'id'>();
  const navigate = useNavigate();

  const {
    data: order,
    isError,
    isFetching,
  } = useGetEquipmentAssignmentQuery(id || skipToken);
  const { data: projects = emptyProjects } = useGetAllProjectsQuery();

  useEffect(() => {
    if (!isFetching && isError) {
      navigate(-1);
    }
  }, [isFetching, isError, navigate]);

  const projectList: SelectItem[] = projects.map((p) => ({
    id: p.id,
    label: `${p.projectName}`,
  }));

  const onClose = () => {
    navigate(-1);
  };

  return (
    <PageUnauthenticated>
      <Header>Endre bestilling</Header>
      <Content sx={{ paddingY: '3vh', paddingX: 2 }}>
        <EditEquipmentOrder
          assignment={order}
          projects={projectList}
          onClose={onClose}
          refetch={() => null}
          isEdit
        />
      </Content>
    </PageUnauthenticated>
  );
};
