import { Box, Button } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import OrdersIcon from '@mui/icons-material/ViewStream';
import ProjectIcon from '@mui/icons-material/Factory';
import MassIcon from '@mui/icons-material/LocalShipping';
import DieselIcon from '@mui/icons-material/LocalGasStation';
import ContainerIcon from '@mui/icons-material/CalendarViewWeek';
import HaulIcon from '@mui/icons-material/MoveDown';
import RentalIcon from '@mui/icons-material/Construction';
import { ReactNode } from 'react';
import { Header } from '../../components/header';
import { Authenticate } from '../../components/authenticate';
import { config as rentalConfig } from '../../shared/config/rental';
import { config as transportConfig } from '../../shared/config/transport';
import { PageUnauthenticated } from '../../shared/components/page';
import { useGetProjectsQuery } from '../../core/redux/transport';
import { useNextWorkingDay } from '../../core/hooks/calendar';

const NavButton = ({
  path,
  icon,
  label,
}: {
  path: string;
  icon: ReactNode;
  label: string;
}) => {
  const navigate = useNavigate();
  return (
    <Button size="large" variant="contained" onClick={() => navigate(path)}>
      {icon}
      <Box flex={1}>{label}</Box>
    </Button>
  );
};

export const LandingPage = () => {
  useGetProjectsQuery(); // Prefetch projects
  useNextWorkingDay(); // Prefetch next working day
  return (
    <PageUnauthenticated className="bestilling-page">
      <Header page="order">Bestilling</Header>
      <Box
        display="flex"
        justifyContent="center"
        alignItems="center"
        height="80%"
        paddingX={2}
      >
        <Box
          maxWidth={300}
          width="100%"
          display="flex"
          flexDirection="column"
          gap={3}
        >
          <Authenticate config={transportConfig}>
            <NavButton
              path="/bestilling"
              icon={<MassIcon />}
              label="Massebestilling"
            />
            <NavButton
              path="/container"
              icon={<ContainerIcon />}
              label="Containerbestilling"
            />
            <NavButton
              path="/maskinslep"
              icon={<HaulIcon />}
              label="Maskinslep"
            />
            <NavButton
              path="/diesel"
              icon={<DieselIcon />}
              label="Dieselbestilling"
            />
          </Authenticate>
          <Authenticate config={rentalConfig}>
            <NavButton
              path="/internutleie"
              icon={<RentalIcon />}
              label="Internutleie"
            />
          </Authenticate>
          <NavButton
            path="/bestillinger"
            icon={<OrdersIcon />}
            label="Mine bestillinger"
          />
          <NavButton
            path="/prosjekter"
            icon={<ProjectIcon />}
            label="Prosjekt"
          />
        </Box>
      </Box>
    </PageUnauthenticated>
  );
};
