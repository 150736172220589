import {
  FC,
  ReactNode,
  useEffect,
} from 'react';
import { Button, CircularProgress } from '@mui/material';
import { format } from 'date-fns';
import { useNavigate } from 'react-router-dom';
import { skipToken } from '@reduxjs/toolkit/dist/query';
import { useSelector, useDispatch } from '../../shared/hooks/redux';
import { PageUnauthenticated } from '../../shared/components/page';
import { Header } from '../../components/header';
import { InnsendingLinje } from '../../components/Header4';
import { resetStates } from '../../core/redux/containerOrderState';
import { useGetProjectQuery, usePostDieselOrderMutation } from '../../core/redux/transport';
import { CancelButton } from '../../shared/components/CancelButton';
import { Content } from '../../shared/components/Content';
import { dateWithoutTimezone } from '../../shared/logic/dates';
import { hasMissing } from '../../core/helpers/hasMissing';
import { dieselTypeItems } from './logic/dieselTypeItems';
import { CreateDieselOrder } from '../../core/types/transport/api/createDieselOrder';

const Line = ({
  title,
  children,
  suffix,
}: {
  title: string,
  children: ReactNode,
  suffix?: string,
}) => (
  <>
    <InnsendingLinje text={title} />
    <div>
      {children} {suffix}
    </div>
  </>
);

/** If active is false, null is returned, otherwise value is returned as-is */
const getOptionalValue = (active: boolean|null, value: string|null) => {
  if (!active) return null;
  return value;
};

export const DieselSubmitPage: FC = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [sendOrder, sendOrderResponse] = usePostDieselOrderMutation();
  const {
    projectTo,
    dieselType,
    dieselAmount,
    dieselContainerAmount,
    deliveryDate,
    isEarlyDelivery,
    orderComment,
    contactPerson,
    gateCode,
    gateCodeRequired,
  } = useSelector((s) => s.dieselOrderState);
  const { data: toProject } = useGetProjectQuery(projectTo ?? skipToken);

  const hasMissingValue = hasMissing(
    projectTo,
    dieselType,
    dieselAmount,
    dieselContainerAmount,
    deliveryDate,
  );

  useEffect(() => {
    if (hasMissingValue) navigate('/diesel');
  }, [hasMissingValue]);

  useEffect(() => {
    if (sendOrderResponse.isSuccess) {
      navigate(`/bestilling/bekreftelse/${sendOrderResponse.data.id}`);
    }
  }, [sendOrderResponse.isSuccess]);

  useEffect(() => {
    if (sendOrderResponse.isError) {
      navigate('./');
    }
  }, [sendOrderResponse.isError]);

  if (projectTo === null) {
    return null;
  }

  const contactId: number | undefined = contactPerson?.employeeNumber || undefined;
  const contactName: string | undefined = contactPerson?.name || undefined;
  const contactNumber: string | undefined = contactPerson?.number || undefined;

  const postOrder = () => {
    if (!deliveryDate.day) {
      throw new Error('Leveringstid er ikke spesifisert');
    }
    if (!toProject) {
      throw new Error('Feil ved innhenting av data. Prøv igjen');
    }

    if (!dieselAmount) return;
    if (!dieselContainerAmount) return;
    if (!dieselType) return;

    const gateCodeValue = getOptionalValue(
      gateCodeRequired,
      gateCode,
    ) ?? undefined;

    const orderToSend: CreateDieselOrder = {
      type: 'Diesel',
      toProject: toProject.id,
      date: deliveryDate.day && dateWithoutTimezone(deliveryDate.day),
      load: {
        amount: dieselAmount,
        dieselContainerAmount,
        dieselType,
      },
      gateCode: gateCodeValue,
      earlyDelivery: isEarlyDelivery,
      comment: orderComment || '',
      contactPersonEmployeeNumber: contactId,
      contactPersonName: contactName,
      contactPersonPhoneNumber: contactNumber,
    };
    sendOrder(orderToSend);
  };

  return (
    <PageUnauthenticated className="innsending-page parent-pos">
      <Header>Dieselbestilling</Header>
      <Content>
        <InnsendingLinje text="Steg 4 - Innsending" styling="page-item" />
        <div className="extra-inline-padding">
          <InnsendingLinje text="Din bestilling er klar til innsending" />
          <p>
            Les gjennom oppsummeringen under og sjekk at informasjonen stemmer før du sender
          </p>

          <Line title="Prosjekt">
            {`${toProject?.id} ${toProject?.projectName}`}
          </Line>

          <Line title="Dieseltype">
            {dieselTypeItems.find((i) => i.id === dieselType)?.label ?? dieselType}
          </Line>

          <Line title="Antall tanker" suffix="stk">
            {dieselContainerAmount}
          </Line>

          <Line title="Estimert antall liter" suffix="liter">
            {dieselAmount}
          </Line>

          <Line title="Leveringsdato">
            {`${deliveryDate.day && format(deliveryDate.day, 'dd.MM.yyyy')}`}
          </Line>

          <Line title="Kan leveres dagen før">
            {isEarlyDelivery ? 'Ja' : 'Nei'}
          </Line>

          <Line title="Kode for port">
            {getOptionalValue(gateCodeRequired, gateCode) ?? 'Nei'}
          </Line>

          <Line title="Kontaktperson">
            {`${contactPerson?.name} - ${contactPerson?.number}`}
          </Line>

          <div>
            <InnsendingLinje text="Kommentar" />
          </div>
          <div className="display-linebreak">
            {orderComment}
          </div>
        </div>

        {/* Bottom navigation */}
        <div className="bottom-inner">
          <div className="bottom-element">
            <Button
              className="left-element"
              onClick={() => navigate(-1)}
              variant="contained"
              size="large"
            >
              Tilbake
            </Button>
            <CancelButton confirm={() => {
              dispatch(resetStates());
              return navigate('/diesel');
            }}
            />
            <Button
              className="right-element"
              disabled={sendOrderResponse.isLoading}
              onClick={postOrder}
              variant="contained"
              size="large"
            >
              Send inn
            </Button>
            {sendOrderResponse.isLoading && (
            <CircularProgress
              size={24}
              sx={{
                position: 'absolute',
                top: '50%',
                left: '50%',
                marginTop: '-12px',
                marginLeft: '-12px',
              }}
            />
            )}
          </div>
        </div>
      </Content>
    </PageUnauthenticated>
  );
};
