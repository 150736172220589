import {
  FC,
  useEffect,
  useMemo,
  useState,
} from 'react';
import {
  Autocomplete,
  Checkbox,
  TextField,
} from '@mui/material';
import { useGetStaffQuery } from '../../core/redux/transport';
import { msalInstance } from '../../shared/msal';
import { Staff } from '../../core/types/transport/staff';

export interface ContactPerson {
  name: string,
  number: string,
  employeeNumber?: number,
}

interface StaffOption extends ContactPerson {
  id: string, // Made up of name+number
  label: string,
}

export const ChooseContactPerson: FC<{
  value: ContactPerson | null,
  onChange: (value: ContactPerson | null) => void,
  externalHelpText?: string,
  checkboxLabel?: string,
  inputLabel?: string,

}> = ({
  value = { name: '', number: '' },
  onChange,
  externalHelpText = 'Fyll inn navn og telefonnummer på tredjeparts kontaktperson',
  checkboxLabel = 'Tredjepart som kontaktperson',
  inputLabel = 'Søk etter kontaktperson',
}) => {
  const [isCustom, setIsCustom] = useState<boolean>(false);
  const [customContact, setCustomContact] = useState<ContactPerson | null>(null);
  const [listContact, setListContact] = useState<ContactPerson | null>(null);
  const { data: staff } = useGetStaffQuery();
  const loggedInAccount = msalInstance.getActiveAccount();
  const loggedInUser = staff?.find((e) => e.email === loggedInAccount?.username);

  const convertToContactPerson = (s: Staff): ContactPerson => ({
    name: s.fullName,
    number: s.mobilePhoneWork,
    employeeNumber: s.employeeNumber,
  });

  useEffect(() => {
    if (staff && value !== null) {
      if (!staff.some((s) => value.employeeNumber === s.employeeNumber)) {
        setIsCustom(true);
        setCustomContact(value);
      } else {
        setListContact(value);
      }
    }
  }, [staff, value]);

  useEffect(() => {
    if (loggedInUser && value === null) {
      onChange(convertToContactPerson(loggedInUser));
    }
  }, [loggedInUser]);

  const getLabel = (p: ContactPerson) => (
    `${p.name ? p.name : 'Navn mangler'} - ${p.number ? p.number : 'Telefonnummer mangler'}`
  );
  const convertToOption = (p: ContactPerson | null): StaffOption | null => {
    if (p === null) return null;

    return {
      ...p,
      id: `${p.name}${p.number}`,
      label: getLabel(p),
    };
  };

  const staffOptions = useMemo(() => {
    const result = (staff?.map((p) => convertToOption(convertToContactPerson(p)))
      .filter((x) => x !== null) as StaffOption[] ?? [])
      .sort((a, b) => a.label.localeCompare(b.label, 'nb'));
    return result;
  }, [staff]);

  const displayInternalContactPerson = () => (
    <div>
      <Autocomplete
        disablePortal
        value={convertToOption(value)}
        clearOnBlur
        clearOnEscape
        noOptionsText="Laster..."
        isOptionEqualToValue={
          (o: StaffOption, v: StaffOption) => o.id === v.id
        }
        onChange={(e, newValue: StaffOption|null) => onChange(newValue)}
        options={staffOptions}
        renderInput={(params) => (
          <TextField
          // eslint-disable-next-line react/jsx-props-no-spreading
            {...params}
            error={value === null}
            label={inputLabel}
          />
        )}
      />
    </div>
  );

  const displayExternalContactPerson = () => (
    <div>
      {externalHelpText}
      <div className="small-vertical-padding">
        <TextField
          value={value?.name}
          onChange={(e) => onChange(value === null ? { name: e.target.value, number: '' } : { ...value, name: e.target.value })}
          id="outlined-basic"
          placeholder="Navn."
          variant="outlined"
          size="small"
          type="string"
          error={value === null || value.name === ''}
          fullWidth
        />
      </div>
      <div className="pt5">
        <TextField
          value={value?.number}
          onChange={(e) => onChange(value === null ? { name: '', number: e.target.value } : { ...value, number: e.target.value })}
          id="outlined-basic"
          placeholder="Telefonnummer"
          variant="outlined"
          size="small"
          type="number"
          error={value === null || value?.number === ''}
          fullWidth
        />
      </div>
    </div>
  );

  return (
    <div className="fullWidth">
      {!isCustom ? displayInternalContactPerson() : displayExternalContactPerson()}
      <div>
        <Checkbox
          checked={isCustom}
          onClick={() => {
            onChange(!isCustom ? customContact : listContact);
            setIsCustom(!isCustom);
          }}
        />
        {checkboxLabel}
      </div>
    </div>
  );
};
