import {
  Button,
  InputAdornment,
  TextField,
} from '@mui/material';
import React, { FC } from 'react';
import AddIcon from '@mui/icons-material/Add';
import RemoveIcon from '@mui/icons-material/Remove';
import LocalShippingIcon from '@mui/icons-material/LocalShipping';
import { useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from '../../../../shared/hooks/redux';
import { ChooseDate } from '../../../../components/ChooseDate';
import { ChooseDeliveryTime } from '../../../../components/ChooseDeliveryTime';
import { ChooseVehicle } from '../../../../components/ChooseVehicle';
import { Header } from '../../../../components/header';
import { InnsendingLinje } from '../../../../components/Header4';
import { futureDateIsNotSelected } from '../../../../core/helpers/functions';
import {
  setDeliveryDate,
  setDeliveryTimeType,
  setStartTime,
  setEndTime,
  setIsImportantDeadline,
  resetStates,
  setNumberOfVehicles, setVehicleType,
} from '../../../../core/redux/massOrderState';
import { CancelButton } from '../../../../shared/components/CancelButton';
import { Content } from '../../../../shared/components/Content';
import { PageUnauthenticated } from '../../../../shared/components/page';
import { useSetDeliveryDateToNextWorkingDay } from '../../../DeliveryDate';

export const InternkjoeringPage: FC = () => {
  const navigate = useNavigate();
  const {
    startTime,
    deliveryTimeType,
    endTime,
    vehicleType,
    deliveryDate,
    userDefinedDeliveryDate,
    timeError,
    numberOfVehicles,
    projectTo,
    isImportantDeadLine,
  } = useSelector((s) => s.massOrderState);
  const dispatch = useDispatch();
  const setDeliveryDateToNextWorkingDay = useSetDeliveryDateToNextWorkingDay();

  if (projectTo === null) {
    navigate('/bestilling');
    return null;
  }

  // all conditions should be false to move on to next page
  const disableNextPage = () => {
    const checkVehicleSelected = vehicleType.type === 0 || vehicleType.type === 3;
    const checkVehicleAmount = numberOfVehicles < 1;
    const checkTimeSelected = deliveryTimeType.type === 5
    && (startTime === null || endTime === null);
    const checkValidTime = timeError !== null;
    if (deliveryTimeType.type === 5) {
      return (
        checkVehicleSelected
        || checkVehicleAmount
        || futureDateIsNotSelected(deliveryDate, userDefinedDeliveryDate)
        || checkTimeSelected
        || checkValidTime
      );
    }
    return (
      checkVehicleSelected
    || checkVehicleAmount
    || futureDateIsNotSelected(deliveryDate, userDefinedDeliveryDate)
    || checkTimeSelected
    );
  };

  const updateNumberOfVehicles = (value: string) => {
    if (value) {
      dispatch(setNumberOfVehicles(parseInt(value, 10)));
    } else {
      dispatch(setNumberOfVehicles(0));
    }
  };

  return (
    <PageUnauthenticated className="leveringsdetaljer-page parent-pos">
      <Header>Massebestilling</Header>
      <Content>
        <InnsendingLinje text="Steg 1 - Leveringsdetaljer" styling="page-item" />
        <InnsendingLinje text="Kjøretøy" styling="page-item" />

        <div className="page-item">
          <Button
            onClick={() => dispatch(setVehicleType({ type: 1, name: 'Enkel' }))}
            variant={vehicleType.type === 1 ? 'contained' : 'outlined'}
            size="medium"
          >
            Enkel
          </Button>
          <Button
            onClick={() => dispatch(setVehicleType({ type: 2, name: 'Dobbel' }))}
            variant={vehicleType.type === 2 ? 'contained' : 'outlined'}
            size="medium"
          >
            Dobbel
          </Button>
          <Button
            onClick={() => dispatch(setVehicleType({ type: 3, name: 'Egendefinert' }))}
            variant={
              (vehicleType.type === 3
              || vehicleType.type === 4
              || vehicleType.type === 5
              || vehicleType.type === 6
              || vehicleType.type === 7) ? 'contained' : 'outlined'
            }
            size="medium"
          >
            Egendefinert
          </Button>
        </div>
        {(
          vehicleType.type === 3
        || vehicleType.type === 4
        || vehicleType.type === 5
        || vehicleType.type === 6
        || vehicleType.type === 7)
        && <ChooseVehicle disable={false} internal />}

        <InnsendingLinje text="Antall kjøretøy" styling="page-item" />

        <div className="page-item vehicle-clicker">
          <Button className="left-element" onClick={() => dispatch(setNumberOfVehicles(Math.max(numberOfVehicles - 1, 1)))} variant="outlined" size="large">
            <RemoveIcon
              fontSize="inherit"
            />
          </Button>
          <TextField
            className="center-element"
            size="small"
            type="number"
            value={numberOfVehicles || ''}
            InputProps={{
              inputProps: { min: 0 },
              endAdornment: <InputAdornment position="end"><LocalShippingIcon fontSize="medium" color="primary" /></InputAdornment>,
            }}
            onChange={(e) => updateNumberOfVehicles(e.target.value)}
            onKeyDown={(e) => ['e', 'E', '+', '-'].includes(e.key) && e.preventDefault()}
            onPaste={(e) => {
              e.preventDefault();
              return false;
            }}
          />
          <Button className="right-element" onClick={() => dispatch(setNumberOfVehicles(numberOfVehicles + 1))} variant="outlined" size="large">
            <AddIcon
              fontSize="inherit"
            />
          </Button>
        </div>

        <InnsendingLinje text="Leveringsdato" styling="page-item" />

        <div className="page-item">
          <Button
            onClick={setDeliveryDateToNextWorkingDay}
            variant={deliveryDate.type === 1 ? 'contained' : 'outlined'}
            size="medium"
          >
            Neste arbeidsdag
          </Button>
          <Button
            onClick={() => dispatch(setDeliveryDate({ type: 2, day: userDefinedDeliveryDate }))}
            variant={deliveryDate.type === 2 ? 'contained' : 'outlined'}
            size="medium"
          >
            Velg dato
          </Button>
          <Button
            onClick={() => dispatch(setDeliveryDate({ type: 3, day: new Date() }))}
            variant={deliveryDate.type === 3 ? 'contained' : 'outlined'}
            size="medium"
          >
            I dag
          </Button>
        </div>
        <ChooseDate />

        <InnsendingLinje text="Leveringstidspunkt" styling="page-item" />

        <div className="page-item">
          <Button
            onClick={() => dispatch(setDeliveryTimeType({ type: 1, time: 'I løpet av dagen' }))}
            variant={deliveryTimeType.type === 1 ? 'contained' : 'outlined'}
            size="medium"
          >
            I løpet av dagen
          </Button>
          <Button
            onClick={() => dispatch(setDeliveryTimeType({ type: 5, time: 'Egendefinert' }))}
            variant={deliveryTimeType.type === 5 ? 'contained' : 'outlined'}
            size="medium"
          >
            Egendefinert
          </Button>
        </div>
        {deliveryTimeType.type === 5
        && (
        <ChooseDeliveryTime
          startTime={startTime || null}
          onStartTimeChange={(v) => dispatch(setStartTime(v))}
          endTime={endTime}
          onEndTimeChange={(v) => dispatch(setEndTime(v))}
          isImportantDeadLine={isImportantDeadLine}
          onIsImportantDeadlineChange={(v) => dispatch(setIsImportantDeadline(v))}
          deliveryDate={deliveryDate.day}
        />
        )}

        <div className="bottom-inner">
          <div className="bottom-element">
            <div style={{ width: '64px' }} />

            <CancelButton
              confirm={() => {
                dispatch(resetStates());
                return navigate('/bestilling');
              }}
            />
            <Button
              className="right-element"
              onClick={() => navigate('/internkjoering/detaljer')}
              variant="contained"
              size="large"
              disabled={disableNextPage()}
            >
              {'>'}
            </Button>
          </div>
        </div>
      </Content>
    </PageUnauthenticated>
  );
};
