import { FC } from 'react';
import {
  Card,
  CardActionArea,
  CardContent,
  Divider,
} from '@mui/material';
import Box from '@mui/material/Box';
import { Project } from '../../../core/types/transport/project';

export const ProjectItem: FC<{
  data: Project,
  lastUsed?: string,
  onClick?: () => void,
}> = ({
  data,
  lastUsed = '',
  onClick = () => null,
}) => (
  <Card
    sx={{ mt: 2 }}
  >
    <CardActionArea onClick={onClick}>
      <CardContent>
        <div key={data.id} className="project-item-wrapper">
          <Box
            sx={{
              display: 'flex',
              alignItems: 'center',
            }}
          >
            <span className="project-number">{data.id}</span>
            <Divider orientation="vertical" flexItem />
            <span className="flexme padleft">
              <span className="project-name">{data.projectName}</span>
            </span>
            {lastUsed && (
            <span className="last-used">Sist brukt {lastUsed}</span>
            )}
          </Box>
        </div>
      </CardContent>
    </CardActionArea>

  </Card>
);
