import {
  FC,
  ReactNode,
  useEffect,
  useMemo,
} from 'react';
import { Button, CircularProgress } from '@mui/material';
import { format } from 'date-fns';
import { useNavigate } from 'react-router-dom';
import { useSelector, useDispatch } from '../../../shared/hooks/redux';
import { PageUnauthenticated } from '../../../shared/components/page';
import { Header } from '../../../components/header';
import { InnsendingLinje } from '../../../components/Header4';
import { translateOrderType, translateWhenType } from '../../../core/helpers/translate';
import { resetStates } from '../../../core/redux/containerOrderState';
import { usePostOrderMutation, useGetProjectsQuery, useGetMachinesQuery } from '../../../core/redux/transport';
import { NewOrder } from '../../../core/types/transport/api/newOrder';
import { When } from '../../../core/types/transport/when';
import { CancelButton } from '../../../shared/components/CancelButton';
import { Content } from '../../../shared/components/Content';
import { dateWithoutTimezone } from '../../../shared/logic/dates';
import { getMachineLabel } from '../../../core/helpers/labelling';
import { optionalBool } from '../../../core/helpers/functions';

const Line = ({
  title,
  children,
}: {
  title: string,
   children: ReactNode,
  }) => (
    <>
      <InnsendingLinje text={title} />
      <div>
        {children}
      </div>
    </>
);

/** If active is false, null is returned, otherwise value is returned as-is */
const getOptionalValue = (active: boolean|null, value: string|null) => {
  if (!active) return null;
  return value;
};

export const HaulSubmitPage: FC = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [sendOrder, sendOrderResponse] = usePostOrderMutation();
  const { data: projects } = useGetProjectsQuery();
  const { data: machines } = useGetMachinesQuery();
  const {
    type,
    projectTo,
    projectFrom,
    machine,
    deliveryTimeType,
    startTime,
    endTime,
    deliveryDate,
    isEarlyDelivery,
    isImportantDeadLine,
    timerCounter,
    orderComment,
    contactPerson,
    daytimeDelivery,
    gateCode,
    gateCodeRequired,
    extraEquipment,
    extraEquipmentRequired,
    hasDispensation,
  } = useSelector((s) => s.haulOrderState);

  const machineTitle = useMemo(() => {
    if (!machines) return '';
    const activeMachine = machines.find((m) => m.internalNumber === machine.internal);
    if (!activeMachine) return machine.external ?? machine.internal;
    return getMachineLabel(activeMachine);
  }, [machine, machines]);

  useEffect(() => {
    if (type === null) {
      navigate('/maskinslep');
    }
  }, [type]);

  useEffect(() => {
    if (sendOrderResponse.isSuccess) {
      navigate(`/bestilling/bekreftelse/${sendOrderResponse.data.id}`);
    }
  }, [sendOrderResponse.isSuccess]);

  useEffect(() => {
    if (sendOrderResponse.isError) {
      navigate('./');
    }
  }, [sendOrderResponse.isError]);

  if (projectTo === null) {
    return null;
  }

  const when = useMemo((): When => {
    const timeWhen = translateWhenType(deliveryTimeType.time);
    if (timeWhen === 'Custom') {
      return {
        when: timeWhen,
        from: startTime ? startTime.toISOString() : undefined,
        to: endTime ? endTime.toISOString() : undefined,
      };
    }
    return {
      when: timeWhen,
    };
  }, [deliveryTimeType, startTime, endTime]);

  const toProject = useMemo(() => (
    projects?.find((e) => e.id === projectTo)
  ), [projects, projectTo]);
  const fromProject = useMemo(() => (
    projects?.find((e) => e.id === projectFrom)
  ), [projects, projectFrom]);

  const contactId: number | undefined = contactPerson?.employeeNumber || undefined;
  const contactName: string | undefined = contactPerson?.name || undefined;
  const contactNumber: string | undefined = contactPerson?.number || undefined;

  const postOrder = () => {
    if (!deliveryDate.day) {
      throw new Error('Leveringstid er ikke spesifisert');
    }
    if (!fromProject || !toProject) {
      throw new Error('Feil ved innhenting av data. Prøv igjen');
    }
    if (!type) throw new Error('Type ikke definert');
    if (!machines) return;
    const machineObj = machines.find((m) => m.internalNumber === machine.internal);
    const machineInternalNumber = (machineObj && machineObj.internalNumber) ?? undefined;
    const machineExternal = machineObj ? undefined : machine.external ?? undefined;
    const gateCodeValue = getOptionalValue(
      gateCodeRequired,
      gateCode,
    ) ?? undefined;
    const extraEquipmentValue = getOptionalValue(
      extraEquipmentRequired,
      extraEquipment,
    ) ?? undefined;

    const orderToSend: NewOrder = {
      type,
      fromProject: fromProject.id,
      toProject: toProject.id,
      date: deliveryDate.day && dateWithoutTimezone(deliveryDate.day),
      when,
      loads: [{
        amount: 1,
        machineInternalNumber,
        machineExternal,
      }],
      gateCode: gateCodeValue,
      extraEquipment: extraEquipmentValue,
      hasDispensation: hasDispensation ?? undefined,
      daytimeDelivery,
      earlyDelivery: isEarlyDelivery,
      importantDeadline: isImportantDeadLine,
      extraTimeInFacility: timerCounter || 0,
      comment: orderComment || '',
      contactPersonEmployeeNumber: contactId,
      contactPersonName: contactName,
      contactPersonPhoneNumber: contactNumber,
    };
    sendOrder(orderToSend);
  };

  return (
    <PageUnauthenticated className="innsending-page parent-pos">
      <Header>{type ? translateOrderType(type) : 'Maskinslep'}</Header>
      <Content>
        <InnsendingLinje text="Steg 4 - Innsending" styling="page-item" />
        <div className="extra-inline-padding">
          <InnsendingLinje text="Din bestilling er klar til innsending" />
          <p>
            Les gjennom oppsummeringen under og sjekk at informasjonen stemmer før du sender
          </p>
          <InnsendingLinje text={type ? translateOrderType(type) : 'Maskinslep'} />

          <Line title="Maskin">
            {machineTitle}
          </Line>

          <Line title="Fra prosjekt">
            {`${fromProject?.id} ${fromProject?.projectName}`}
          </Line>

          <Line title="Til prosjekt">
            {`${toProject?.id} ${toProject?.projectName}`}
          </Line>

          <Line title="Leveringsdato">
            {`${deliveryDate.day && format(deliveryDate.day, 'dd.MM.yyyy')}`}
          </Line>

          <Line title="Kan leveres dagen før">
            {isEarlyDelivery ? 'Ja' : 'Nei'}
          </Line>

          <Line title="Leveringstidspunkt">
            {(deliveryTimeType.type < 5) ? (
              <div>
                {deliveryTimeType.time}
              </div>
            ) : (
              <div>
                <div>
                  {`Tidligst: ${startTime && format(startTime, 'HH:mm')} `}
                </div>
                <div>
                  {`Senest: ${endTime && format(endTime, 'HH:mm')} `}
                </div>
              </div>
            )}
            {isImportantDeadLine && (
              <h5>
                Viktig Tidsfrist!
              </h5>
            )}
          </Line>

          <Line title="Kan tas på dagtid">
            {daytimeDelivery ? 'Ja' : 'Nei'}
          </Line>

          <Line title="Kode for port">
            {getOptionalValue(gateCodeRequired, gateCode) ?? 'Nei'}
          </Line>

          <Line title="Ekstra utstyr">
            {getOptionalValue(extraEquipmentRequired, extraEquipment) ?? 'Nei'}
          </Line>

          <Line title="Dispensasjon">
            {optionalBool(hasDispensation)}
          </Line>

          <Line title="Kontaktperson">
            {`${contactPerson?.name} - ${contactPerson?.number}`}
          </Line>

          <div>
            <InnsendingLinje text="Kommentar" />
          </div>
          <div className="display-linebreak">
            {orderComment}
          </div>
        </div>

        {/* Bottom navigation */}
        <div className="bottom-inner">
          <div className="bottom-element">
            <Button
              className="left-element"
              onClick={() => navigate(-1)}
              variant="contained"
              size="large"
            >
              Tilbake
            </Button>
            <CancelButton confirm={() => {
              dispatch(resetStates());
              return navigate('/maskinslep');
            }}
            />
            <Button
              className="right-element"
              disabled={sendOrderResponse.isLoading}
              onClick={postOrder}
              variant="contained"
              size="large"
            >
              Send inn
            </Button>
            {sendOrderResponse.isLoading && (
            <CircularProgress
              size={24}
              sx={{
                position: 'absolute',
                top: '50%',
                left: '50%',
                marginTop: '-12px',
                marginLeft: '-12px',
              }}
            />
            )}
          </div>
        </div>
      </Content>
    </PageUnauthenticated>
  );
};
