import { Configuration } from '../types/configuration';
import { useRoles } from './useRoles';

/**
 * Check if the user has the given app roles in the provided environment.
 *
 * For instance it is possible to check if the user is an admin in transport,
 * or a machine coordinator in machine.
 */
export const useHasAppRoles = (config: Configuration, ...roles: string[]) => {
  const { roles: authRoles, isAuthenticated } = useRoles(config);
  if (authRoles === undefined || !isAuthenticated) return false;
  return roles.some((r) => authRoles.includes(r));
};
