import { FC, useEffect } from 'react';
import Button from '@mui/material/Button';
import { useNavigate } from 'react-router-dom';
import { Box } from '@mui/material';
import { useDispatch } from '../../shared/hooks/redux';
import { PageUnauthenticated } from '../../shared/components/page';
import { Header } from '../../components/header';
import { Content } from '../../shared/components/Content';
import { resetStates, setType } from '../../core/redux/haulOrderState';
import '../../core/styles/orderStyle.scss';

export const HaulOrderPage: FC = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(resetStates());
  }, []);

  const goto = (place: 'semi' | 'hook') => {
    if (place === 'semi') {
      return () => {
        dispatch(setType('HaulSemi'));
        navigate('/maskinslep/semi');
      };
    }
    return () => {
      dispatch(setType('HaulHook'));
      navigate('/maskinslep/krok');
    };
  };

  return (
    <PageUnauthenticated className="haul-bestilling-page">
      <Header>Maskinslep</Header>
      <Content>
        <Box sx={{ height: '5vh' }} />
        <div className="page-item vertical-padding">
          <Button
            onClick={goto('semi')}
            variant="contained"
            size="large"
            fullWidth
          >
            Maskinslep Semi
          </Button>
        </div>
        <div className="page-item vertical-padding">
          <Button
            onClick={goto('hook')}
            variant="contained"
            size="large"
            fullWidth
          >
            Maskinslep krok
          </Button>
        </div>
      </Content>
    </PageUnauthenticated>
  );
};
