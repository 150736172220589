import { AssignmentType } from '../types/transport/enums/assignmentType';

/** Provides a function that checks if the assignmentType is one of the given types
 *
 * Includes the utility "types" Container and Mass
 *
 * Note: This is not actually a hook, so it can be used anywhere
 */
export const useIsType = (type: AssignmentType|null|undefined) => {
  const isType = (...types: (AssignmentType|'Container'|'Mass'|'Haul')[]) => {
    if (!type) return false;
    let t = types.filter((v) => !['Container', 'Mass', 'Haul'].includes(v)) as AssignmentType[];
    if (types.includes('Haul')) {
      t = [
        ...t,
        'HaulHook',
        'HaulSemi',
      ];
    }
    if (types.includes('Container')) {
      t = [
        ...t,
        'ContainerCollect',
        'ContainerDeliver',
        'ContainerEmptying',
        'ContainerSwap',
      ];
    }
    if (types.includes('Mass')) {
      t = [
        ...t,
        'MassIn',
        'MassOut',
        'MassInternal',
      ];
    }
    return t.includes(type);
  };

  return isType;
};
