import React, {
  FC,
  useMemo,
  useState,
} from 'react';
import {
  Checkbox,
  FormControl,
  TextField,
} from '@mui/material';
import { TimePicker } from '@mui/x-date-pickers';
import {
  addDays,
  format,
  getUnixTime,
  isEqual,
  isValid,
  roundToNearestMinutes,
  startOfDay,
} from 'date-fns';
import { onlyQuarters } from '../../core/helpers/functions';

export const ChooseDeliveryTime: FC<{
  startTime?: Date | null,
  onStartTimeChange: (startTime: Date | null) => void,
  endTime?: Date | null,
  onEndTimeChange: (endTime: Date | null) => void,
  isImportantDeadLine: boolean,
  onIsImportantDeadlineChange: (isImportantDeadline: boolean) => void,
  deliveryDate?: Date | null,
}> = ({
  startTime,
  onStartTimeChange,
  endTime,
  onEndTimeChange,
  isImportantDeadLine,
  onIsImportantDeadlineChange,
  deliveryDate,
}) => {
  const [startInput, setStartInput] = useState<Date|null>(startTime || null);
  const [endInput, setEndInput] = useState<Date|null>(endTime || null);

  const handleTimeChange = (e: Date, start: boolean, fromPicker: boolean = false) => {
    if (e === null) {
      return;
    }
    if (start) {
      onStartTimeChange(roundToNearestMinutes(e, { nearestTo: 15 }));
      if (fromPicker) {
        setStartInput(roundToNearestMinutes(e, { nearestTo: 15 }));
      } else {
        setStartInput(e);
      }
    } else {
      onEndTimeChange(roundToNearestMinutes(e, { nearestTo: 15 }));
      if (fromPicker) {
        setEndInput(roundToNearestMinutes(e, { nearestTo: 15 }));
      } else {
        setEndInput(e);
      }
    }
  };
  const endDayAdjusted = useMemo(() => {
    const startUnix = startTime && getUnixTime(startTime) - getUnixTime(startOfDay(startTime));
    const endUnix = endTime && getUnixTime(endTime) - getUnixTime(startOfDay(endTime));
    if (deliveryDate && endUnix === 0) {
      return addDays(deliveryDate, 1);
    }
    if (endUnix && startUnix && deliveryDate && startUnix >= endUnix) {
      return addDays(deliveryDate, 1);
    }
    return deliveryDate;
  }, [endTime, startTime, deliveryDate]);

  return (
    <div>
      <div className="page-item same-line-wrapper">
        <span className="same-line-form vertical-padding">
          <FormControl fullWidth>
            <TimePicker
              value={startInput}
              label="Tidligst"
              ampm={false}
              onAccept={(e) => (e ? handleTimeChange(e, true, true) : null)}
              onChange={(e) => (e && isValid(e) ? handleTimeChange(e, true) : null)}
              shouldDisableTime={onlyQuarters}
              renderInput={(params) => (
                <TextField
                // eslint-disable-next-line react/jsx-props-no-spreading
                  {...params}
                  // eslint-disable-next-line max-len
                  error={(startTime === null || undefined) || (startInput !== null && startTime && !isEqual(startInput, startTime))}
                  helperText={startInput !== null && startTime && !isEqual(startInput, startTime) ? `Tiden vil rundes av til ${format(startTime, 'HH:mm')}` : ''}
                  onBlur={() => setStartInput(startTime || null)}
                />
              )}
            />
          </FormControl>
        </span>
        <div className="same-line-date">
          <span>
            {deliveryDate && startTime
              ? `${format(deliveryDate, 'dd.MM.yyyy')} kl. ${format(startTime, 'HH:mm')}`
              : null}
          </span>
        </div>
      </div>

      <div className="page-item same-line-wrapper">
        <span className="same-line-form small-vertical-padding">
          <FormControl fullWidth>
            <TimePicker
              value={endInput}
              label="Senest"
              ampm={false}
              onAccept={(e) => (e ? handleTimeChange(e, false, true) : null)}
              onChange={(e) => (e && isValid(e) ? handleTimeChange(e, false) : null)}
              shouldDisableTime={onlyQuarters}
              renderInput={(params) => (
                <TextField
                  // eslint-disable-next-line react/jsx-props-no-spreading
                  {...params}
                  // eslint-disable-next-line max-len
                  error={(endTime === null || undefined) || (endInput !== null && endTime && !isEqual(endInput, endTime))}
                  helperText={endInput !== null && endTime && !isEqual(endInput, endTime) ? `Tiden vil rundes av til ${format(endTime, 'HH:mm')}` : ''}
                  onBlur={() => setEndInput(endTime || null)}
                />
              )}
            />
          </FormControl>
        </span>
        <div className="same-line-date">
          <span>
            {endDayAdjusted && endTime
              ? `${format(endDayAdjusted, 'dd.MM.yyyy')} kl. ${format(endTime, 'HH:mm')}`
              : null}
          </span>
        </div>
      </div>
      <div className="vertical-padding">
        <Checkbox
          defaultChecked={isImportantDeadLine}
          onClick={() => onIsImportantDeadlineChange(!isImportantDeadLine)}
        />
        Viktig frist!
      </div>
    </div>
  );
};
