import {
  FC,
  useEffect,
  useMemo,
} from 'react';
import { useNavigate } from 'react-router-dom';
import { Box, Button, TextField } from '@mui/material';
import { Header } from '../../../../components/header';
import { InnsendingLinje } from '../../../../components/Header4';
import { setDeliverContainers, resetStates } from '../../../../core/redux/containerOrderState';
import { useGetContainerTypesQuery, useGetMassTypesQuery } from '../../../../core/redux/transport';
import { SelectContainer } from '../../../../core/types/transport/selectContainer';
import { CancelButton } from '../../../../shared/components/CancelButton';
import { Content } from '../../../../shared/components/Content';
import { SearchSelect } from '../../../../shared/components/searchSelect';
import { SelectItem } from '../../../../shared/types/util/selectItem';
import { useDispatch, useSelector } from '../../../../shared/hooks/redux';
import { PageUnauthenticated } from '../../../../shared/components/page';

export const ContainerDeliverPage: FC = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { deliverContainers, projectTo } = useSelector((s) => s.containerOrderState);
  const { data: rawMass } = useGetMassTypesQuery();
  const { data: rawContainerTypes } = useGetContainerTypesQuery();

  useEffect(() => {
    if (!projectTo) navigate('/container');
  }, []);

  const massList: SelectItem[] = [{ id: 0, label: '(tom)' }];

  if (rawMass) {
    rawMass.filter((m) => m.type === 'Inn').forEach((m) => (massList.push({ id: m.id, label: m.name })));
  }

  const containerList: SelectItem[] = rawContainerTypes
    ? rawContainerTypes.map((c) => (
      { id: c, label: c }
    ))
    : [];

  const setVal = (id: number, val: Partial<SelectContainer>) => {
    dispatch(setDeliverContainers(deliverContainers.map((c) => (c.id === id ? { ...c, ...val } : c))));
  };

  const setAmount = (id: number, value: string) => {
    const amount = value === '' ? 0 : parseInt(value, 10);
    if (Number.isNaN(amount)) return;
    setVal(id, { amount });
  };

  const addContainer = () => {
    const id = deliverContainers[deliverContainers.length - 1].id + 1;
    if (!id) return;
    dispatch(setDeliverContainers(
      [
        ...deliverContainers,
        {
          id, type: undefined, amount: 1, mass: { id: 0, label: '(tom)' },
        },
      ],
    ));
  };

  const removeContainer = (id: number) => {
    dispatch(setDeliverContainers(deliverContainers.filter((f) => f.id !== id)));
  };

  const duplicates = useMemo(() => (
    deliverContainers.filter((c) => (
      deliverContainers.some((o) => o.id !== c.id && o.type?.label === c.type?.label && o.mass?.label === c.mass?.label)))
      .map((c) => c.id)
  ), [deliverContainers]);

  const disableNextPage = duplicates.length > 0 || deliverContainers.some((c) => !c.type || !c.amount || !c.mass);

  return (
    <PageUnauthenticated className="container-order-page">
      <Header>Containerbestilling</Header>
      <Content>
        <InnsendingLinje text="Steg 1 - Container utkjøring" styling="page-item" />
        <Box className="orders-wrapper">
          {deliverContainers.map((c) => (
            <Box key={c.id} className="order-wrapper">
              <div className="item">
                <InnsendingLinje styling="field-title" text="Containertype" />
                <SearchSelect
                  label=""
                  errorLabel={duplicates?.includes(c.id) ? 'Duplikat' : 'Velg en container'}
                  required
                  forceError={duplicates?.includes(c.id)}
                  value={c.type}
                  onChange={(p) => setVal(c.id, { type: p })}
                >
                  {containerList}
                </SearchSelect>
              </div>
              <div className="item">
                <InnsendingLinje styling="field-title" text="Antall" />
                <TextField
                  type="number"
                  fullWidth
                  value={c.amount === 0 ? '' : c.amount}
                  onChange={(e) => setAmount(c.id, e.currentTarget.value)}
                  onBlur={() => c.amount < 1 && setAmount(c.id, '1')}
                />
              </div>
              <div className="item">
                <InnsendingLinje styling="field-title" text="Masse" />
                <SearchSelect
                  label=""
                  errorLabel={duplicates.includes(c.id) ? 'Duplikat' : 'Velg masse'}
                  required
                  forceError={duplicates?.includes(c.id)}
                  value={c.mass}
                  onChange={(p) => setVal(c.id, { mass: p })}
                >
                  {massList}
                </SearchSelect>
              </div>
              {deliverContainers.length > 1 && (
                <div>
                  <Button className="delete-btn" size="large" color="error" variant="contained" onClick={() => removeContainer(c.id)}>Slett</Button>
                </div>
              )}
            </Box>
          ))}
        </Box>
        <Button className="add-btn" sx={{ marginTop: '40px' }} variant="contained" onClick={addContainer}>Legg til flere</Button>
        <div className="bottom-inner">
          <div className="bottom-element">
            <div style={{ width: '64px' }} />
            <CancelButton confirm={() => {
              dispatch(resetStates());
              return navigate('/container');
            }}
            />
            <Button
              className="right-element"
              onClick={() => navigate('/container/ut/leveringsdetaljer')}
              variant="contained"
              size="large"
              disabled={disableNextPage}
            >
              {'>'}
            </Button>
          </div>
        </div>
      </Content>
    </PageUnauthenticated>
  );
};
