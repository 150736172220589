import { FC, useEffect, useMemo } from 'react';
import Button from '@mui/material/Button';
import {
  Box,
  TextareaAutosize,
  TextField,
} from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { useSelector, useDispatch } from '../../../shared/hooks/redux';
import { PageUnauthenticated } from '../../../shared/components/page';
import { ChooseContactPerson } from '../../../components/ContactPerson';
import { Header } from '../../../components/header';
import { InnsendingLinje } from '../../../components/Header4';
import {
  setContactPerson,
  setOrderComment,
  resetStates,
  setGateCodeRequired,
  setGateCode,
  setExtraEquipmentRequired,
  setExtraEquipment,
  setHasDispensation,
} from '../../../core/redux/haulOrderState';
import { CancelButton } from '../../../shared/components/CancelButton';
import { Content } from '../../../shared/components/Content';
import { RadioYesNo } from '../../../components/RadioYesNo';
import { translateOrderType } from '../../../core/helpers/translate';

export const HaulAdditionalDetailsPage: FC = () => {
  const navigate = useNavigate();
  const {
    type,
    orderComment,
    projectFrom,
    projectTo,
    contactPerson,
    gateCode,
    gateCodeRequired,
    extraEquipment,
    extraEquipmentRequired,
    hasDispensation: haveDispensation,
  } = useSelector((s) => s.haulOrderState);
  const dispatch = useDispatch();

  useEffect(() => {
    if (type !== null) return;
    if (projectFrom !== null) return;
    if (projectTo !== null) return;
    navigate('/maskinslep');
  }, [type, projectFrom, projectTo]);

  if (projectTo === null) {
    return null;
  }

  const disableNextPage = useMemo(() => {
    if (gateCodeRequired && gateCode === '') return true;
    if (gateCodeRequired && gateCode === null) return true;
    if (extraEquipmentRequired && extraEquipment === '') return true;
    if (contactPerson === null) return true;
    if (contactPerson.name === '') return true;
    if (contactPerson.number === '') return true;
    return false;
  }, [
    gateCode,
    gateCodeRequired,
    extraEquipment,
    extraEquipmentRequired,
    contactPerson,
    haveDispensation,
  ]);

  return (
    <PageUnauthenticated className="tilleggsdetaljer-page parent-pos">
      <Header>{type ? translateOrderType(type) : 'Maskinslep'}</Header>
      <Content>
        <InnsendingLinje text="Steg 3 - Tilleggsdetaljer" styling="page-item" />
        <InnsendingLinje text="Kode for port" styling="page-item" />
        <Box sx={{ display: 'flex', gap: 1, flexDirection: 'column' }} className="page-item">
          <RadioYesNo
            required
            value={gateCodeRequired}
            onChange={(v) => dispatch(setGateCodeRequired(v))}
          />
          {gateCodeRequired && (
            <TextField
              required
              fullWidth
              value={gateCode || ''}
              onChange={(e) => dispatch(setGateCode(e.target.value))}
              label="Adgangskode"
              error={gateCode === null || gateCode === ''}
            />
          )}
        </Box>
        <InnsendingLinje text="Ekstra utstyr" styling="page-item" />
        <Box sx={{ display: 'flex', gap: 1, flexDirection: 'column' }} className="page-item">
          <RadioYesNo
            value={extraEquipmentRequired}
            onChange={(v) => dispatch(setExtraEquipmentRequired(v))}
          />
          {extraEquipmentRequired && (
            <TextField
              required
              multiline
              fullWidth
              value={extraEquipment}
              onChange={(e) => dispatch(setExtraEquipment(e.target.value))}
              InputProps={{ minRows: 2 }}
              label="Ekstra utstyr"
              error={extraEquipment === ''}
            />
          )}
        </Box>
        <InnsendingLinje text="Dispensasjon" styling="page-item" />
        <div className="page-item">
          <RadioYesNo
            value={haveDispensation}
            onChange={(v) => dispatch(setHasDispensation(v))}
          />
        </div>
        <InnsendingLinje text="Kontaktperson" styling="page-item remove-bottom-padding" />
        <div className="page-item">
          <ChooseContactPerson
            onChange={(value) => dispatch(setContactPerson(value))}
            value={contactPerson}
          />
        </div>
        <InnsendingLinje text="Er det noe mer vi bør vite? (valgfritt)" styling="page-item remove-bottom-padding" />
        <p className="page-item">
          Spesielle forhold eller hensyn på anlegget, hvem skal motta leveransen
          eller annen nyttig informasjon som vil hjelp oss å planlegge bedre.
        </p>
        <div className="text-item flex-parent">
          <TextareaAutosize
            minRows={20}
            placeholder="Legg til kommentar"
            value={orderComment}
            className="fill-screen"
            onChange={(e) => dispatch(setOrderComment(e.target.value))}
          />
        </div>

        <div className="bottom-inner">
          <div className="bottom-element">
            <Button
              className="left-element"
              onClick={() => navigate(-1)}
              variant="contained"
              size="large"
            >
              {'<'}
            </Button>
            <CancelButton confirm={() => {
              dispatch(resetStates());
              return navigate('/container');
            }}
            />
            <Button
              className="right-element"
              disabled={disableNextPage}
              onClick={() => navigate('./../innsending')}
              variant="contained"
              size="large"
            >
              {'>'}
            </Button>
          </div>
        </div>
      </Content>
    </PageUnauthenticated>
  );
};
