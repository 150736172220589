import {
  Box,
  Card,
  CardActionArea,
  CardContent,
  Chip,
  Tooltip,
  Typography,
} from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { ReactNode } from 'react';
import { OrderType as MassOrderType } from '../../../core/types/transport/enums/orderType';
import { translateOrderType } from '../../../core/helpers/translate';
import { OrderStatusIcon } from '../../../components/OrderStatusIcon';

export type OrderItemType = {
  id: number|string,
  title: string,
  status: string,
  /** Displayed below the header - mostly used for loads */
  info: string|ReactNode,
  assignedUser?: { name: string, phone: string },
  icons: ReactNode,
  type: MassOrderType | 'Rental',
  origin: 'transport' | 'rental',
  importantDeadline?: boolean,
  /** Path to navigate to when clicked */
  path: string,
}

export const OrderItem = ({
  order,
}: {
  order: OrderItemType,
}) => {
  const navigate = useNavigate();

  return (
    <Card>
      <CardActionArea onClick={() => {
        navigate(order.path);
      }}
      >
        <CardContent>
          <Box className="line-wrapper">
            <Tooltip title="Viktig tidsfrist!" placement="right-start" disableHoverListener={!order.importantDeadline}>
              <Box className="header left">
                {order.title}
                {
                order.importantDeadline
                  ? <span className="important-deadline">!</span>
                  : null
              }
              </Box>
            </Tooltip>
            <Box className="right transform-status">
              <OrderStatusIcon
                currStatus={order.type === 'Diesel' && order.status === 'Planned' ? 'Completed' : order.status}
              />
            </Box>
          </Box>

          <Box className="flex-load-contact" sx={{ overflow: 'hidden', gap: 1 }}>
            <Box className="load" display="flex" flexDirection="column" gap={0.5} overflow="hidden">
              {order.info}
            </Box>
            <Typography sx={{ textAlign: 'end', maxWidth: '300px' }}>
              {order.assignedUser && `${order.assignedUser.name} - (${order.assignedUser.phone})`}
            </Typography>
          </Box>

          <Box className="line-wrapper">
            <Box className="left font-size-16">
              {order.icons}
            </Box>
            <Box className="right transform-type">
              <Chip color={order.origin === 'transport' ? 'info' : 'success'} label={translateOrderType(order.type)} size="medium" />
            </Box>
          </Box>
        </CardContent>
      </CardActionArea>
    </Card>
  );
};
