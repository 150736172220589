import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { resetStates } from '../../core/redux/dieselOrderState';
import { useDispatch } from '../../shared/hooks/redux';

/** Resets the diesel state and navigates to the first step */
export const DieselIndexPage = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(resetStates());
    navigate('./detaljer');
  }, []);

  return null;
};
