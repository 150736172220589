import { OrderStatus } from '../types/transport/enums/orderStatus';
import { OrderType } from '../types/transport/enums/orderType';
import { WhenType } from '../types/transport/enums/whenType';
import { EquipmentOrderStatus } from '../../shared/types/enums/equipmentOrderStatus';
import { Order } from '../types/transport/order';
import { VehicleType } from '../types/transport/enums/vehicleType';
import { AssignmentType } from '../types/transport/enums/assignmentType';
import { AssignmentStatus } from '../types/transport/enums/assignmentStatus';

export const translateErrorCode = (endPoint: string, errorText: string, errorCode = 500) => {
  if (errorText.toLocaleLowerCase().includes('not found')) {
    return 'Ikke funnet';
  }
  if (errorText.includes('authentication')) {
    return 'Et autoriseringsproblem har oppstått';
  }
  if (errorCode === 403) {
    return 'En autoriseringsfeil har oppstått';
  }
  return 'En feil har oppstått';
};

export function translateWhenType(when: string): WhenType {
  switch (when) {
    case 'I løpet av dagen':
      return 'DuringTheDay';
    case 'Morgen':
      return 'Morning';
    case 'Før mat':
      return 'BeforeFood';
    case 'Etter mat':
      return 'AfterFood';
    case 'Egendefinert':
      return 'Custom';
    default:
      return 'DuringTheDay';
  }
}

export function translateVehicleNumberType(vehicleNumber: number): VehicleType {
  switch (vehicleNumber) {
    case 1:
      return 'Single';
    case 2:
      return 'Double';
    case 4:
      return 'Semi';
    case 5:
      return 'CarWithTrailer';
    case 6:
      return 'SixxSix';
    case 7:
      return 'HookLift';
    default:
      return 'Single';
  }
}
// to N(orsk)B(okmål)
export function translateNBVehicleType(vehicleNumber: number): string {
  switch (vehicleNumber) {
    case 1:
      return 'Enkel';
    case 2:
      return 'Dobbel';
    case 4:
      return 'Semi';
    case 5:
      return 'Bil med henger';
    case 6:
      return '6x6';
    case 7:
      return 'Kroklift';
    default:
      return 'Enkel';
  }
}

export const translateOrderType = (orderType: OrderType|'Rental') => {
  switch (orderType) {
    case 'ContainerCollect': return 'Container Inn';
    case 'ContainerDeliver': return 'Container Ut';
    case 'ContainerEmptying': return 'Container Tøm';
    case 'MassIn': return 'Masse Inn';
    case 'MassOut': return 'Masse Ut';
    case 'MassInternal': return 'Internkjøring';
    case 'Rental': return 'Internutleie';
    case 'HaulHook': return 'Maskinslep Krok';
    case 'HaulSemi': return 'Maskinslep Semi';
    default: return orderType;
  }
};

export const translateOrderStatus = (currStatus: OrderStatus | EquipmentOrderStatus | undefined) => {
  switch (currStatus) {
    case 'Created':
      return 'Opprettet';
    case 'UnderPlanning':
      return 'Under planlegging';
    case 'Planned':
      return 'Planlagt';
    case 'Completed':
      return 'Fullført';
    case 'Cancelled':
      return 'Kansellert';
    case 'Undelivered':
      return 'Ulevert';
    case 'Declined':
      return 'Avslått';
    case 'Approved': return 'Godkjent';
    case 'NotApproved': return 'Ikke Godkjent';
    default:
      return currStatus;
  }
};

export const vehicleType = (vehicle: VehicleType) => {
  switch (vehicle) {
    case 'Single':
      return 'Enkel';
    case 'Double':
      return 'Dobbel';
    case 'Semi':
      return 'Semi';
    case 'CarWithTrailer':
      return 'Bil med henger';
    case 'SixxSix':
      return '6x6';
    case 'HookLift':
      return 'Kroklift';
    default:
      return vehicle;
  }
};

export const translateVehicleType = (order: Order) => (
  order.vehicle && vehicleType(order.vehicle)
);

export const getTransportType = (id: OrderType|AssignmentType|undefined) => {
  switch (id) {
    case 'MassIn':
      return 'Masse Inn';
    case 'MassOut':
      return 'Masse Ut';
    case 'MassInternal':
      return 'Internkjøring';
    case 'ContainerDeliver':
      return 'Container Ut';
    case 'ContainerCollect':
      return 'Container Hent';
    case 'ContainerEmptying':
      return 'Container Tøm';
    case 'ContainerSwap':
      return 'Container Bytt';
    case 'HaulHook':
      return 'Maskinslep Krok';
    case 'HaulSemi':
      return 'Maskinslep Semi';
    case 'Diesel':
      return 'Diesel';
    default:
      return 'Ukjent';
  }
};

export const getAssignmentStatusType = (currStatus: AssignmentStatus | undefined) => {
  switch (currStatus) {
    case 'UnderPlanning':
      return 'Under planlegging';
    case 'Approved':
      return 'Godkjent';
    case 'Started':
      return 'Pågående';
    case 'Completed':
      return 'Fullført';
    case 'Cancelled':
      return 'Kansellert';
    case 'NotDelivered':
      return 'Ikke levert';
    case 'VerifiedNotDelivered':
      return 'Ikke levert (bekreftet)';
    case 'Deleted':
      return 'Slettet';
    case 'Unavailable':
      return 'Utilgjengelig';
    default:
      return currStatus;
  }
};
