import {
  BrowserRouter as ReactRouter,
  Route,
  Routes,
} from 'react-router-dom';
import { MsalAuthenticationTemplate } from '@azure/msal-react';
import { InteractionType } from '@azure/msal-browser';
import { AccessDeniedPage } from './pages/accessDenied';
import { Unauthenticated } from './shared/components/unauthenticated';
import { NotFound } from './shared/components/NotFound';
import './router.scss';
import { LandingPage } from './pages/landingPage';
import { RentalPage } from './pages/rentalPage';
import { OrdersPage } from './pages/ordersPage';
import { BekreftelsePage } from './pages/BekreftelsePage';
import { ContainerOrderPage } from './pages/containerOrderPages';
import { ContainerCollectAdditionalDetailsPage } from './pages/containerOrderPages/ContainerCollect/CollectAdditionalDetailsPage';
import { ContainerCollectDetailsPage } from './pages/containerOrderPages/ContainerCollect/CollectDetailsPage';
import { ContainerCollectPage } from './pages/containerOrderPages/ContainerCollect/CollectPage';
import { ContainerCollectSubmitPage } from './pages/containerOrderPages/ContainerCollect/CollectSubmitPage';
import { ContainerDeliverAdditionalDetailsPage } from './pages/containerOrderPages/ContainerDeliver/DeliverAdditionalDetailsPage';
import { ContainerDeliverDetailsPage } from './pages/containerOrderPages/ContainerDeliver/DeliverDetailsPage';
import { ContainerDeliverPage } from './pages/containerOrderPages/ContainerDeliver/DeliverPage';
import { ContainerDeliverSubmitPage } from './pages/containerOrderPages/ContainerDeliver/DeliverSubmitPage';
import { ContainerEmptyAdditionalDetailsPage } from './pages/containerOrderPages/ContainerEmpty/EmptyAdditionalDetailsPage';
import { ContainerEmptyDetailsPage } from './pages/containerOrderPages/ContainerEmpty/EmptyDetailsPage';
import { ContainerEmptyPage } from './pages/containerOrderPages/ContainerEmpty/EmptyPage';
import { ContainerEmptySubmitPage } from './pages/containerOrderPages/ContainerEmpty/EmptySubmitPage';
import { InternDetaljerPage } from './pages/massOrderPages/Internal/DetaljerPage';
import { InternInnsendingPage } from './pages/massOrderPages/Internal/InnsendingPage';
import { InternkjoeringPage } from './pages/massOrderPages/Internal/InternKjoringPage';
import { InnInnsendingPage } from './pages/massOrderPages/MassIn/InnsendingPage';
import { InnLeveringsdetaljerPage } from './pages/massOrderPages/MassIn/LeveringsdetaljerPage';
import { MasseInnPage } from './pages/massOrderPages/MassIn/MasseInnPage';
import { InnTilleggsdetaljerPage } from './pages/massOrderPages/MassIn/TilleggsdetaljerPage';
import { UtInnsendingPage } from './pages/massOrderPages/MassOut/InnsendingPage';
import { UtLeveringsdetaljerPage } from './pages/massOrderPages/MassOut/LeveringsdetaljerPage';
import { MasseUtPage } from './pages/massOrderPages/MassOut/MasseUtPage';
import { UtTilleggsdetaljerPage } from './pages/massOrderPages/MassOut/TilleggsdetaljerPage';
import { MassOrderPage } from './pages/massOrderPages';
import { OrderDetailsTransportPage } from './pages/orderDetailsTransportPage';
import { OrderDetailsRentalPage } from './pages/orderDetailsRentalPage';
import { ProjectsPage } from './pages/ProjectsPage';
import { HaulOrderPage } from './pages/haulOrderPages';
import { HaulPage } from './pages/haulOrderPages/haulPage';
import { HaulAdditionalDetailsPage } from './pages/haulOrderPages/haulAdditionalDetailsPage';
import { HaulDetailsPage } from './pages/haulOrderPages/haulDetailsPage';
import { HaulSubmitPage } from './pages/haulOrderPages/haulSubmitPage';
import { OrderRedirectPage } from './pages/orderRedirectPage';
import { DieselOrderPage } from './pages/dieselOrderPages/dieselOrderPage';
import { DieselDeliveryDetailsPage } from './pages/dieselOrderPages/dieselDeliveryDetails';
import { DieselAdditionalInfoPage } from './pages/dieselOrderPages/dieselAdditionalInfo';
import { DieselSubmitPage } from './pages/dieselOrderPages/dieselSubmitPage';
import { DieselIndexPage } from './pages/dieselOrderPages';

export const Router = () => (
  <MsalAuthenticationTemplate
    authenticationRequest={{ scopes: [] }}
    interactionType={InteractionType.Redirect}
    errorComponent={Unauthenticated}
  >
    <div className="router-wrapper">
      <ReactRouter>
        <Routes>
          <Route path="/ingentilgang" element={<AccessDeniedPage />} />
          <Route path="/internutleie" element={<RentalPage />} />
          <Route path="/bestillinger" element={<OrdersPage />} />
          <Route path="/prosjekt/:id" element={<OrdersPage />} />
          <Route path="/prosjekter" element={<ProjectsPage />} />
          <Route path="/bestilling" element={<MassOrderPage />} />
          <Route path="/bestilling/bekreftelse/:id" element={<BekreftelsePage />} />
          <Route path="/masseut/leveringsdetaljer" element={<UtLeveringsdetaljerPage />} />
          <Route path="/masseut/tilleggsdetaljer" element={<UtTilleggsdetaljerPage />} />
          <Route path="/masseut/innsending" element={<UtInnsendingPage />} />
          <Route path="/masseUt" element={<MasseUtPage />} />
          <Route path="/masseinn/leveringsdetaljer" element={<InnLeveringsdetaljerPage />} />
          <Route path="/masseinn/tilleggsdetaljer" element={<InnTilleggsdetaljerPage />} />
          <Route path="/masseinn/innsending" element={<InnInnsendingPage />} />
          <Route path="/masseinn" element={<MasseInnPage />} />
          <Route path="/internkjoering/detaljer" element={<InternDetaljerPage />} />
          <Route path="/internkjoering/innsending" element={<InternInnsendingPage />} />
          <Route path="/internkjoering" element={<InternkjoeringPage />} />

          <Route path="/container" element={<ContainerOrderPage />} />
          <Route path="/container/tom/innsending" element={<ContainerEmptySubmitPage />} />
          <Route path="/container/tom/tilleggsdetaljer" element={<ContainerEmptyAdditionalDetailsPage />} />
          <Route path="/container/tom/leveringsdetaljer" element={<ContainerEmptyDetailsPage />} />
          <Route path="/container/tom/" element={<ContainerEmptyPage />} />
          <Route path="/container/inn/innsending" element={<ContainerCollectSubmitPage />} />
          <Route path="/container/inn/tilleggsdetaljer" element={<ContainerCollectAdditionalDetailsPage />} />
          <Route path="/container/inn/leveringsdetaljer" element={<ContainerCollectDetailsPage />} />
          <Route path="/container/inn/" element={<ContainerCollectPage />} />
          <Route path="/container/ut/innsending" element={<ContainerDeliverSubmitPage />} />
          <Route path="/container/ut/tilleggsdetaljer" element={<ContainerDeliverAdditionalDetailsPage />} />
          <Route path="/container/ut/leveringsdetaljer" element={<ContainerDeliverDetailsPage />} />
          <Route path="/container/ut/" element={<ContainerDeliverPage />} />

          <Route path="/maskinslep" element={<HaulOrderPage />} />
          <Route path="/maskinslep/semi/" element={<HaulPage />} />
          <Route path="/maskinslep/semi/leveringsdetaljer" element={<HaulDetailsPage />} />
          <Route path="/maskinslep/semi/tilleggsdetaljer" element={<HaulAdditionalDetailsPage />} />
          <Route path="/maskinslep/semi/innsending" element={<HaulSubmitPage />} />
          <Route path="/maskinslep/krok/" element={<HaulPage />} />
          <Route path="/maskinslep/krok/leveringsdetaljer" element={<HaulDetailsPage />} />
          <Route path="/maskinslep/krok/tilleggsdetaljer" element={<HaulAdditionalDetailsPage />} />
          <Route path="/maskinslep/krok/innsending" element={<HaulSubmitPage />} />

          <Route path="/diesel" element={<DieselIndexPage />} />
          <Route path="/diesel/detaljer" element={<DieselOrderPage />} />
          <Route path="/diesel/leveringsdetaljer" element={<DieselDeliveryDetailsPage />} />
          <Route path="/diesel/tilleggsdetaljer" element={<DieselAdditionalInfoPage />} />
          <Route path="/diesel/innsending" element={<DieselSubmitPage />} />

          <Route path="/bestilling/transport/:id" element={<OrderDetailsTransportPage />} />
          <Route path="/bestilling/internutleie/:id" element={<OrderDetailsRentalPage />} />

          <Route path="/b/:id" element={<OrderRedirectPage />} />

          <Route path="/" element={<LandingPage />} />
          <Route path="*" element={<NotFound />} />
        </Routes>
      </ReactRouter>
    </div>
  </MsalAuthenticationTemplate>
);
