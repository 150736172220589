import { ReactNode } from 'react';
import './style.scss';
import { Box, SxProps } from '@mui/material';

export const Content = ({
  children,
  sx = {},
  innerSx = {},
}: {children: ReactNode,
  sx?: SxProps,
  innerSx?: SxProps
}) => (
  <Box className="content-wrapper" sx={sx}>
    <Box className="content-inner" sx={innerSx}>
      {children}
    </Box>
  </Box>
);
