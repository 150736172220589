import { FC, useEffect } from 'react';
import Button from '@mui/material/Button';
import { useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from '../../shared/hooks/redux';
import { PageUnauthenticated } from '../../shared/components/page';
import { Header } from '../../components/header';
import { Content } from '../../shared/components/Content';
import { InnsendingLinje } from '../../components/Header4';
import { resetStates, setProjectTo } from '../../core/redux/containerOrderState';
import { ProjectPicker } from '../../components/ProjectPicker';
import '../../core/styles/orderStyle.scss';

export const ContainerOrderPage: FC = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const selectedProject = useSelector((s) => s.containerOrderState.projectTo);

  const disableNextPage = selectedProject === null;

  useEffect(() => {
    dispatch(resetStates());
  }, []);

  return (
    <PageUnauthenticated className="container-bestilling-page">
      <Header>Containerbestilling</Header>
      <Content>
        <InnsendingLinje text="Prosjekt" styling="page-item vertical-padding" />
        <div className="page-item vertical-padding mb20">
          <ProjectPicker
            value={selectedProject}
            onChange={(newValue) => {
              dispatch(setProjectTo(newValue?.id || null));
            }}
          />
        </div>
        <div className="page-item vertical-padding">
          <Button
            onClick={() => navigate('/container/ut')}
            disabled={disableNextPage}
            variant="contained"
            size="large"
            fullWidth
          >
            Utkjøring
          </Button>
        </div>
        <div className="page-item vertical-padding">
          <Button
            onClick={() => navigate('/container/tom')}
            disabled={disableNextPage}
            variant="contained"
            size="large"
            fullWidth
          >
            Tømming/bytte
          </Button>
        </div>
        <div className="page-item vertical-padding">
          <Button
            onClick={() => navigate('/container/inn')}
            disabled={disableNextPage}
            variant="contained"
            size="large"
            fullWidth
          >
            Henting (Avslutning)
          </Button>
        </div>
      </Content>
    </PageUnauthenticated>
  );
};
