import { useNextWorkingDayWithRequirement } from '../core/hooks/calendar';
import { setDeliveryDate as setDeliveryDateContainer } from '../core/redux/containerOrderState';
import { setValue } from '../core/redux/dieselOrderState';
import { setDeliveryDate as setDeliveryDateHaul } from '../core/redux/haulOrderState';
import { setDeliveryDate } from '../core/redux/massOrderState';
import { useDispatch } from '../shared/hooks/redux';

export const useSetDeliveryDateToNextWorkingDay = (
  state: 'transport'|'container'|'haul'|'diesel' = 'transport',
  reqFn: (d: Date) => boolean = () => true,
) => {
  const nextWorkingDay = useNextWorkingDayWithRequirement(reqFn);
  const dispatch = useDispatch();

  const setDeliveryDateToNextWorkingDay = () => {
    const deliveryDate = {
      type: 1,
      day: nextWorkingDay,
    };
    switch (state) {
      case 'transport': return dispatch(setDeliveryDate(deliveryDate));
      case 'container': return dispatch(setDeliveryDateContainer(deliveryDate));
      case 'haul': return dispatch(setDeliveryDateHaul(deliveryDate));
      case 'diesel': return dispatch(setValue({ deliveryDate }));
      default: return null;
    }
  };

  return setDeliveryDateToNextWorkingDay;
};
