import { Box, Typography } from '@mui/material';
import { Page } from '../../shared/components/page';
import { Header } from '../../components/header';

export const AccessDeniedPage = () => (
  <Page className="access-denied-page">
    <Header><Typography sx={{ fontSize: 18, fontWeight: 'bold' }}>Ingen tilgang</Typography></Header>
    <Box sx={{ margin: 5 }}>
      <Typography variant="h3">Du har ikke tilgang til denne siden!</Typography>
      <Typography variant="subtitle1">Ta kontakt med IT-ansvarlig hvis du har behov for tilgang til denne siden.</Typography>
    </Box>
  </Page>
);
