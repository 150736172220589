import { FC, useEffect, useMemo } from 'react';
import Button from '@mui/material/Button';
import { Box, TextareaAutosize, TextField } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { useSelector, useDispatch } from '../../shared/hooks/redux';
import { PageUnauthenticated } from '../../shared/components/page';
import { ChooseContactPerson } from '../../components/ContactPerson';
import { Header } from '../../components/header';
import { InnsendingLinje } from '../../components/Header4';
import { CancelButton } from '../../shared/components/CancelButton';
import { Content } from '../../shared/components/Content';
import { RadioYesNo } from '../../components/RadioYesNo';
import { hasMissing } from '../../core/helpers/hasMissing';
import { resetStates, setValue } from '../../core/redux/dieselOrderState';

export const DieselAdditionalInfoPage: FC = () => {
  const navigate = useNavigate();
  const {
    projectTo,
    dieselType,
    dieselAmount,
    dieselContainerAmount,
    deliveryDate,

    orderComment,
    contactPerson,
    gateCode,
    gateCodeRequired,
  } = useSelector((s) => s.dieselOrderState);
  const dispatch = useDispatch();

  const hasMissingValue = hasMissing(
    projectTo,
    dieselType,
    dieselAmount,
    dieselContainerAmount,
    deliveryDate,
  );

  useEffect(() => {
    if (hasMissingValue) navigate('/diesel');
  }, [hasMissingValue]);

  if (projectTo === null) {
    return null;
  }

  const disableNextPage = useMemo(() => {
    if (gateCodeRequired && gateCode === '') return true;
    if (gateCodeRequired && gateCode === null) return true;
    if (contactPerson === null) return true;
    if (contactPerson.name === '') return true;
    if (contactPerson.number === '') return true;
    return false;
  }, [
    gateCode,
    gateCodeRequired,
    contactPerson,
  ]);

  return (
    <PageUnauthenticated className="tilleggsdetaljer-page parent-pos">
      <Header>Dieselbestilling</Header>
      <Content>
        <InnsendingLinje text="Steg 3 - Tilleggsdetaljer" styling="page-item" />
        <InnsendingLinje text="Kode for port" styling="page-item" />
        <Box sx={{ display: 'flex', gap: 1, flexDirection: 'column' }} className="page-item">
          <RadioYesNo
            required
            value={gateCodeRequired}
            onChange={(v) => dispatch(setValue({ gateCodeRequired: v }))}
          />
          {gateCodeRequired && (
            <TextField
              required
              fullWidth
              value={gateCode || ''}
              onChange={(e) => dispatch(setValue({ gateCode: e.target.value }))}
              label="Adgangskode"
              error={gateCode === null || gateCode === ''}
            />
          )}
        </Box>
        <InnsendingLinje text="Kontaktperson" styling="page-item remove-bottom-padding" />
        <div className="page-item">
          <ChooseContactPerson
            onChange={(value) => dispatch(setValue({ contactPerson: value }))}
            value={contactPerson}
          />
        </div>
        <InnsendingLinje text="Er det noe mer vi bør vite? (valgfritt)" styling="page-item remove-bottom-padding" />
        <p className="page-item">
          Spesielle forhold eller hensyn på anlegget, hvem skal motta leveransen
          eller annen nyttig informasjon som vil hjelp oss å planlegge bedre.
        </p>
        <div className="text-item flex-parent">
          <TextareaAutosize
            minRows={20}
            placeholder="Legg til kommentar"
            value={orderComment}
            className="fill-screen"
            onChange={(e) => dispatch(setValue({ orderComment: e.target.value }))}
          />
        </div>

        <div className="bottom-inner">
          <div className="bottom-element">
            <Button
              className="left-element"
              onClick={() => navigate('./../leveringsdetaljer')}
              variant="contained"
              size="large"
            >
              {'<'}
            </Button>
            <CancelButton confirm={() => {
              dispatch(resetStates());
              return navigate('/diesel');
            }}
            />
            <Button
              className="right-element"
              disabled={disableNextPage}
              onClick={() => navigate('./../innsending')}
              variant="contained"
              size="large"
            >
              {'>'}
            </Button>
          </div>
        </div>
      </Content>
    </PageUnauthenticated>
  );
};
