import {
  FC,
  useEffect,
  useMemo,
  useState,
} from 'react';
import {
  Box,
  Button,
  CircularProgress,
  TextField,
  Typography,
} from '@mui/material';
import {
  addHours,
  endOfDay,
  format,
  formatISO,
  isBefore,
  startOfDay,
  subHours,
} from 'date-fns';
import { toast } from 'react-toastify';
import './style.scss';
import { TaskEvent } from '../../types/util/taskEvent';
import { NOT_DELIVERED_COLOR } from '../../constants';
import { ensureAfter } from '../../logic/dates';
import { labelSort } from '../../logic/functions';
import {
  useGetCategoriesQuery,
  usePutMoveEquipmentOrderMutation,
  usePostEquipmentOrderMutation,
  useDeleteOrderMutation,
  useGetEquipmentAssignmentsForQuery,
} from '../../redux/rental';
import { MoveEquipmentOrder } from '../../types/api/moveEquipmentOrder';
import { NewEquipmentOrder } from '../../types/api/newEquipmentOrder';
import { EquipmentAssignment } from '../../types/equipmentAssignment';
import { Displayable } from '../../types/util/displayable';
import { SelectItem } from '../../types/util/selectItem';
import { DataField } from '../dataField';
import { PopConfirm } from '../popConfirm';
import { SearchSelect } from '../searchSelect';
import { WorkerPicker } from '../workerPicker';
import { DateTimePicker } from '../dateTimePicker';
import { useGetLoggedInRentalUserObject } from '../../hooks/getLoggedInRentalUserObject';
import { selectFromResult } from '../../logic/defaultArray';
import { useOverlapMessage } from '../../hooks/useOverlapMessage';

export const EditEquipmentOrder: FC<{
  event?: TaskEvent;
  assignment?: EquipmentAssignment;
  projects: Displayable[];
  isEdit?: boolean;
  refetch: () => void;
  onClose?: () => void;
}> = ({
  event,
  assignment,
  projects,
  isEdit = false,
  refetch = () => null,
  onClose = () => null,
}) => {
  const { data: equipmentDummies } = useGetCategoriesQuery({
    includeSubCategories: true,
  }, { selectFromResult });
  const [updateOrder] = usePutMoveEquipmentOrderMutation();
  const [post] = usePostEquipmentOrderMutation();
  const [deleteOrder] = useDeleteOrderMutation();
  const [spinner, setSpinner] = useState<boolean>(false);
  const [from, setFrom] = useState<Date>(new Date());
  const [to, setTo] = useState<Date>(new Date());
  const [project, setProject] = useState<SelectItem | undefined>();
  const [selectedEquipment, setSelectedEquipment] = useState<SelectItem | undefined>();
  const [mainCat, setMainCat] = useState<SelectItem|undefined>();
  const [subCat, setSubCat] = useState<SelectItem | undefined>();
  const [orderedBy, setOrderedBy] = useState<number|undefined>();
  const [worksite, setWorksite] = useState<string | undefined>();
  const [comment, setComment] = useState<string | undefined>();
  const [isChanged, setIsChanged] = useState<boolean>(false);

  const { data: allAssignments } = useGetEquipmentAssignmentsForQuery(
    {
      id: selectedEquipment?.id || '',
      from: from !== undefined ? formatISO(startOfDay(from)) : undefined,
      to: to !== undefined ? formatISO(endOfDay(to)) : undefined,
    },
    { skip: selectedEquipment?.id === undefined },
  );

  const totalCost = useMemo(() => {
    const equipment = assignment?.equipment;
    if (!equipment) return null;
    const equipmentCost = equipment.dailyRentalPrice || 0;
    if (!assignment.extraEquipment) return equipmentCost;
    const extraCost = assignment.extraEquipment
      .map((e) => e.dailyRentalPrice)
      .reduce((acc, val) => acc + val, 0);
    return equipmentCost + extraCost;
  }, [assignment]);

  const canEdit = !assignment || isEdit;

  const mainCategories = useMemo(() => (
    equipmentDummies.map((d): SelectItem => ({ id: d.name, label: d.name }))
  ), [equipmentDummies]);

  const subCategories = useMemo(() => {
    if (!mainCat) {
      return equipmentDummies.reduce((a, v) => [...a, ...v.subCategories.map((s) => ({ id: s, label: `${v.name} - ${s}` }))], [] as SelectItem[])
        .sort(labelSort);
    }
    return equipmentDummies.filter((e) => e.name === mainCat?.id)
      .reduce((a, v) => [...a, ...v.subCategories.map((s) => ({ id: s, label: `${v.name} - ${s}` }))], [] as SelectItem[])
      .sort(labelSort) || [];
  }, [equipmentDummies, mainCat]);

  const setMainCategory = (cat: SelectItem|undefined) => {
    const newCat = equipmentDummies.find((d) => d.name === cat?.id);
    if (newCat?.subCategories.length === 1) {
      const newSubCat = newCat.subCategories[0];
      setSubCat({ id: newSubCat, label: newSubCat });
    } else if (subCat && !newCat?.subCategories.some((c) => c === subCat.id)) {
      setSubCat(undefined);
    }
    setMainCat(cat);
  };

  const setSubCategory = (cat: SelectItem|undefined) => {
    if (!mainCat) {
      const newMainCat = equipmentDummies.find((d) => d.subCategories.some((c) => cat?.label.startsWith(d.name) && c === cat?.id));
      if (newMainCat) setMainCat({ id: newMainCat.name, label: newMainCat.name });
    }
    setSubCat(cat ? { id: cat.id, label: `${cat.id}` } : undefined);
  };

  const loggedInUser = useGetLoggedInRentalUserObject();

  useEffect(() => {
    if (loggedInUser === null) return;
    if (assignment) {
      setOrderedBy(assignment.ordererEmployeeNumber || loggedInUser?.employeeNumber);
    } else if (event) {
      setOrderedBy(loggedInUser?.employeeNumber);
    }
  }, [assignment, event, loggedInUser]);

  useEffect(() => {
    if (assignment) {
      setFrom(new Date(assignment.from));
      setTo(new Date(assignment.to));
      setProject({
        id: assignment.project.id,
        label: `${assignment.project.id} - ${assignment.project.projectName}`,
      });
      setSelectedEquipment(
        assignment.equipment
          ? {
            id: assignment.equipment.internalNumber,
            label: `${assignment.equipment.internalNumber} - ${assignment.equipment.subCategoryName} - ${assignment.equipment.modelName}`,
          }
          : undefined,
      );
      setMainCat({ id: assignment.category, label: assignment.category });
      setSubCat({ id: assignment.subCategory, label: assignment.subCategory });
      setWorksite(assignment.worksite ?? assignment.order?.worksite);
      setComment(assignment.orderComment ?? assignment.order?.comment);
    } else if (event) {
      setMainCat({
        id: event.workerType && event.id !== event.workerType ? event.workerType : event.id,
        label: event.workerType && event.id !== event.workerType ? event.workerType : event.id,
      });
      setSubCat(event.workerType && event.id !== event.workerType ? {
        id: event.id,
        label: event.id,
      } : undefined);
      const defaultProject = {
        id: event.parent,
        label: `${event.parent} - ${
          projects.find((p) => p.id === event.parent)?.label
        }`,
      };
      setFrom(addHours(event.startTime, 7));
      setTo(subHours(event.endTime, 9));
      setProject(defaultProject);
      setWorksite(undefined);
      setComment(undefined);
      setIsChanged(true);
    }
  }, [assignment, event, projects]);

  const projectList: SelectItem[] = projects.map((p) => ({
    id: p.id,
    label: `${p.id} - ${p.label}`,
  }));

  const buttonConfirmText = useMemo(() => {
    if (assignment) return 'Lagre';
    return 'Send bestilling';
  }, [assignment]);

  const periodValid = useMemo(() => {
    if (isBefore(from, to)) return true;
    return false;
  }, [from, to]);

  const formValid = useMemo(() => {
    if (assignment) {
      if (!periodValid || !project || !mainCat || !subCat) return false;
    } else if (!event || !periodValid || !project || !mainCat || !subCat) return false;
    return true;
  }, [event, assignment, periodValid, project, mainCat, subCat]);

  const orderUpdate = () => {
    if (!isEdit || !assignment || !project || !mainCat || !subCat || !subCat || !orderedBy) return;
    const body: MoveEquipmentOrder = {
      from: formatISO(from),
      to: formatISO(to),
      category: mainCat.label,
      subCategory: subCat?.label,
      projectId: project.id as number,
      equipmentInternalNumber: selectedEquipment?.id.toString(),
      worksite: worksite || '',
      comment: comment || '',
      ordererEmployeeNumber: orderedBy,
    };
    updateOrder({ id: assignment?.order?.id || assignment.id, body })
      .unwrap()
      .then(() => {
        refetch();
        setSpinner(false);
        onClose();
      })
      .catch(() => {
        toast.error('Kunne ikke oppdatere bestilling');
        setSpinner(false);
      });
  };

  const orderDelete = () => {
    if (!assignment) return;
    setSpinner(true);
    deleteOrder(assignment?.order?.id || assignment.id)
      .unwrap()
      .then(() => {
        refetch();
        setSpinner(false);
        onClose();
      })
      .catch(() => {
        setSpinner(false);
        toast.error('Kunne ikke kansellere bestilling');
      });
  };

  const send = () => {
    if (!event || !mainCat || !subCat || !subCat || !orderedBy) return;
    const body: NewEquipmentOrder = {
      from: formatISO(from),
      to: formatISO(to),
      category: mainCat.label,
      subCategory: subCat.label,
      projectId: event.parent as number,
      equipmentInternalNumber: selectedEquipment?.id.toString(),
      worksite,
      comment,
      ordererEmployeeNumber: orderedBy,
    };
    post(body)
      .unwrap()
      .then(() => {
        refetch();
        setSpinner(false);
        onClose();
      })
      .catch(() => {
        setSpinner(false);
        toast.error('Kunne ikke opprette bestilling');
      });
  };

  const confirmHandler = (v: string) => {
    setSpinner(true);
    if (assignment) {
      if (v === 'Lagre') return orderUpdate();
    }
    return send();
  };

  const [handOutDate, handInDate] = useMemo(() => {
    if (!assignment) return [undefined, undefined];
    return [
      assignment.handOutDate === undefined
        ? undefined
        : new Date(assignment.handOutDate),
      assignment.handInDate === undefined
        ? undefined
        : new Date(assignment.handInDate),
    ];
  }, [assignment, event, projects]);

  const overlapMessage = useOverlapMessage(
    allAssignments,
    assignment,
    selectedEquipment,
    from,
    to,
    handOutDate,
    handInDate,
  );

  const isOverlap = useMemo(() => {
    if (overlapMessage === null) return false;
    return overlapMessage.length > 0;
  }, [overlapMessage]);

  return (
    <div className="edit-task-component">
      {isOverlap && (
      <Box className="overlapMessage">
        <span className="title">Utstyr overlapper denne perioden fra: </span>
        {overlapMessage}
      </Box>
      )}
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          gap: 2,
          paddingTop: 1,
        }}
      >
        <Box sx={{ display: 'flex', gap: 2 }}>
          <Box sx={{ flex: 1 }}>
            <DateTimePicker
              closeOnSelect
              format="dd.MM.yyyy"
              disableTime
              disabled={!!assignment?.handOutDate || !canEdit}
              size="medium"
              fullWidth
              label="Fra"
              value={from}
              onChange={(d) => {
                setTo(ensureAfter(to, d));
                setFrom(d);
                setIsChanged(true);
              }}
            />
          </Box>
          <Box sx={{ flex: 1 }}>
            <DateTimePicker
              closeOnSelect
              format="dd.MM.yyyy"
              disableTime
              disabled={!!assignment?.handInDate || !canEdit}
              size="medium"
              fullWidth
              label="Til"
              value={to}
              onChange={(d) => {
                setTo(d);
                setIsChanged(true);
              }}
              minDate={from}
            />
          </Box>
        </Box>
        <Box sx={{ flex: 1 }}>
          <SearchSelect
            label="Prosjekt"
            errorLabel="Velg et prosjekt"
            disabled={!canEdit}
            required
            value={project}
            onChange={(p) => {
              setProject(p);
              setIsChanged(true);
            }}
          >
            {projectList}
          </SearchSelect>
        </Box>
        <Box sx={{ display: 'flex', gap: 2, flexDirection: 'column' }}>
          <Box sx={{ display: 'flex', gap: 2 }}>
            {!assignment?.equipment && (
              <>
                <Box sx={{ flex: 1 }}>
                  <SearchSelect
                    label="Hovedkategori"
                    errorLabel="Velg en kategori"
                    required
                    disabled={!canEdit}
                    value={mainCat}
                    onChange={(c) => { setMainCategory(c); setIsChanged(true); }}
                  >
                    {mainCategories}
                  </SearchSelect>
                </Box>
                <Box sx={{ flex: 1 }}>
                  <SearchSelect
                    label="Underkategori"
                    errorLabel="Velg en kategori"
                    required
                    disabled={!canEdit}
                    value={subCat}
                    onChange={(c) => {
                      setSubCategory(c);
                      setSelectedEquipment(undefined);
                      setIsChanged(true);
                    }}
                  >
                    {subCategories}
                  </SearchSelect>
                </Box>
              </>
            )}
          </Box>
          <WorkerPicker
            label="Bestiller"
            value={orderedBy}
            disabled={!canEdit}
            onChange={(x) => { setOrderedBy(x); setIsChanged(true); }}
          />
          <Box sx={{ flex: 1 }}>
            <TextField
              fullWidth
              label="Anlegg - Lokasjon/adresse ved bruk av regnings nr"
              variant="outlined"
              value={worksite || ''}
              onChange={(v) => {
                setWorksite(v.target.value);
                setIsChanged(true);
              }}
            />
          </Box>
          {(!!assignment?.equipment?.internalNumber) && (
            <Box sx={{ flex: 1 }}>
              <TextField
                fullWidth
                label="Internnummer dersom tilgjengelig"
                variant="outlined"
                disabled
                value={`${assignment.equipment.internalNumber} - ${assignment.equipment.subCategoryName} - ${assignment.equipment.modelName}` || ''}
              />
              <Box>
                {assignment.extraEquipment && assignment.extraEquipment.length > 0 && (
                  <DataField label="Ekstrautstyr" sx={{ marginTop: 2, marginBottom: 1 }}>
                      {assignment.extraEquipment.map((ee) => (
                        <Typography sx={{ paddingLeft: 1, fontSize: '15px' }}>
                          {`${ee.internalNumber} - ${ee.subCategoryName} - ${ee.modelName} (${ee.dailyRentalPrice} kr/dag)`}
                        </Typography>
                      ))}
                  </DataField>
                )}
              </Box>
            </Box>
          )}
          {isOverlap && (
            <Box className="overlapMessage">
              <span className="title">
                Utstyr overlapper denne perioden fra:{' '}
              </span>
              {overlapMessage}
            </Box>
          )}
          <Box>
            <TextField
              label="Bestillingskommentar"
              fullWidth
              multiline
              disabled={!canEdit}
              value={comment || ''}
              onChange={(e) => {
                setComment(e.target.value);
                setIsChanged(true);
              }}
              minRows={4}
            />
          </Box>
        </Box>

        {!periodValid && (
          <Typography color={NOT_DELIVERED_COLOR} align="right">
            Fra tid må være før til tid
          </Typography>
        )}

        <Box display="flex">
          <Box color="#666666" display="flex" flex={1} flexDirection="column">
            {assignment?.lastEditTime && (
              <span>
                Sist endret{' '}
                {assignment?.lastEditTime
                  ? format(
                    new Date(assignment.lastEditTime),
                    "dd.MM.yyyy 'kl.' HH:mm",
                  )
                  : assignment?.createdTimestamp
                  && format(
                    new Date(assignment.createdTimestamp),
                    "dd.MM.yyyy 'kl.' HH:mm",
                  )}
                {assignment?.lastEditedByName
                  ? ` av ${assignment.lastEditedByName}`
                  : assignment?.createdByName
                  && ` av ${assignment.createdByName}`}
              </span>
            )}
            {assignment?.approvedTime && (
              <span>
                Godkjent{' '}
                {assignment?.approvedTime
                  ? format(
                    new Date(assignment.approvedTime),
                    "dd.MM.yyyy 'kl.' HH:mm",
                  )
                  : assignment?.lastEditTime
                  && format(
                    new Date(assignment.lastEditTime),
                    "dd.MM.yyyy 'kl.' HH:mm",
                  )}
                {assignment?.approvedByName
                  ? ` av ${assignment.approvedByName}`
                  : assignment?.lastEditedByName
                  && ` av ${assignment.lastEditedByName}`}
              </span>
            )}
          </Box>
          <Box>
            <DataField sx={{ display: totalCost === null ? 'none' : undefined }} label="Totalkostnad">
              {totalCost} kr/dag
            </DataField>
          </Box>
        </Box>

        <Box
          sx={{
            display: 'flex',
            justifyContent: canEdit ? 'space-between' : 'flex-end',
          }}
        >
          <Box display="flex" gap={2}>
            {assignment && assignment.status !== 'Approved' && isEdit && (
              <PopConfirm
                confirmText="Kanseller"
                buttonText="Kanseller"
                buttonColor="primary"
                buttonVariant="outlined"
                buttonProps={{ disabled: spinner }}
                content={(
                  <>
                    Vil du kansellere denne bestillingen?
                    <br />
                    Det vil ikke være mulig å angre
                  </>
                )}
                onConfirm={orderDelete}
              />
            )}
          </Box>
          <Box sx={{ display: 'flex', gap: 2 }}>
            <Button
              disabled={spinner}
              color="primary"
              variant="outlined"
              onClick={onClose}
            >
              Lukk
            </Button>
            {isEdit && (
              <Button
                variant="contained"
                value={buttonConfirmText}
                disabled={!formValid || spinner || !isChanged}
                onClick={(e) => confirmHandler(e.currentTarget.value)}
              >
                {spinner ? <CircularProgress size={24} /> : buttonConfirmText}
              </Button>
            )}
          </Box>
        </Box>
      </Box>
    </div>
  );
};
