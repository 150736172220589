import { useMemo } from 'react';
import { nextWorkingDay, nextWorkingDayWithRequirement } from '../calendar';
import { useCalendar } from '../redux/calendar';

export const useNextWorkingDay = () => {
  const { data: calendar } = useCalendar();
  const day = useMemo(() => nextWorkingDay(new Date(Date.now()), calendar || []), [calendar]);
  return { day };
};

export const useNextWorkingDayWithRequirement = (reqFun: (v: Date) => boolean) => {
  const { data: calendar } = useCalendar();
  const day = useMemo(() => nextWorkingDayWithRequirement(
    new Date(Date.now()),
    calendar || [],
    reqFun,
  ), [calendar, reqFun]);
  return day;
};
