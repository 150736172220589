import React, { FC } from 'react';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import { Button } from '@mui/material';

interface CancelButtonProps {confirm: () => void}

export const CancelButton: FC<CancelButtonProps> = ({ confirm }) => {
  const [open, setOpen] = React.useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };
  return (
    <>
      <Button
        onClick={handleClickOpen}
        variant="outlined"
        size="large"
      >
        Avbryt
      </Button>
      <Dialog
        open={open}
        onClose={handleClose}
      >
        <DialogTitle>
          Er du sikker på at du vil avbryte?
        </DialogTitle>
        <DialogContent>
          <DialogContentText>
            Klikk på &quot;Ja&quot; for å gå tilbake til startside, eller klikk
            på &quot;Nei&quot; for å fortsette bestilling.
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>Nei</Button>
          <Button onClick={confirm} autoFocus>
            Ja
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};
