import { useState } from 'react';
import { format } from 'date-fns';
import { Chip, CircularProgress } from '@mui/material';
import { useParams } from 'react-router-dom';
import { skipToken } from '@reduxjs/toolkit/dist/query';
import { OrderStatusIcon } from '../../components/OrderStatusIcon';
import { extractData } from '../../core/helpers/functions';
import { getTransportType } from '../../core/helpers/translate';
import {
  useCancelOrderMutation,
  useGetContainerTypesQuery,
  useGetContainerWasteTypesQuery,
  useGetMassTypesQuery,
  useGetOrderQuery,
  useGetProjectContainersQuery,
} from '../../core/redux/transport';
import { PageUnauthenticated } from '../../shared/components/page';
import { Header } from '../../components/header';
import { useVehicleList } from '../../core/hooks/useVehicleList';
import { EditOrder } from './components/edit';
import { ViewOrder } from './components/view';
import './style.scss';

export const OrderDetailsTransportPage = () => {
  const params = useParams() as any;
  const { data: order } = useGetOrderQuery(params.id);
  const { data: vehicles } = useVehicleList();
  const { data: massTypes } = useGetMassTypesQuery();
  const { data: containerTypes } = useGetContainerTypesQuery();
  const { data: wasteTypes } = useGetContainerWasteTypesQuery();
  const { data: exContainers } = useGetProjectContainersQuery(order?.fromProject?.id ?? skipToken);
  const [cancelOrder, { isLoading: cancelLoading }] = useCancelOrderMutation();

  const [isEdit, setIsEdit] = useState(false);

  if (!order) return null;
  const orderData = extractData(order);

  return (
    <PageUnauthenticated className="order-page">
      <Header showBack>Bestillingsdetaljer</Header>
      <div className="order-wrapper">
        <div className="order-inner">
          <div className="order-header">
            <div className="line-wrapper">
              <span><Chip color="info" label={getTransportType(order.type)} size="small" /></span>
              <span className="header">{`${orderData.title}`}</span>
              <span>
                <OrderStatusIcon
                  currStatus={order.type === 'Diesel' && order.status === 'Planned' ? 'Completed' : order.status}
                />
              </span>
            </div>
            <div className="line-wrapper mt5">
              <span className="left updated-by">Oppdatert: {`${order?.lastEditTime
                ? `${format(new Date(order?.lastEditTime), 'dd.MM.yyyy')} kl. ${format(new Date(order?.lastEditTime), 'HH:mm')}`
                : 'uspesifisert tidspunkt'} av ${order?.lastEditByName
                ? order?.lastEditByName : 'uspesifisert person'}`}
              </span>
              <span className="right updated-by">#{order.id}</span>
            </div>
          </div>
          <div className="flex-task">
            {isEdit ? (
              <EditOrder
                order={order}
                massTypes={massTypes}
                containerTypes={containerTypes}
                wasteTypes={wasteTypes}
                exContainers={exContainers}
                onEndEdit={() => setIsEdit(false)}
              />
            ) : (
              <ViewOrder
                order={order}
                vehicles={vehicles}
                onCancel={cancelOrder}
                onStartEdit={() => setIsEdit(true)}
              />
            )}
          </div>
        </div>
      </div>
      {(cancelLoading) && (
      <CircularProgress
        size={40}
        sx={{
          position: 'fixed',
          bottom: '50%',
          left: '50%',
          marginBottom: '-12px',
          marginLeft: '-12px',
          zIndex: 1000,
        }}
      />
      )}
    </PageUnauthenticated>
  );
};
