import { FC, useMemo } from 'react';
import Button from '@mui/material/Button';
import { useNavigate } from 'react-router-dom';
import { Box, TextField } from '@mui/material';
import { useDispatch, useSelector } from '../../shared/hooks/redux';
import { PageUnauthenticated } from '../../shared/components/page';
import { Header } from '../../components/header';
import { Content } from '../../shared/components/Content';
import { InnsendingLinje } from '../../components/Header4';
import { ProjectPicker } from '../../components/ProjectPicker';
import { CancelButton } from '../../shared/components/CancelButton';
import { SearchSelectDirect } from '../../shared/components/searchSelect';
import { resetStates, setValue } from '../../core/redux/dieselOrderState';
import { dieselTypeItems } from './logic/dieselTypeItems';
import '../../core/styles/orderStyle.scss';

export const DieselOrderPage: FC = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const {
    projectTo,
    dieselType,
    dieselAmount,
    dieselContainerAmount,
  } = useSelector((s) => s.dieselOrderState);

  const disableNextPage = useMemo(() => {
    if (projectTo === null) return true;
    if (dieselType === null) return true;
    if (!dieselAmount || dieselAmount < 1) return true;
    if (!dieselContainerAmount || dieselContainerAmount < 1) return true;
    return false;
  }, [
    projectTo,
    dieselType,
    dieselAmount,
    dieselContainerAmount,
  ]);

  const getHelperText = (value: number | null) => {
    if (value === null) return 'Dette feltet er påkrevd';
    if (value < 1) return 'Denne verdien må være større enn 0';
    return ' ';
  };

  return (
    <PageUnauthenticated className="diesel-order-page">
      <Header>Dieselbestilling</Header>
      <Content>
        <InnsendingLinje text="Steg 1 - Dieselbestilling" styling="page-item" />
        <Box sx={{ display: 'flex', flexDirection: 'column', gap: 2 }}>
          <ProjectPicker
            label="Til prosjekt"
            value={projectTo}
            onChange={(v) => dispatch(setValue({ projectTo: v?.id ?? null }))}
            disableMaxHeight
            required
          />
          <SearchSelectDirect
            value={dieselType}
            onChange={(v) => dispatch(setValue({ dieselType: v ?? null }))}
            label="Diesel type"
            required
          >{dieselTypeItems}
          </SearchSelectDirect>
          <TextField
            label="Antall tanker"
            type="number"
            fullWidth
            value={dieselContainerAmount ?? ''}
            onChange={(e) => dispatch(setValue({ dieselContainerAmount: Math.round(+e.target.value) }))}
            required
            error={!dieselContainerAmount || dieselContainerAmount < 1}
            helperText={getHelperText(dieselContainerAmount)}
          />
          <TextField
            label="Estimert antall liter"
            type="number"
            fullWidth
            value={dieselAmount ?? ''}
            onChange={(e) => dispatch(setValue({ dieselAmount: Math.round(+e.target.value) }))}
            required
            error={!dieselAmount || dieselAmount < 1}
            helperText={getHelperText(dieselAmount)}
          />
        </Box>
        <div className="bottom-inner">
          <div className="bottom-element">
            <div style={{ width: '64px' }} />
            <CancelButton confirm={() => {
              dispatch(resetStates());
              return navigate('/diesel');
            }}
            />
            <Button
              className="right-element"
              onClick={() => navigate('./../leveringsdetaljer')}
              variant="contained"
              size="large"
              disabled={disableNextPage}
            >
              {'>'}
            </Button>
          </div>
        </div>
      </Content>
    </PageUnauthenticated>
  );
};
