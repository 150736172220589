import { useMemo } from 'react';
import { format, isValid } from 'date-fns';
import { useUrl } from '../../shared/hooks/params';

export const useCalendarDate = (disableAutochange: boolean = false) => {
  const [{ date: urlDate }, change] = useUrl();

  const currentDate = useMemo(() => {
    if (urlDate) {
      const date = new Date(urlDate);
      if (isValid(date)) {
        return date;
      }
    }
    if (!disableAutochange) {
      change('/transportplanlegger', { date: format(new Date(), 'yyyy-MM-dd') });
    }
    return new Date();
  }, [urlDate]);

  return [currentDate, change] as const;
};
