import { useMemo } from 'react';
import {
  addHours,
  isAfter,
  isBefore,
  isSameDay,
  parseISO,
  startOfDay,
} from 'date-fns';
import { useCalendar } from '../redux/calendar';
import { Order } from '../types/transport/order';
import { nextWorkingDay } from '../helpers/calendar';

export const useIsLate = () => {
  const { data: calendar } = useCalendar();
  const isLate = useMemo(
    () => (order: Order) => {
      if (order === undefined) return false;
      const createdTimestamp = parseISO(order.createdTimestamp);
      const orderDate = parseISO(order.date);
      const limit = addHours(startOfDay(createdTimestamp), 12);
      const nextwd = nextWorkingDay(createdTimestamp, calendar || []);
      return (
        isSameDay(createdTimestamp, orderDate)
        || (isAfter(createdTimestamp, limit) && isBefore(orderDate, nextwd))
      );
    },
    [calendar],
  );

  return { isLate };
};

export const useIsOrderLate = (order: Order | undefined) => {
  const { isLate: isLateFunc } = useIsLate();
  const isLate = useMemo(() => {
    if (order === undefined) return false;
    return isLateFunc(order);
  }, [order, isLateFunc]);

  return { isLate };
};
