import { useConvertMass } from './useConvertTransport';
import { Order } from '../../../core/types/transport/order';
import { OrderItem } from './orderItem';

export const MassOrderItem = ({
  order,
}: {
  order: Order,
}) => {
  const data = useConvertMass(order);
  return (
    <OrderItem order={data} />
  );
};
