import {
  UpdateContainerCollectOrder,
  UpdateContainerDeliverOrder,
  UpdateContainerEmptyingOrder,
  UpdateDieselOrder,
  UpdateHaulHookOrder,
  UpdateHaulSemiOrder,
  UpdateInOrder,
  UpdateInternalOrder,
  UpdateOrder,
  UpdateOutOrder,
} from '../types/transport/api/updateOrder';
import { Order } from '../types/transport/order';

export const order2UpdateOrder = (order: Order): UpdateOrder => {
  const baseData = {
    type: order.type,
    fromProject: order.fromProject?.id,
    toProject: order.toProject?.id,
    loads: order.loads.length
      ? order.loads.map((l) => ({
        amount: l.amount,
        massTypeId: (l.type && parseInt(l.type.id, 10)) || undefined,
        wasteType: l.wasteType || undefined,
        containerInternalNumber: l.containerInternalNumber || undefined,
        containerType: l.containerType || undefined,
        machineInternalNumber: l.machineInternalNumber ?? l.machine?.internalNumber,
        machineExternal: l.machineExternal,
      }))
      : undefined,
    date: order.date,
    when: order.when,
    importantDeadline: order.importantDeadline,
    vehicle: order.vehicle,
    extraTimeInFacility: order.extraTimeInFacility,
    comment: order.comment,
    contactPersonEmployeeNumber: order.contactPersonEmployeeNumber,
    contactPersonName: order.contactPersonName,
    contactPersonPhoneNumber: order.contactPersonPhoneNumber,
    numberOfVehicles: order.numberOfVehicles,
  };

  if (order.type === 'MassIn') {
    return {
      ...baseData,
      type: 'MassIn',
      numberOfVehicles: undefined,
    } as UpdateInOrder;
  }
  if (order.type === 'MassOut') {
    return {
      ...baseData,
      type: 'MassOut',
      numberOfVehicles: undefined,
    } as UpdateOutOrder;
  }
  if (order.type === 'MassInternal') {
    return {
      ...baseData,
      type: 'MassInternal',
      loads: undefined,
    } as UpdateInternalOrder;
  }
  if (order.type === 'ContainerDeliver') {
    return {
      ...baseData,
      type: 'ContainerDeliver',
      vehicle: undefined,
    } as UpdateContainerDeliverOrder;
  }
  if (order.type === 'ContainerCollect') {
    return {
      ...baseData,
      type: 'ContainerCollect',
      vehicle: undefined,
    } as UpdateContainerCollectOrder;
  }
  if (order.type === 'HaulHook') {
    return {
      ...baseData,
      type: 'HaulHook',
      numberOfVehicles: undefined,
      gateCode: order.gateCode,
      extraEquipment: order.extraEquipment,
      hasDispensation: order.hasDispensation,
      daytimeDelivery: order.daytimeDelivery,
    } as UpdateHaulHookOrder;
  }
  if (order.type === 'HaulSemi') {
    return {
      ...baseData,
      type: 'HaulSemi',
      numberOfVehicles: undefined,
      gateCode: order.gateCode,
      extraEquipment: order.extraEquipment,
      hasDispensation: order.hasDispensation,
      daytimeDelivery: order.daytimeDelivery,
    } as UpdateHaulSemiOrder;
  }
  if (order.type === 'Diesel') {
    return {
      ...baseData,
      type: 'Diesel',
      fromProject: undefined,
      vehicle: undefined,
      numberOfVehicles: undefined,
    } as UpdateDieselOrder;
  }
  return {
    ...baseData,
    type: 'ContainerEmptying',
    vehicle: undefined,
  } as UpdateContainerEmptyingOrder;
};
