import { FC, useEffect } from 'react';
import Button from '@mui/material/Button';
import { useNavigate } from 'react-router-dom';
import { Checkbox, FormControlLabel } from '@mui/material';
import { isSameDay } from 'date-fns';
import { useDispatch, useSelector } from '../../../../shared/hooks/redux';
import { ChooseDate } from '../../../../components/ChooseDate';
import { ChooseDeliveryTime } from '../../../../components/ChooseDeliveryTime';
import { Header } from '../../../../components/header';
import { InnsendingLinje } from '../../../../components/Header4';
import { futureDateIsNotSelected } from '../../../../core/helpers/functions';
import {
  setDeliveryDate,
  setIsEarlyDelivery,
  setDeliveryTimeType,
  setStartTime,
  setEndTime,
  setIsImportantDeadline,
  resetStates,
} from '../../../../core/redux/containerOrderState';
import { CancelButton } from '../../../../shared/components/CancelButton';
import { Content } from '../../../../shared/components/Content';
import { PageUnauthenticated } from '../../../../shared/components/page';
import { useSetDeliveryDateToNextWorkingDay } from '../../../DeliveryDate';

export const ContainerEmptyDetailsPage: FC = () => {
  const navigate = useNavigate();
  const {
    projectTo,
    deliveryDate,
    startTime,
    endTime,
    deliveryTimeType,
    userDefinedDeliveryDate,
    isEarlyDelivery,
    isImportantDeadLine,
  } = useSelector((s) => s.containerOrderState);
  const dispatch = useDispatch();

  const setDeliveryDateToNextWorkingDay = useSetDeliveryDateToNextWorkingDay('container');

  useEffect(() => {
    if (projectTo === null) {
      navigate('/container');
    }
  }, [projectTo]);

  if (projectTo === null) {
    return null;
  }

  // all conditions should be false to move on to next page
  const disableNextPage = () => {
    const checkTimeSelected = deliveryTimeType.type === 5
      && (startTime === null || endTime === null);
    if (deliveryTimeType.type === 5) {
      return (
        futureDateIsNotSelected(deliveryDate, userDefinedDeliveryDate)
        || checkTimeSelected
      );
    }
    return (
      futureDateIsNotSelected(deliveryDate, userDefinedDeliveryDate)
      || checkTimeSelected);
  };

  return (
    <PageUnauthenticated className="leveringsdetaljer-page parent-pos">
      <Header>Containerbestilling</Header>
      <Content>
        <InnsendingLinje text="Steg 2 - Leveringsdetaljer" styling="page-item" />

        <InnsendingLinje text="Leveringsdato" styling="page-item" />

        <div className="page-item">
          <Button
            onClick={setDeliveryDateToNextWorkingDay}
            variant={deliveryDate.type === 1 ? 'contained' : 'outlined'}
            size="medium"
          >
            Neste arbeidsdag
          </Button>
          <Button
            onClick={() => dispatch(setDeliveryDate({ type: 2, day: userDefinedDeliveryDate }))}
            variant={deliveryDate.type === 2 ? 'contained' : 'outlined'}
            size="medium"
          >
            Velg dato
          </Button>
          <Button
            onClick={() => dispatch(setDeliveryDate({ type: 3, day: new Date() }))}
            variant={deliveryDate.type === 3 ? 'contained' : 'outlined'}
            size="medium"
          >
            I dag
          </Button>
        </div>
        <ChooseDate state="container" />

        <FormControlLabel
          disabled={(deliveryDate.day && isSameDay(deliveryDate.day, new Date())) || false}
          className="page-item pt10"
          control={(
            <Checkbox
              checked={isEarlyDelivery}
              onClick={() => dispatch(setIsEarlyDelivery(!isEarlyDelivery))}
            />
              )}
          label="Kan leveres dagen før"
        />

        <InnsendingLinje text="Leveringstidspunkt" styling="page-item" />

        <div className="page-item">
          <Button
            onClick={() => dispatch(setDeliveryTimeType({ type: 1, time: 'I løpet av dagen' }))}
            variant={deliveryTimeType.type === 1 ? 'contained' : 'outlined'}
            size="medium"
          >
            I løpet av dagen
          </Button>
          <Button
            onClick={() => dispatch(setDeliveryTimeType({ type: 2, time: 'Morgen' }))}
            variant={deliveryTimeType.type === 2 ? 'contained' : 'outlined'}
            size="medium"
          >
            Morgen
          </Button>
          <Button
            onClick={() => dispatch(setDeliveryTimeType({ type: 3, time: 'Før mat' }))}
            variant={deliveryTimeType.type === 3 ? 'contained' : 'outlined'}
            size="medium"
          >
            Før mat
          </Button>
          <Button
            onClick={() => dispatch(setDeliveryTimeType({ type: 4, time: 'Etter mat' }))}
            variant={deliveryTimeType.type === 4 ? 'contained' : 'outlined'}
            size="medium"
          >
            Etter mat
          </Button>
          <Button
            onClick={() => dispatch(setDeliveryTimeType({ type: 5, time: 'Egendefinert' }))}
            variant={deliveryTimeType.type === 5 ? 'contained' : 'outlined'}
            size="medium"
          >
            Egendefinert
          </Button>
        </div>
        {deliveryTimeType.type === 5
        && (
        <ChooseDeliveryTime
          startTime={startTime}
          onStartTimeChange={(v) => dispatch(setStartTime(v))}
          endTime={endTime}
          onEndTimeChange={(v) => dispatch(setEndTime(v))}
          isImportantDeadLine={isImportantDeadLine}
          onIsImportantDeadlineChange={(v) => dispatch(setIsImportantDeadline(v))}
          deliveryDate={deliveryDate.day}
        />
        )}
        <div className="bottom-inner">
          <div className="bottom-element">
            <Button
              className="left-element"
              onClick={() => navigate(-1)}
              variant="contained"
              size="large"
            >
              {'<'}
            </Button>
            <CancelButton confirm={() => {
              dispatch(resetStates());
              return navigate('/container');
            }}
            />
            <Button
              className="right-element"
              onClick={() => navigate('/container/tom/tilleggsdetaljer')}
              variant="contained"
              size="large"
              disabled={disableNextPage()}
            >
              {'>'}
            </Button>
          </div>
        </div>
      </Content>
    </PageUnauthenticated>
  );
};
