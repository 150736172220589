import React, { FC, useEffect } from 'react';
import {
  Button,
  CircularProgress,
} from '@mui/material';
import { toast } from 'react-toastify';
import { format } from 'date-fns';
import { useNavigate } from 'react-router-dom';
import { PageUnauthenticated } from '../../../../shared/components/page';
import { useDispatch, useSelector } from '../../../../shared/hooks/redux';
import { Header } from '../../../../components/header';
import { InnsendingLinje } from '../../../../components/Header4';
import { translateWhenType, translateVehicleNumberType, translateNBVehicleType } from '../../../../core/helpers/translate';
import { resetStates } from '../../../../core/redux/massOrderState';
import { useGetProjectsQuery, usePostOrderMutation } from '../../../../core/redux/transport';
import { NewOrder } from '../../../../core/types/transport/api/newOrder';
import { When } from '../../../../core/types/transport/when';
import { CancelButton } from '../../../../shared/components/CancelButton';
import { Content } from '../../../../shared/components/Content';
import { dateWithoutTimezone } from '../../../../shared/logic/dates';

export const InternInnsendingPage: FC = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { data: projects } = useGetProjectsQuery();
  const {
    projectFrom,
    projectTo,
    deliveryTimeType,
    startTime,
    endTime,
    vehicleType,
    deliveryDate,
    isImportantDeadLine,
    orderComment,
    contactPerson,
    numberOfVehicles,
  } = useSelector((s) => s.massOrderState);

  if (projectTo === null || vehicleType.type === 0) {
    navigate('/bestilling');
    return null;
  }

  function getWhen(): When {
    const timeWhen = translateWhenType(deliveryTimeType.time);
    if (timeWhen === 'Custom') {
      return {
        when: timeWhen,
        from: startTime ? startTime.toISOString() : undefined,
        to: endTime ? endTime.toISOString() : undefined,
      };
    }
    return {
      when: timeWhen,
    };
  }

  const [sendOrder, sendOrderResponse] = usePostOrderMutation();
  const postOrder = () => {
    const contactId: number | undefined = contactPerson?.employeeNumber || undefined;
    const contactName: string | undefined = contactPerson?.name || undefined;
    const contactNumber: string | undefined = contactPerson?.number || undefined;

    const hasContact = (contactId !== undefined
      || (contactName !== undefined && contactNumber !== undefined));
    if (!hasContact) {
      toast.error('Ingen kontaktperson');
      return;
    }
    if (projectTo && deliveryDate.day) {
      const orderToSend: NewOrder = {
        type: 'MassInternal',
        toProject: projectTo,
        date: dateWithoutTimezone(deliveryDate.day),
        when: getWhen(),
        earlyDelivery: false,
        importantDeadline: isImportantDeadLine,
        vehicle: translateVehicleNumberType(vehicleType.type),
        comment: orderComment || '',
        contactPersonEmployeeNumber: contactId,
        contactPersonName: contactName,
        contactPersonPhoneNumber: contactNumber,
        numberOfVehicles,
      };
      sendOrder(orderToSend);
    }
  };

  useEffect(() => {
    if (sendOrderResponse.isSuccess) {
      navigate(`/bestilling/bekreftelse/${sendOrderResponse.data.id}`);
    }
  }, [sendOrderResponse.isSuccess]);

  useEffect(() => {
    if (sendOrderResponse.isError) {
      navigate('/interkjoering/innsending');
    }
  }, [sendOrderResponse.isError]);

  function checkIfImportantDeadline() {
    return (
      <h5>
        Viktig Tidsfrist!
      </h5>
    );
  }

  function displayTime() {
    if (deliveryTimeType.type < 5) {
      return (
        <div>
          {deliveryTimeType.time}
        </div>
      );
    }
    return (
      <div>
        <div>
          {`Tidligst: ${startTime && format(startTime, 'HH:mm')} `}
        </div>
        <div>
          {`Senest: ${endTime && format(endTime, 'HH:mm')} `}
        </div>
      </div>
    );
  }

  function displayProjectFrom() {
    const project = projects?.find((e) => e.id === projectFrom);
    return (
      <div>
        <InnsendingLinje text="Fra prosjekt" />
        <div>
          {`${project?.id} ${project?.projectName}`}
        </div>
      </div>
    );
  }

  const project = projects?.find((e) => projectTo === e.id);

  return (
    <PageUnauthenticated className="innsending-page parent-pos">
      <Header>Massebestilling</Header>
      <Content>
        <InnsendingLinje text="Steg 3 - Innsending" styling="page-item" />
        <div className="extra-inline-padding">
          <InnsendingLinje text="Din bestilling er klar til innsending" />
          <p>
            Les gjennom oppsummeringen under og sjekk at informasjonen stemmer før du sender
          </p>
          <InnsendingLinje text="Internkjøring" />
          <InnsendingLinje text="Til prosjekt" />
          <div>
            {`${project?.id} ${project?.projectName}`}
          </div>
          {projectFrom && displayProjectFrom()}
          <InnsendingLinje text="Kjøretøy" />
          <div>
            {translateNBVehicleType(vehicleType.type)}
          </div>
          <InnsendingLinje text="Antall" />
          <div>
            {numberOfVehicles}
          </div>
          <InnsendingLinje text="Leveringsdato" />
          <div>
            {`${deliveryDate.day && format(deliveryDate.day, 'dd.MM.yyyy')}`}
          </div>
          <InnsendingLinje text="Leveringstidspunkt" />
          {displayTime()}
          {isImportantDeadLine && checkIfImportantDeadline()}
          <InnsendingLinje text="Kontaktperson" />
          <div>
            {`${contactPerson?.name} - ${contactPerson?.number}`}
          </div>
          <div>
            <InnsendingLinje text="Kommentar" />
          </div>
          <div className="display-linebreak">
            {orderComment}
          </div>
        </div>
        <div className="bottom-inner">
          <div className="bottom-element">
            <Button
              className="left-element"
              onClick={() => navigate(-1)}
              variant="contained"
              size="large"
            >
              Tilbake
            </Button>
            <CancelButton confirm={() => {
              dispatch(resetStates());
              return navigate('/bestilling');
            }}
            />
            <Button
              className="right-element"
              disabled={sendOrderResponse.isLoading}
              onClick={postOrder}
              variant="contained"
              size="large"
            >
              Send inn
            </Button>
            {sendOrderResponse.isLoading && (
            <CircularProgress
              size={24}
              sx={{
                position: 'absolute',
                top: '50%',
                left: '50%',
                marginTop: '-12px',
                marginLeft: '-12px',
              }}
            />
            )}
          </div>
        </div>
      </Content>
    </PageUnauthenticated>
  );
};
