import { useMemo, useState } from 'react';
import {
  format,
  compareAsc,
  subDays,
  // eslint-disable-next-line import/no-duplicates
} from 'date-fns';
// eslint-disable-next-line import/no-duplicates
import { nb } from 'date-fns/locale';
import { Button, Card, Popover } from '@mui/material';
import AltRouteIcon from '@mui/icons-material/AltRoute';
import AlarmIcon from '@mui/icons-material/Alarm';
import { toast } from 'react-toastify';
import {
  extractData,
  getContainerLoadTitle,
  getDeliveryTimes,
  getDieselLoadTitle,
  getHaulLoadTitle,
  optionalBool,
  pluralize,
  sumAssignedLoads,
} from '../../../core/helpers/functions';
import { translateVehicleType } from '../../../core/helpers/translate';
import { msalInstance } from '../../../shared/msal';
import { useIsType } from '../../../core/hooks/useIsType';
import { AssignmentStatusIcon } from '../../../components/AssignmentStatusIcon';
import { useIsOrderLate } from '../../../core/hooks/useIsLate';
import { AssignedDriver } from '../../../core/types/transport/driver';
import { Load } from '../../../core/types/transport/load';
import '../style.scss';
import { Order } from '../../../core/types/transport/order';
import { Vehicle } from '../../../core/types/transport/vehicle';
import { AssignedAssignment } from '../../../core/types/transport/assignment';
import { useHasAppRoles } from '../../../shared/hooks/hasAppRole';
import { config } from '../../../shared/config/transport';
import { useGetDieselDeliveredAmountQuery } from '../../../core/redux/transport';

interface AssignmentData extends AssignedAssignment {
  vehicleType: string;
  driver?: AssignedDriver;
}

const loadTitle = (o: Order, l: Load) => {
  const isType = useIsType(o.type);
  if (isType('Haul')) return getHaulLoadTitle(l);
  if (isType('Container')) return getContainerLoadTitle(o.type, l);
  if (isType('Diesel')) return getDieselLoadTitle(l);
  return `${o.vehicles ? sumAssignedLoads(l.type?.id, o) : 100}m³ av ${
    l.amount
  }m³ ${l.type?.name}`;
};

const checkCanEdit = (
  order: Order,
  /** If true, bypass deadline and always allow editing if status is created */
  bypassDeadline = false,
) => {
  if (order.status !== 'Created') return false;
  if (bypassDeadline) return true;
  const deadline = new Date(order.date);
  deadline.setHours(12, 0, 0, 0);
  if (compareAsc(new Date(), subDays(deadline, 1)) === 1) return false;
  return true;
};

export const ViewOrder = ({
  order,
  vehicles,
  onCancel,
  onStartEdit,
}: {
  order: Order;
  vehicles: Vehicle[] | undefined;
  onCancel?: (id: number) => Promise<unknown>;
  onStartEdit?: () => void;
}) => {
  const { data: deliveredDiesel } = useGetDieselDeliveredAmountQuery(order.id, {
    skip: order.type !== 'Diesel' || order.status !== 'Planned',
  });
  const { isLate } = useIsOrderLate(order);
  const isDieselAdmin = useHasAppRoles(config, 'admin', 'diesel-koordinator')
    && order.type === 'Diesel'
    && order.status === 'Created';
  const canEdit = checkCanEdit(order, isDieselAdmin);
  const orderData = extractData(order);

  const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null);
  const open = Boolean(anchorEl);
  const id = open ? 'popover' : undefined;

  const isType = useIsType(order?.type);
  const isMass = isType('Mass');
  const isMassInternal = isType('MassInternal');
  const isMassInOut = isType('MassIn', 'MassOut');
  const isContainer = isType('Container');
  const isHaul = isType('Haul');
  const isDiesel = isType('Diesel');

  const user = msalInstance.getActiveAccount();
  const isOwner = order.createdBy === user?.localAccountId;

  const handleDelete = () => {
    setAnchorEl(null);
    if (order) {
      onCancel?.(order.id).catch(() => toast.error('Kunne ikke kansellere bestilling, prøv igjen'));
    }
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const assignments: AssignmentData[] = useMemo((): AssignmentData[] => {
    if (!order?.vehicles) return [];
    return order.vehicles
      .flatMap((v) => v.assignments.map((a) => ({
        ...a,
        vehicleType: v.type,
        driver: v.driver,
      })))
      .filter((a) => a.status !== 'UnderPlanning');
  }, [order, vehicles]);

  if (!order) {
    return null;
  }

  return (
    <>
      <div className="order-task">
        <div className="order-info">
          <div className="info-item">
            <span className="info-label">Innsender</span>
            <span className="info-content">{`${order.createdByName}`}</span>
          </div>
          <div className="info-item">
            <span className="info-label">Innsendt</span>
            <span
              className="info-content"
              title={isLate ? 'Sen innsending' : undefined}
            >
              {`${format(new Date(order.createdTimestamp), 'dd.MM.yyyy', {
                locale: nb,
              })}
                       kl. ${format(new Date(order.createdTimestamp), 'HH:mm', {
                locale: nb,
              })}`}
              {isLate && (
                <>
                  &nbsp;
                  <AlarmIcon fontSize="small" color="error" />
                </>
              )}
            </span>
          </div>
          <div className="info-item">
            <span className="info-label">Leveringsdato</span>
            <span
              className="info-content"
              title={order.earlyDelivery ? 'Kan leveres dagen før' : undefined}
            >
              {`${format(new Date(order.date), 'dd.MM.yyyy')}`}
              {order.earlyDelivery && (
                <>
                  &nbsp;
                  <AltRouteIcon fontSize="small" color="primary" />
                </>
              )}
            </span>
          </div>
          <div className="info-item">
            <span className="info-label">Kontaktperson</span>
            <span className="info-content">{`${order.contactPersonName} (${order.contactPersonPhoneNumber})`}</span>
          </div>
          {!isMassInternal && (
            <div className="info-item">
              <span className="info-label">{isHaul ? 'Maskin' : 'Last'}</span>
              {order.loads.map((l) => (
                <div key={l.id}>
                  <span className="info-content mb5">
                    {loadTitle(order, l)}
                  </span>
                </div>
              ))}
            </div>
          )}
          {isMass && (
            <div className="info-item">
              <span className="info-label">Ønsket kjøretøy</span>
              <span className="info-content">
                {translateVehicleType(order)}
              </span>
            </div>
          )}
          {!isType('MassInternal', 'ContainerEmptying', 'Diesel') && (
            <div>
              <div className="info-item">
                <span className="info-label">Fra prosjekt</span>
                <span className="info-content">
                  {order.fromProject ? (
                    <a href={`/prosjekt/${order.fromProject.id}`}>
                      {`${order.fromProject.id} ${order.fromProject.projectName}`}
                    </a>
                  ) : (
                    'N/A'
                  )}
                </span>
              </div>
              <div className="info-item">
                <span className="info-label">Til prosjekt</span>
                <span className="info-content">
                  {order.toProject ? (
                    <a href={`/prosjekt/${order.toProject.id}`}>
                      {`${order.toProject.id} ${order.toProject.projectName}`}
                    </a>
                  ) : (
                    'N/A'
                  )}
                </span>
              </div>
            </div>
          )}
          {isMassInOut && (
            <>
              <div className="info-item">
                <span className="info-label">
                  Antall oppdrag (opprettet/estimert)
                </span>
                <span className="info-content">
                  {`${order.details?.assignmentCount || 0}/${orderData.loads}`}
                </span>
              </div>
              <div className="info-item">
                <span className="info-label">
                  Antall kjøretøy (opprettet/estimert)
                </span>
                <span className="info-content">
                  {`${order.details?.vehicleCount || 0}/${orderData.vehicles}`}
                </span>
              </div>
            </>
          )}
          {isContainer && (
            <div className="info-item">
              <span className="info-label">
                Antall containere (opprettet/bestilling)
              </span>
              <span className="info-content">
                {`${order.details?.totalAmount || 0}/${orderData.totalAmount}`}
              </span>
            </div>
          )}
          {(isHaul || isDiesel) && (
            <div className="info-item">
              <span className="info-label">Kode for port</span>
              <span className="info-content">{order.gateCode ?? 'Nei'}</span>
            </div>
          )}
          {isHaul && (
            <div className="info-item">
              <span className="info-label">Ekstra utstyr</span>
              <span className="info-content">
                {order.extraEquipment ?? 'Nei'}
              </span>
            </div>
          )}
          {isHaul && (
            <div className="info-item">
              <span className="info-label">Dispensasjon</span>
              <span className="info-content">
                {optionalBool(order.hasDispensation)}
              </span>
            </div>
          )}
          {isHaul && (
            <div className="info-item">
              <span className="info-label">Kan tas på dagtid</span>
              <span className="info-content">
                {order.daytimeDelivery ? 'Ja' : 'Nei'}
              </span>
            </div>
          )}
          {isType('MassInternal', 'ContainerEmptying', 'Diesel') && (
            <div className="info-item">
              <span className="info-label">Prosjekt</span>
              <span className="info-content">
                {order.toProject ? (
                  <a href={`/prosjekt/${order.toProject.id}`}>
                    {`${order.toProject.id} ${order.toProject.projectName}`}
                  </a>
                ) : (
                  'N/A'
                )}
              </span>
            </div>
          )}
          {isType('MassInternal', 'ContainerEmptying') && (
            <>
              <div className="info-item">
                <span className="info-label">Bestilt antall kjøretøy</span>
                <span className="info-content">{`${order.numberOfVehicles}`}</span>
              </div>
              <div className="info-item">
                <span className="info-label">Faktisk antall kjøretøy </span>
                <span className="info-content">
                  {order.details?.vehicleCount || 0}
                </span>
              </div>
            </>
          )}
          {!isDiesel && (
            <div className="info-item">
              <span className="info-label">Ønsket leveringstidspunkt</span>
              <span className="info-content line-wrapper">
                <span id="break">{getDeliveryTimes(order)}</span>
                {order.importantDeadline && (
                  <span
                    className="important-deadline"
                    title="Viktig tidsfrist!"
                  >
                    Viktig tidsfrist!
                  </span>
                )}
              </span>
            </div>
          )}
          {!isMassInternal && !isHaul && !isDiesel && (
            <div className="info-item">
              <span className="info-label">Ekstra tid i anlegget</span>
              <span className="info-content">
                {`${pluralize(order.extraTimeInFacility || 0, 'time', 'r')} `}
              </span>
            </div>
          )}
          <div className="info-item">
            <span className="info-label">Bestillingskommentar</span>
            <span className="info-content">
              {`${order.comment || 'Ingen kommentar'}`}
            </span>
          </div>
          {order.type === 'Diesel'
            && order.status === 'Planned'
            && deliveredDiesel && (
              <div className="info-item">
                <span className="info-label">Levert diesel</span>
                {deliveredDiesel.truck > 0 && (
                  <span className="info-content">
                    {deliveredDiesel.truck} liter fra bil
                  </span>
                )}
                {deliveredDiesel.trailer > 0 && (
                  <span className="info-content">
                    {deliveredDiesel.trailer} liter fra henger
                  </span>
                )}
                {deliveredDiesel.truck > 0 && deliveredDiesel.trailer > 0 && (
                  <span className="info-content">
                    Totalt {deliveredDiesel.trailer + deliveredDiesel.truck}{' '}
                    liter
                  </span>
                )}
              </div>
          )}
          {(isDieselAdmin || (isOwner && canEdit)) && (
            <div className="line-wrapper g10">
              <Button
                aria-describedby={id}
                onClick={(e) => setAnchorEl(e.currentTarget)}
                sx={{ marginTop: '15px' }}
                fullWidth
                variant="outlined"
              >
                Kanseller
              </Button>
              <Button
                onClick={() => onStartEdit?.()}
                sx={{ marginTop: '15px' }}
                fullWidth
                variant="contained"
              >
                Rediger
              </Button>
              <Popover
                id={id}
                open={open}
                anchorEl={anchorEl}
                onClose={handleClose}
                elevation={8}
                transitionDuration="auto"
                anchorOrigin={{
                  vertical: 'center',
                  horizontal: 'center',
                }}
                sx={{
                  backgroundColor: 'rgba(0, 0, 0, 0.5)',
                }}
              >
                <div className="m10">
                  <div className="ml5 mb10">
                    <span className="bold-text font-size-17">
                      Er du sikker?
                    </span>
                    <div className="mt5">
                      <span className="font-size-15 italic">
                        {`Du vil nå kansellere bestilling #${order.id}`}
                        <br />
                        Det vil ikke være mulig å angre kansellering.
                      </span>
                    </div>
                  </div>
                  <div className="text-align-end">
                    <Button
                      variant="outlined"
                      onClick={handleClose}
                      sx={{ m: 0.75 }}
                    >
                      Avbryt
                    </Button>
                    <Button
                      sx={{ m: 0.75 }}
                      variant="contained"
                      color="error"
                      onClick={handleDelete}
                    >
                      Kanseller bestilling
                    </Button>
                  </div>
                </div>
              </Popover>
            </div>
          )}
        </div>
      </div>
      {!isDiesel && (
        <div className="assignment-task">
          <div className="assignment-info">
            <span className="info-label">Oppdrag</span>
            {!assignments.length ? (
              <span>Ingen oppdrag på bestilling</span>
            ) : (
              assignments
                .sort((a, b) => compareAsc(new Date(a.startTime), new Date(b.startTime)))
                .map((a) => (
                  <Card className="assignment-card" key={a.id}>
                    <div className="content">
                      <div className="line-wrapper">
                        <span className="left bold">{`${a.vehicleType}`}</span>
                        <span className="right">{`#${a.id}`}</span>
                      </div>
                      <div className="mb5">
                        <span>{`${a.driver?.name} - ${a.driver?.phone}`}</span>
                      </div>
                      <div>
                        {!isMassInternal
                          && a.loads.map((l) => (
                            <div key={l.id}>
                              {order.type.includes('Container') ? (
                                <span>
                                  {getContainerLoadTitle(order.type, l)}
                                </span>
                              ) : (
                                <span>{`${l.amount}m³ ${l.type?.name}`}</span>
                              )}
                            </div>
                          ))}
                      </div>
                      <div className="line-wrapper">
                        <span className="left updated-by">
                          {`${format(new Date(a.startTime), 'dd MMM HH:mm')} -
                            ${
                              new Date(a.startTime).getDay()
                              === new Date(a.endTime).getDay()
                                ? format(new Date(a.endTime), 'HH:mm')
                                : format(new Date(a.endTime), 'dd MMM HH:mm')
                            }`}
                        </span>
                        <span className="right">
                          <AssignmentStatusIcon
                            currStatus={a.status}
                            size="small"
                          />
                        </span>
                      </div>
                    </div>
                  </Card>
                ))
            )}
          </div>
        </div>
      )}
    </>
  );
};
