import { OrderItem } from './orderItem';
import { useConvertRental } from './useConvertRental';
import { EquipmentAssignment } from '../../../shared/types/equipmentAssignment';

export const RentalOrderItem = ({
  order,
}: {
  order: EquipmentAssignment,
}) => {
  const data = useConvertRental(order);
  return (
    <OrderItem order={data} />
  );
};
