import { Box, Typography } from '@mui/material';
import ConstructionIcon from '@mui/icons-material/Construction';
import Arrow from '@mui/icons-material/ChevronRight';
import { OrderItemType } from './orderItem';
import { format } from '../../../shared/logic/dates';
import { EquipmentAssignment } from '../../../shared/types/equipmentAssignment';

export const useConvertRental = (assignment: EquipmentAssignment): OrderItemType => {
  const time = (() => {
    const from = format(assignment.handOutDate || assignment.from, 'yyyy-MM-dd');
    const to = format(assignment.handInDate || assignment.to, 'yyyy-MM-dd');
    if (from === to) return null;
    return (
      <Typography sx={{ display: 'flex', alignItems: 'center' }}>
        {from} <Arrow sx={{ p: 0, m: 0, fontSize: 18 }} /> {to}
      </Typography>
    );
  })();

  const obj: OrderItemType = {
    id: assignment.id,
    path: `/bestilling/internutleie/${assignment.id}`,
    assignedUser: assignment?.orderer && {
      name: assignment.orderer.fullName,
      phone: assignment.orderer.mobilePhoneWork,
    },
    info: (
      <Typography>{assignment.category} - {assignment.subCategory}</Typography>
    ),
    status: assignment.status,
    title: `${assignment.project.id} - ${assignment.project.projectName}`,
    type: 'Rental',
    importantDeadline: false,
    origin: 'rental',
    icons: (
      <Box display="flex" gap={1} alignItems="center">
        <ConstructionIcon color="primary" /> {time}
      </Box>
    ),
  };

  if (assignment && assignment.equipment) {
    const { equipment, extraEquipment } = assignment;
    obj.info = (
      <Box>
        <Typography sx={{
          whiteSpace: 'nowrap',
          overflow: 'hidden',
          textOverflow: 'ellipsis',
        }}
        >
          {equipment.internalNumber}{' - '}
          {equipment.subCategoryName}{' - '}
          {equipment.modelName}
        </Typography>
        {extraEquipment && extraEquipment.map((e) => (
          <Typography sx={{
            display: 'flex',
            gap: 1,
            whiteSpace: 'nowrap',
            overflow: 'hidden',
            textOverflow: 'ellipsis',
          }}
          >
            <Typography sx={{ fontWeight: 'bold' }}>+</Typography>
            {e.internalNumber} - {e.subCategoryName} - {e.modelName}
          </Typography>
        ))}
      </Box>
    );
  }

  return obj;
};
