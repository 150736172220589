import { FC, useState } from 'react';
import Button from '@mui/material/Button';
import { useNavigate } from 'react-router-dom';
import { PageUnauthenticated } from '../../shared/components/page';
import { Header } from '../../components/header';
import { InnsendingLinje } from '../../components/Header4';
import { Project } from '../../core/types/transport/project';
import { Content } from '../../shared/components/Content';
import { ProjectPicker } from '../../components/ProjectPicker';
import { setProjectTo } from '../../core/redux/massOrderState';
import { useDispatch } from '../../shared/hooks/redux';
import '../../core/styles/orderStyle.scss';

export const MassOrderPage: FC = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const [selectedProject, setSelectedProject] = useState<Project | null>(null);

  const disableNextPage = () => (selectedProject === null);

  return (
    <PageUnauthenticated className="bestilling-page">
      <Header>Massebestilling</Header>
      <Content>
        <InnsendingLinje text="Prosjekt" styling="page-item vertical-padding" />
        <div className="page-item vertical-padding mb20">
          <ProjectPicker
            value={selectedProject}
            onChange={(newValue) => {
              if (newValue) {
                setSelectedProject(newValue);
                dispatch(setProjectTo(newValue.id));
              } else {
                setSelectedProject(null);
                dispatch(setProjectTo(null));
              }
            }}
          />
        </div>
        <div className="page-item vertical-padding">
          <Button
            onClick={() => navigate('/masseinn')}
            disabled={disableNextPage()}
            variant="contained"
            size="large"
            fullWidth
          >
            Masse inn
          </Button>
        </div>
        <div className="page-item vertical-padding">
          <Button
            onClick={() => navigate('/masseUt')}
            disabled={disableNextPage()}
            variant="contained"
            size="large"
            fullWidth
          >
            Masse ut
          </Button>
        </div>
        <div className="page-item vertical-padding">
          <Button
            onClick={() => navigate('/internkjoering')}
            disabled={disableNextPage()}
            variant="contained"
            size="large"
            fullWidth
          >
            Internkjøring
          </Button>
        </div>
      </Content>
    </PageUnauthenticated>
  );
};
