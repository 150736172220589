import React, { FC } from 'react';
import CircleIcon from '@mui/icons-material/Circle';
import './style.scss';
import { getAssignmentStatusColor } from '../../core/helpers/functions';
import { getAssignmentStatusType } from '../../core/helpers/translate';
import { AssignmentStatus } from '../../core/types/transport/enums/assignmentStatus';

export const AssignmentStatusIcon: FC<{
  currStatus: AssignmentStatus | undefined,
  size?: 'normal' | 'small',
}> = ({
  currStatus,
  size = 'normal',
}) => {
  const statusType = getAssignmentStatusType(currStatus);
  const statusColor = getAssignmentStatusColor(currStatus);
  return (
    <div className={`align-icon${size === 'small' ? ' small' : ''}`}>
      <CircleIcon className={statusColor} />
      {statusType}
    </div>
  );
};
