import { FC, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { Button } from '@mui/material';
import { skipToken } from '@reduxjs/toolkit/dist/query';
import { formatISO } from 'date-fns';
import { useSelector, useDispatch } from '../../../../shared/hooks/redux';
import { PageUnauthenticated } from '../../../../shared/components/page';
import { Header } from '../../../../components/header';
import { InnsendingLinje } from '../../../../components/Header4';
import { OrderContainerSelector } from '../../../../components/OrderContainerSelector';
import { setContainers, resetStates } from '../../../../core/redux/containerOrderState';
import { useGetFullProjectQuery } from '../../../../core/redux/transport';
import { SelectContainer } from '../../../../core/types/transport/selectContainer';
import { CancelButton } from '../../../../shared/components/CancelButton';
import { Content } from '../../../../shared/components/Content';

export const ContainerCollectPage: FC = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { containers, projectTo } = useSelector((s) => s.containerOrderState);

  useEffect(() => {
    if (!projectTo) navigate('/container');
  }, []);

  const { data: project } = useGetFullProjectQuery(projectTo ? { id: `${projectTo}`, date: formatISO(new Date()) } : skipToken);

  const setVal = (id: number, val: Partial<SelectContainer>) => {
    dispatch(setContainers(containers.map((c) => (c.id === id ? { ...c, ...val } : c))));
  };

  const addContainer = (external: boolean = false) => {
    const id = containers[containers.length - 1].id + 1;
    if (!id) return;
    dispatch(setContainers(
      [
        ...containers.filter((c) => (
          c.isExternal === external
          || c.containerExternal
          || c.containerInternalNumber
        )),
        {
          id,
          isExternal: external,
          containerInternalNumber: undefined,
          containerExternal: undefined,
          type: undefined,
          amount: 1,
          mass: undefined,
        },
      ],
    ));
  };

  const removeContainer = (id: number) => {
    dispatch(setContainers(containers.filter((f) => f.id !== id)));
  };

  const disableNextPage = containers.some((c) => (!c.isExternal && !c.containerInternalNumber)
  || (c.isExternal && !c.containerExternal)
  || !c.mass);

  return (
    <PageUnauthenticated className="container-order-page">
      <Header>Containerbestilling</Header>
      <Content>
        <InnsendingLinje text="Steg 1 - Container henting (avslutning)" styling="page-item" />
        <InnsendingLinje text={`${project?.id} - ${project?.projectName}`} styling="page-item" />
        <OrderContainerSelector
          value={containers}
          onChange={setVal}
          onRemove={removeContainer}
          onAdd={addContainer}
          projectId={projectTo || undefined}
        />
        <div className="bottom-inner">
          <div className="bottom-element">
            <div style={{ width: '64px' }} />
            <CancelButton confirm={() => {
              dispatch(resetStates());
              return navigate('/container');
            }}
            />
            <Button
              className="right-element"
              onClick={() => navigate('/container/inn/leveringsdetaljer')}
              variant="contained"
              size="large"
              disabled={disableNextPage}
            >
              {'>'}
            </Button>
          </div>
        </div>
      </Content>
    </PageUnauthenticated>
  );
};
