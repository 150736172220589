/* eslint-disable no-param-reassign */
/* eslint-disable no-plusplus */
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { ContactPerson } from '../../components/ContactPerson';
import { DeliveryDate } from '../types/transport/utilityTypes';

interface DeliveryTime {
  type: number;
  time: string;
}

interface TypeOfVehicle {
  type: number;
  name: string;
}

interface MassOrderState {
  projectTo: number | null;
  projectFrom: number | null;
  cubic: number | null;
  typeOfMass: number;

  trips: number;
  numberOfVehicles: number;

  splitLoad: boolean;
  splitCubic: number | null;
  splitTypeOfMass: number;

  vehicleType: TypeOfVehicle;
  userDefinedVehicleType: number;

  deliveryTimeType: DeliveryTime;
  startTime: Date | null;
  endTime: Date | null;

  userDefinedDeliveryDate: Date | null;
  deliveryDate: DeliveryDate;

  timeError: string | null;
  isEarlyDelivery: boolean;
  isImportantDeadLine: boolean;

  lastCreatedOrderId: number;

  specifyContactPerson: boolean;
  internalContactPerson: number | null;
  localInternalContactPerson: string | null;
  externalContactPerson: string | null;
  externalContactNumber: number | null;
  timerCounter: number | null;
  orderComment: string;

  contactPerson: ContactPerson | null;
}

const initialState: MassOrderState = {
  projectFrom: null,
  projectTo: null,
  cubic: null,
  typeOfMass: 0,

  trips: 0,

  splitLoad: false,
  splitCubic: 0,
  splitTypeOfMass: 0,

  vehicleType: { type: 0, name: '' },
  userDefinedVehicleType: 0,
  numberOfVehicles: 1,

  deliveryTimeType: { type: 1, time: 'I løpet av dagen' },
  startTime: null,
  endTime: null,

  userDefinedDeliveryDate: null,
  deliveryDate: { type: 0, day: null },

  timeError: null,
  isEarlyDelivery: false,
  isImportantDeadLine: false,
  lastCreatedOrderId: 0,
  timerCounter: 0,

  specifyContactPerson: false,
  internalContactPerson: null,
  localInternalContactPerson: null,
  externalContactPerson: null,
  externalContactNumber: null,

  orderComment: '',

  contactPerson: null,
};

const massOrderStateSlice = createSlice({
  name: 'massOrderState',
  initialState,
  reducers: {
    setProjectFrom(state, action: PayloadAction<number | null>) {
      state.projectFrom = action.payload;
    },
    setProjectTo(state, action: PayloadAction<number | null>) {
      state.projectTo = action.payload;
    },
    setCubic(state, action: PayloadAction<number | null>) {
      state.cubic = action.payload;
    },
    setTypeOfMass(state, action: PayloadAction<number>) {
      state.typeOfMass = action.payload;
    },
    setTrips(state, action: PayloadAction<number>) {
      state.trips = action.payload;
    },
    setSplitLoad(state, action: PayloadAction<boolean>) {
      state.splitLoad = action.payload;
    },
    setSplitCubic(state, action: PayloadAction<number | null>) {
      state.splitCubic = action.payload;
    },
    setSplitTypeOfMass(state, action: PayloadAction<number>) {
      state.splitTypeOfMass = action.payload;
    },
    setNumberOfVehicles(state, action: PayloadAction<number>) {
      state.numberOfVehicles = action.payload;
    },
    setVehicleType(state, action: PayloadAction<TypeOfVehicle>) {
      state.vehicleType = action.payload;
    },
    setUserDefinedVehicleType(state, action: PayloadAction<number>) {
      state.userDefinedVehicleType = action.payload;
    },
    setDeliveryTimeType(state, action: PayloadAction<DeliveryTime>) {
      state.deliveryTimeType = action.payload;
    },
    setStartTime(state, action: PayloadAction<Date | null>) {
      state.startTime = action.payload;
    },
    setEndTime(state, action: PayloadAction<Date | null>) {
      state.endTime = action.payload;
    },
    setUserDefinedDeliveryDate(state, action: PayloadAction<Date | null>) {
      state.userDefinedDeliveryDate = action.payload;
    },
    setDeliveryDate(state, action: PayloadAction<DeliveryDate>) {
      state.deliveryDate = action.payload;
    },
    setTimeError(state, action: PayloadAction<string | null>) {
      state.timeError = action.payload;
    },
    setIsEarlyDelivery(state, action: PayloadAction<boolean>) {
      state.isEarlyDelivery = action.payload;
    },
    setIsImportantDeadline(state, action: PayloadAction<boolean>) {
      state.isImportantDeadLine = action.payload;
    },
    setLastCreatedOrderId(state, action: PayloadAction<number>) {
      state.lastCreatedOrderId = action.payload;
    },
    setTimerCounter(state, action: PayloadAction<number | null>) {
      state.timerCounter = action.payload;
    },
    setSpecifyContactPerson(state, action: PayloadAction<boolean>) {
      state.specifyContactPerson = action.payload;
    },
    setInternalContactPerson(state, action: PayloadAction<number | null>) {
      state.internalContactPerson = action.payload;
    },
    setLocalInternalContactPerson(state, action: PayloadAction<string | null>) {
      state.localInternalContactPerson = action.payload;
    },
    setExternalContactPerson(state, action: PayloadAction<string | null>) {
      state.externalContactPerson = action.payload;
    },
    setExternalContactNumber(state, action: PayloadAction<number | null>) {
      state.externalContactNumber = action.payload;
    },
    setOrderComment(state, action: PayloadAction<string>) {
      state.orderComment = action.payload;
    },
    setContactPerson(state, action: PayloadAction<ContactPerson | null>) {
      state.contactPerson = action.payload;
    },
    resetStates(state) {
      Object.assign(state, { ...initialState, lastCreatedOrderId: state.lastCreatedOrderId });
    },
  },
});

export const {
  setProjectFrom,
  setProjectTo,
  setCubic,
  setTypeOfMass,
  setTrips,
  setNumberOfVehicles,
  setSplitLoad,
  setSplitCubic,
  setSplitTypeOfMass,
  setVehicleType,
  setUserDefinedVehicleType,
  setDeliveryTimeType,
  setStartTime,
  setEndTime,
  setUserDefinedDeliveryDate,
  setDeliveryDate,
  setTimeError,
  setIsEarlyDelivery,
  setIsImportantDeadline,
  setLastCreatedOrderId,
  setTimerCounter,
  setSpecifyContactPerson,
  setInternalContactPerson,
  setLocalInternalContactPerson,
  setExternalContactPerson,
  setExternalContactNumber,
  setOrderComment,
  setContactPerson,
  resetStates,
} = massOrderStateSlice.actions;
export default massOrderStateSlice.reducer;
