import React, { FC, useState } from 'react';
import {
  Button,
  Checkbox,
  FormControl,
  FormControlLabel,
  FormGroup,
  InputLabel,
  MenuItem,
  Select,
  TextField,
} from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { useSelector, useDispatch } from '../../../../shared/hooks/redux';
import { Header } from '../../../../components/header';
import { InnsendingLinje } from '../../../../components/Header4';
import { ProjectPicker } from '../../../../components/ProjectPicker';
import { calculateTonnage } from '../../../../core/helpers/functions';
import { useGetMassTypesQuery } from '../../../../core/redux/transport';
import { Project } from '../../../../core/types/transport/project';
import { CancelButton } from '../../../../shared/components/CancelButton';
import { Content } from '../../../../shared/components/Content';
import { PageUnauthenticated } from '../../../../shared/components/page';
import {
  setSplitCubic,
  setCubic,
  setSplitTypeOfMass,
  setSplitLoad,
  setTypeOfMass,
  setProjectFrom,
  resetStates,
} from '../../../../core/redux/massOrderState';

export const MasseInnPage: FC = () => {
  const navigate = useNavigate();
  const [selectedProject, setSelectedProject] = useState<Project | null>(null);
  const { data: massTypes } = useGetMassTypesQuery();
  const {
    cubic,
    splitLoad,
    splitCubic,
    splitTypeOfMass,
    typeOfMass,
    projectTo,
  } = useSelector((s) => s.massOrderState);
  const dispatch = useDispatch();

  if (projectTo === null) {
    navigate('/bestilling');
    return null;
  }

  const updateCubic = (value: string, setSplit: boolean = false) => {
    if (value) {
      if (setSplit) {
        dispatch(setSplitCubic(Number.parseFloat(value)));
      } else {
        dispatch(setCubic(Number.parseFloat(value)));
      }
    } else {
      // eslint-disable-next-line no-lonely-if
      if (setSplit) {
        dispatch(setSplitCubic(null));
      } else {
        dispatch(setCubic(null));
      }
    }
  };

  const disableNextPage = () => {
    if (splitLoad) {
      return !(
        splitTypeOfMass !== 0
        && splitCubic !== null
        && splitCubic <= 10
        && cubic !== null
        && cubic !== 0
        && typeOfMass !== 0
      );
    }
    return !(cubic !== null && cubic !== 0 && typeOfMass !== 0);
  };

  const displaySplitLoad = () => (
    <div>
      <div className="page-item">
        <InnsendingLinje text="Massetype henger" />
        <div>
          <FormControl size="small" fullWidth>
            <InputLabel>Velg massetype</InputLabel>
            <Select
              value={`${splitTypeOfMass}`}
              label="Velg massetype"
              onChange={(e) => dispatch(setSplitTypeOfMass(parseInt(e.target.value, 10)))}
              defaultValue="Laster..."
            >
              {!massTypes
                ? null
                : massTypes
                  .filter((e) => e.type === 'Inn')
                  .map((p) => (
                    <MenuItem key={p.id} value={p.id}>
                      {p.name}
                    </MenuItem>
                  ))}
            </Select>
          </FormControl>
        </div>
      </div>

      <div className="page-item">
        <InnsendingLinje text="Mengde henger" />
        <div className="same-line-wrapper">
          <span className="same-line-textbox">
            <TextField
              value={splitCubic || ''}
              onChange={(e) => updateCubic(e.target.value.replace(/[+-]/g, ''), true)}
              id="outlined-basic"
              placeholder="Angi mengde masse"
              type="number"
              autoComplete="off"
              error={splitCubic ? splitCubic > 10 : false}
              helperText={splitCubic && splitCubic > 10 ? 'Maks 10m³' : ''}
              InputProps={{
                inputProps: { min: 0, max: 10 },
              }}
              variant="outlined"
              size="small"
              fullWidth
            />
          </span>
          <span className="same-line-text">m³</span>
        </div>
      </div>
    </div>
  );

  const isDisabled = !cubic || cubic > 10;
  if (isDisabled) {
    dispatch(setSplitLoad(false));
  }

  return (
    <PageUnauthenticated className="masse-inn-page parent-pos">
      <Header>Massebestilling</Header>
      <Content>
        <InnsendingLinje text="Steg 1 - Masse Inn" styling="page-item" />
        <div className="page-item">
          <InnsendingLinje text="Massetype" />
          <div>
            <FormControl size="small" fullWidth>
              <InputLabel>Velg massetype</InputLabel>
              <Select
                value={typeOfMass === 0 ? '' : `${typeOfMass}`}
                required={false}
                label="Velg massetype"
                onChange={(e) => dispatch(setTypeOfMass(parseInt(e.target.value, 10)))}
              >
                {!massTypes
                  ? null
                  : massTypes
                    .filter((e) => e.type === 'Inn')
                    .map((p) => (
                      <MenuItem key={p.id} value={p.id}>
                        {p.name}
                      </MenuItem>
                    ))}
              </Select>
            </FormControl>
          </div>
        </div>

        <div className="page-item">
          <InnsendingLinje text="Mengde" />
          <div className="same-line-wrapper">
            <span className="same-line-textbox">
              <TextField
                value={cubic || ''}
                onChange={(e) => updateCubic(e.target.value.replace(/[+-]/g, ''))}
                id="outlined-basic"
                placeholder="Angi mengde masse"
                type="number"
                autoComplete="off"
                InputProps={{
                  inputProps: { min: 0 },
                }}
                variant="outlined"
                size="small"
                fullWidth
              />
            </span>
            <span className="same-line-text">m³</span>
          </div>
        </div>
        <div className="page-item vertical-padding">
          {`Cirka ${calculateTonnage(cubic || 0)} tonn`}
        </div>
        <div className="page-item">
          <FormGroup>
            <FormControlLabel
              disabled={isDisabled}
              control={(
                <Checkbox
                  checked={splitLoad}
                  onClick={() => dispatch(setSplitLoad(!splitLoad))}
                />
              )}
              label="Splittet last (maks 10m³)"
            />
          </FormGroup>
        </div>
        <div>{!isDisabled && splitLoad && displaySplitLoad()}</div>
        <div className="page-item">
          <InnsendingLinje text="Fra prosjekt (valgfritt)" />
          <div>
            <ProjectPicker
              value={selectedProject}
              onChange={(newValue) => {
                if (newValue) {
                  setSelectedProject(newValue);
                  dispatch(setProjectFrom(newValue && newValue.id));
                } else {
                  setSelectedProject(null);
                  dispatch(setProjectFrom(null));
                }
              }}
            />
          </div>
        </div>
        <div className="bottom-inner">
          <div className="bottom-element">
            <div style={{ width: '64px' }} />
            <CancelButton
              confirm={() => {
                dispatch(resetStates());
                return navigate('/bestilling');
              }}
            />
            <Button
              className="right-element"
              onClick={() => navigate('/masseinn/leveringsdetaljer')}
              variant="contained"
              size="large"
              disabled={disableNextPage()}
            >
              {'>'}
            </Button>
          </div>
        </div>
      </Content>
    </PageUnauthenticated>
  );
};
