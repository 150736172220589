import {
  addDays,
  isSameDay,
  isWeekend,
  nextMonday,
  parseISO,
} from 'date-fns';
import { CalendarObservation } from './types/transport/api/calendar';

// Checks if the given date mathces one of the observed days
export function isObservation(date: Date | number, observations: CalendarObservation[]) {
  const observation = observations.find((o) => isSameDay(parseISO(o.date), date));
  return observation !== undefined;
}

// Calculates the next working day based on weekend and observed
// holidays/days off
export function nextWorkingDay(today: Date, observations: CalendarObservation[]): Date {
  let result = addDays(today, 1);

  while (isWeekend(result) || isObservation(result, observations)) {
    if (isWeekend(result)) {
      result = nextMonday(result);
    } else {
      result = addDays(result, 1);
    }
  }

  return result;
}

/** Calculate the next working day that fits the requirement */
export const nextWorkingDayWithRequirement = (
  /** Date to start calculation from */
  today: Date,
  /** List of invalid dates */
  observations: CalendarObservation[],
  /** Requirement function. Only dates where this function returns true are considered. */
  requirementFunc: (v: Date) => boolean,
): Date => {
  let d = today;
  do {
    d = nextWorkingDay(d, observations);
  } while (!requirementFunc(d));

  return d;
};
