import CircleIcon from '@mui/icons-material/Circle';
import { Box } from '@mui/material';
import { OrderStatus } from '../../core/types/transport/enums/orderStatus';
import { translateOrderStatus } from '../../core/helpers/translate';
import { getOrderStatusColor } from '../../core/helpers/functions';
import {
  APPROVED_COLOR,
  CANCELLED_COLOR,
  UNDER_PLANNING_COLOR,
  COMPLETED_COLOR,
  STARTED_COLOR,
} from '../../shared/constants';

export const OrderStatusIcon = ({
  currStatus,
}: {
  currStatus: OrderStatus | string | undefined
}) => {
  const statusType = translateOrderStatus(currStatus as OrderStatus);
  const statusColor = getOrderStatusColor(currStatus as OrderStatus);
  const color = (() => {
    switch (statusColor) {
      case 'ApprovedColor': return APPROVED_COLOR;
      case 'CancelledColor': return CANCELLED_COLOR;
      case 'CreatedColor': return '#8CF0DA';
      case 'FinishedColor': return COMPLETED_COLOR;
      case 'UndeliveredColor': return STARTED_COLOR;
      case 'UnderPlanningColor': return UNDER_PLANNING_COLOR;
      default: return 'black';
    }
  })();
  return (
    <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
      <CircleIcon sx={{ color }} />
      {statusType}
    </Box>
  );
};
