import React, { FC, useEffect } from 'react';
import Button from '@mui/material/Button';
import { useNavigate } from 'react-router-dom';
import { Checkbox, FormControlLabel } from '@mui/material';
import { isSameDay } from 'date-fns';
import { useDispatch, useSelector } from '../../../../shared/hooks/redux';
import { ChooseDate } from '../../../../components/ChooseDate';
import { ChooseDeliveryTime } from '../../../../components/ChooseDeliveryTime';
import { ChooseVehicle } from '../../../../components/ChooseVehicle';
import { Header } from '../../../../components/header';
import { InnsendingLinje } from '../../../../components/Header4';
import { futureDateIsNotSelected } from '../../../../core/helpers/functions';
import {
  setDeliveryDate,
  setIsEarlyDelivery,
  setDeliveryTimeType,
  setStartTime,
  setEndTime,
  setIsImportantDeadline,
  resetStates,
  setTrips,
  setNumberOfVehicles,
  setVehicleType,
} from '../../../../core/redux/massOrderState';
import { CancelButton } from '../../../../shared/components/CancelButton';
import { Content } from '../../../../shared/components/Content';
import { PageUnauthenticated } from '../../../../shared/components/page';
import { useSetDeliveryDateToNextWorkingDay } from '../../../DeliveryDate';

export const InnLeveringsdetaljerPage: FC = () => {
  const navigate = useNavigate();
  const {
    deliveryTimeType,
    startTime,
    endTime,
    vehicleType,
    deliveryDate,
    trips,
    numberOfVehicles,
    userDefinedDeliveryDate,
    timeError,
    typeOfMass,
    cubic,
    projectTo,
    splitLoad,
    isEarlyDelivery,
    isImportantDeadLine,
  } = useSelector((s) => s.massOrderState);
  const dispatch = useDispatch();
  const setDeliveryDateToNextWorkingDay = useSetDeliveryDateToNextWorkingDay();

  const m3 = cubic || 0;

  let cubicCapacity = 20;
  if (vehicleType.type === 1) { // if single
    cubicCapacity = 10;
  }

  if (vehicleType.type !== 0) {
    dispatch(setTrips(Math.ceil(m3 / cubicCapacity)));
    dispatch(setNumberOfVehicles(Math.ceil(trips / 6)));
  } else {
    dispatch(setTrips(0));
    dispatch(setNumberOfVehicles(0));
  }

  if (projectTo === null || cubic === null || typeOfMass === 0) {
    navigate('/bestilling');
    return null;
  }

  // all conditions should be false to move on to next page
  const disableNextPage = () => {
    const checkVehicleSelected = vehicleType.type === 0
      || vehicleType.type === 3;
    const checkTimeSelected = deliveryTimeType.type === 5
      && (startTime === null || endTime === null);
    const checkValidTime = timeError !== null;
    if (deliveryTimeType.type === 5) {
      return (
        checkVehicleSelected
        || futureDateIsNotSelected(deliveryDate, userDefinedDeliveryDate)
        || checkTimeSelected
        || checkValidTime
      );
    }
    return (
      checkVehicleSelected
      || futureDateIsNotSelected(deliveryDate, userDefinedDeliveryDate)
      || checkTimeSelected);
  };

  useEffect(() => {
    if (splitLoad) {
      dispatch(setVehicleType({ type: 5, name: 'Bil med henger' }));
    } else if (!splitLoad && vehicleType.type === 5) {
      dispatch(setVehicleType({ type: 0, name: '' }));
    }
  }, []);

  return (
    <PageUnauthenticated className="leveringsdetaljer-page parent-pos">

      <Header>Massebestilling</Header>
      <Content>
        <InnsendingLinje text="Steg 2 - Leveringsdetaljer" styling="page-item" />
        <InnsendingLinje text="Anbefalt kjøretøy" styling="page-item" />

        <div className="page-item">
          <Button
            onClick={() => dispatch(setVehicleType({ type: 1, name: 'Enkel' }))}
            variant={vehicleType.type === 1 ? 'contained' : 'outlined'}
            size="medium"
            // if load is split, bil med henger is only option. Hence disabled is true
            disabled={splitLoad}
          >
            Enkel
          </Button>
          <Button
            onClick={() => dispatch(setVehicleType({ type: 2, name: 'Dobbel' }))}
            variant={vehicleType.type === 2 ? 'contained' : 'outlined'}
            size="medium"
            disabled={splitLoad}
          >
            Dobbel
          </Button>
          <Button
            onClick={() => dispatch(setVehicleType({ type: 3, name: 'Egendefinert' }))}
            variant={(vehicleType.type === 3
                || vehicleType.type === 4
                || vehicleType.type === 5
                || vehicleType.type === 6) ? 'contained' : 'outlined'}
            size="medium"
            disabled={splitLoad}
          >
            Egendefinert
          </Button>
        </div>
        {
          (vehicleType.type === 3
          || vehicleType.type === 4
          || vehicleType.type === 5
          || vehicleType.type === 6)
          && <ChooseVehicle disable={splitLoad} />
    }
        <div className="page-item">
          {`Antall turer/biler: ${trips}/${numberOfVehicles}`}
        </div>

        <InnsendingLinje text="Leveringsdato" styling="page-item" />

        <div className="page-item">
          <Button
            onClick={setDeliveryDateToNextWorkingDay}
            variant={deliveryDate.type === 1 ? 'contained' : 'outlined'}
            size="medium"
          >
            Neste arbeidsdag
          </Button>
          <Button
            onClick={() => dispatch(setDeliveryDate({ type: 2, day: userDefinedDeliveryDate }))}
            variant={deliveryDate.type === 2 ? 'contained' : 'outlined'}
            size="medium"
          >
            Velg dato
          </Button>
          <Button
            onClick={() => dispatch(setDeliveryDate({ type: 3, day: new Date() }))}
            variant={deliveryDate.type === 3 ? 'contained' : 'outlined'}
            size="medium"
          >
            I dag
          </Button>
        </div>
        <ChooseDate />

        <FormControlLabel
          disabled={(deliveryDate.day && isSameDay(deliveryDate.day, new Date())) || false}
          className="page-item pt10"
          control={(
            <Checkbox
              checked={isEarlyDelivery}
              onClick={() => dispatch(setIsEarlyDelivery(!isEarlyDelivery))}
            />
              )}
          label="Kan leveres dagen før"
        />

        <InnsendingLinje text="Leveringstidspunkt" styling="page-item" />

        <div className="page-item">
          <Button
            onClick={() => dispatch(setDeliveryTimeType({ type: 1, time: 'I løpet av dagen' }))}
            variant={deliveryTimeType.type === 1 ? 'contained' : 'outlined'}
            size="medium"
          >
            I løpet av dagen
          </Button>
          <Button
            onClick={() => dispatch(setDeliveryTimeType({ type: 2, time: 'Morgen' }))}
            variant={deliveryTimeType.type === 2 ? 'contained' : 'outlined'}
            size="medium"
          >
            Morgen
          </Button>
          <Button
            onClick={() => dispatch(setDeliveryTimeType({ type: 3, time: 'Før mat' }))}
            variant={deliveryTimeType.type === 3 ? 'contained' : 'outlined'}
            size="medium"
          >
            Før mat
          </Button>
          <Button
            onClick={() => dispatch(setDeliveryTimeType({ type: 4, time: 'Etter mat' }))}
            variant={deliveryTimeType.type === 4 ? 'contained' : 'outlined'}
            size="medium"
          >
            Etter mat
          </Button>
          <Button
            onClick={() => dispatch(setDeliveryTimeType({ type: 5, time: 'Egendefinert' }))}
            variant={deliveryTimeType.type === 5 ? 'contained' : 'outlined'}
            size="medium"
          >
            Egendefinert
          </Button>
        </div>
        {deliveryTimeType.type === 5
        && (
        <ChooseDeliveryTime
          startTime={startTime}
          onStartTimeChange={(v) => dispatch(setStartTime(v))}
          endTime={endTime}
          onEndTimeChange={(v) => dispatch(setEndTime(v))}
          isImportantDeadLine={isImportantDeadLine}
          onIsImportantDeadlineChange={(v) => dispatch(setIsImportantDeadline(v))}
          deliveryDate={deliveryDate.day}
        />
        )}

        <div className="bottom-inner">
          <div className="bottom-element">
            <Button
              className="left-element"
              onClick={() => navigate(-1)}
              variant="contained"
              size="large"
            >
              {'<'}
            </Button>
            <CancelButton confirm={() => {
              dispatch(resetStates());
              return navigate('/bestilling');
            }}
            />
            <Button
              className="right-element"
              onClick={() => navigate('/masseinn/tilleggsdetaljer')}
              variant="contained"
              size="large"
              disabled={disableNextPage()}
            >
              {'>'}
            </Button>
          </div>
        </div>
      </Content>
    </PageUnauthenticated>
  );
};
