import { ReactNode } from 'react';
import { InteractionType } from '@azure/msal-browser';
import { MsalAuthenticationTemplate } from '@azure/msal-react';
import { Configuration } from '../../shared/types/configuration';

export const Authenticate = ({
  config,
  children,
} : {
  config: Configuration,
  children?: ReactNode,
  }) => (
    <MsalAuthenticationTemplate
      authenticationRequest={{ scopes: [config.SCOPE] }}
      interactionType={InteractionType.Redirect}
    >
      {children}
    </MsalAuthenticationTemplate>
);
