import { skipToken } from '@reduxjs/toolkit/dist/query';
import { useNavigate, useParams, Link } from 'react-router-dom';
import { useEffect } from 'react';
import {
  Alert,
  Box,
  CircularProgress,
  Typography,
} from '@mui/material';
import { useGetRedirectLinkQuery } from '../../shared/redux/rental';
import { PageUnauthenticated } from '../../shared/components/page';
import { Header } from '../../components/header';
import { Content } from '../../shared/components/Content';

/** Page with a short URL for redirecting to an order */
export const OrderRedirectPage = () => {
  const { id } = useParams<'id'>();
  const navigate = useNavigate();

  const { data: link, isError } = useGetRedirectLinkQuery((+(id ?? 0)) || skipToken);

  useEffect(() => {
    if (!link) return;
    const { linkId, linkType } = link;

    let typeUrl = 'internutleie';
    if (linkType === 'Transport') typeUrl = 'transport';

    navigate(`/bestilling/${typeUrl}/${linkId}`, { replace: true });
  }, [link, navigate]);

  return (
    <PageUnauthenticated>
      <Header>Bestilling</Header>
      <Content sx={{ paddingY: '3vh', paddingX: 2 }}>
        <Box sx={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          flexDirection: 'column',
          minHeight: '40vh',
          gap: 2,
        }}
        >
          {!isError && (
            <>
              <CircularProgress />
              <Typography sx={{ color: 'grey', fontSize: '1.4em' }}>Finner bestilling</Typography>
            </>
          )}
          {isError && (
            <>
              <Alert severity="error">Kunne ikke finne bestilling</Alert>
              <Link to="/bestillinger">Mine bestillinger</Link>
            </>
          )}
        </Box>
      </Content>
    </PageUnauthenticated>
  );
};
