import { useMemo, useState } from 'react';
import {
  IconButton,
  InputAdornment,
  InputLabel,
  OutlinedInput,
} from '@mui/material';
import BackspaceIcon from '@mui/icons-material/Backspace';
import FormControl from '@mui/material/FormControl';
import { format } from 'date-fns';
import { useNavigate } from 'react-router-dom';
import { ProjectItem } from './partials/Project';
import { LoadingProject } from './partials/LoadingProject';
import { sortByMRU } from '../../core/helpers/functions';
import { useMRUs } from '../../core/hooks/useMRUs';
import { useGetProjectsQuery } from '../../core/redux/transport';
import { PageUnauthenticated } from '../../shared/components/page';
import { Header } from '../../components/header';
import { Project } from '../../core/types/transport/project';
import './style.scss';

export const ProjectsPage = () => {
  const navigate = useNavigate();
  const { data: rawProjects = [], isLoading } = useGetProjectsQuery();
  const [term, setTerm] = useState<string>('');

  const mrus = useMRUs();

  const projects = useMemo((): Array<Project & {lastUsed?: string}> => {
    let ps = [...rawProjects];

    // sort by ID
    ps.sort((a, b) => (
      (a.id === 0 ? Infinity : a.id) - (b.id === 0 ? Infinity : b.id)
    ));

    // Sort by recent usage
    ps.sort(sortByMRU(mrus));

    // Map in the lastUsed string
    ps = ps.map((p): Project & {lastUsed?: string} => {
      const mru = mrus.get(p.id);
      if (!mru) return p;
      return ({ ...p, lastUsed: format(mru, "dd.MM.yyyy 'kl.' HH:mm") });
    });

    return ps;
  }, [rawProjects, mrus]);

  const projectListItems = useMemo(() => {
    if (isLoading) return null;

    return projects.filter((p) => (
      (term === '')
        || p.projectName.toLowerCase().includes(term.toLowerCase())
        || `${p.id}`.toLowerCase().includes(term.toLowerCase())
    )).slice(0, 20).map((p) => (
      <ProjectItem
        key={p.id}
        data={p}
        lastUsed={p.lastUsed}
        onClick={() => navigate(`/prosjekt/${p.id}`)}
      />
    ));
  }, [projects, term, mrus]);

  return (
    <PageUnauthenticated className="prosjekter-page">
      <Header>Prosjekter</Header>
      <div className="project-area-wrapper">
        <div className="project-area">
          <div className="project-area-item project-search-bar">
            <FormControl variant="outlined" fullWidth>
              <InputLabel htmlFor="project-search">Søk prosjekter</InputLabel>
              <OutlinedInput
                id="project-search"
                value={term}
                fullWidth
                onChange={(e) => setTerm(e.target.value)}
                label="Søk prosjekter"
                endAdornment={(
                  <InputAdornment position="end">
                    <IconButton
                      aria-label="Fjern søket"
                      onClick={() => setTerm('')}
                      onMouseDown={() => setTerm('')}
                      edge="end"
                    >
                      {term === '' ? null : <BackspaceIcon /> }
                    </IconButton>
                  </InputAdornment>
            )}
              />
            </FormControl>
          </div>
          <div className="project-area-item project-list-container">
            {projectListItems}
            {isLoading ? Array.from(Array(5)).map(() => <LoadingProject />) : null}
          </div>
        </div>
      </div>
    </PageUnauthenticated>
  );
};
