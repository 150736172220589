import { FC, useEffect, useMemo } from 'react';
import Button from '@mui/material/Button';
import { useNavigate, useParams } from 'react-router-dom';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import './style.scss';

import {
  resetStates as resetStatesNormal,
} from '../../core/redux/massOrderState';
import {
  resetStates as resetStatesContainer,
} from '../../core/redux/containerOrderState';
import { InnsendingLinje } from '../../components/Header4';
import { Content } from '../../shared/components/Content';
import { useDispatch } from '../../shared/hooks/redux';
import { useGetOrderQuery } from '../../core/redux/transport';
import { PageUnauthenticated } from '../../shared/components/page';
import { formatDate } from '../../shared/logic/dates';
import { Header } from '../../components/header';
import { translateOrderType } from '../../core/helpers/translate';

export const BekreftelsePage: FC = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { id = '' } = useParams<{id: string}>();
  dispatch(resetStatesNormal());
  dispatch(resetStatesContainer());

  const { data: order, isLoading } = useGetOrderQuery(id);

  useEffect(() => {
    if (!order && !isLoading) {
      navigate('/');
    }
  }, [order, isLoading]);

  const typeText = useMemo(() => {
    switch (order?.type) {
      case 'MassIn':
      case 'MassOut':
      case 'MassInternal':
        return { title: 'Transportbestilling', url: '/bestilling' };
      case 'HaulSemi':
      case 'HaulHook':
        return { title: translateOrderType(order?.type), url: '/maskinslep' };
      case 'Diesel': return { title: 'Dieselbestilling', url: '/diesel' };
      default: return { title: 'Containerbestilling', url: '/container' };
    }
  }, [order]);

  if (!order) return null;

  const currentDate = new Date(order.createdTimestamp);

  const bestilling = `/bestilling/transport/${id}`;

  return (
    <PageUnauthenticated className="parent-pos">
      <Header>{typeText.title}</Header>
      <Content>
        <InnsendingLinje text="Bestillingsbekreftelse" styling="centered" />
        <div className="centered">
          <CheckCircleIcon color="success" sx={{ width: 100, height: 100 }} />
        </div>
        <InnsendingLinje text="Bestilling sendt" styling="centered" />
        <div className="top-and-bottom-padding centered">
          {`Din bestilling ble registrert ${formatDate(currentDate, 'eee dd/MM')} kl. ${formatDate(currentDate, 'HH:mm:ss')}`}
        </div>
        <div className="centered">
          <Button variant="contained" onClick={() => { navigate('/bestillinger'); navigate(bestilling); }}>Gå til bestillingen</Button>
        </div>
        <div className="bottom-element-bekreft">
          <div className="page-item">
            <Button
              onClick={() => navigate('/bestillinger')}
              variant="outlined"
              size="large"
              fullWidth
              sx={{
                maxWidth: 800,
              }}
            >
              Mine bestillinger
            </Button>
          </div>
          <div className="page-item vertical-padding">
            <Button
              className="page-item"
              onClick={() => navigate(typeText.url)}
              variant="contained"
              size="large"
              fullWidth
              sx={{
                maxWidth: 800,
              }}
            >
              Ny bestilling
            </Button>
          </div>
        </div>
      </Content>
    </PageUnauthenticated>
  );
};
